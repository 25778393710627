import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import Page from '../../components/atoms/page'
import { FcDoughnutChart as AdIcon } from 'react-icons/fc'
import backendApis from '../../utils/backendApis'
import commaNumber from 'comma-number'
import AdCreditLogTable from '../../components/molecules/adCreditLogTable'
import AdCreditPayModal from '../../components/molecules/adCreditPayModal'
import AdCreditCancelModal from '../../components/molecules/adCreditCancelModal'
import AdPolicyAgreementModal from '../../components/molecules/adPolicyAgreementModdal'
import AdConsumeLogTable from '../../components/molecules/adConsumeLogTable'
import Button from '../../components/atoms/button'
import SellerStore from '../../stores/SellerStore'
import Datepicker from 'react-tailwindcss-datepicker'
import { SiGooglesheets } from 'react-icons/si'
import * as ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import Format from '../../utils/format'
import moment from 'moment-timezone'
import AdCpmConsumeLogTable from '../../components/molecules/AdCpmConsumeLogTable'

const headers = Format.adDetailExcelHeaders

const typeTranslator = (type) => {
  if (type === 'purchase') {
    return '판매'
  }
  if (type === 'cancel') {
    return '취소'
  }
  if (type === 'click') {
    return '클릭'
  }
}

const AdCreditScreen = observer(() => {
  const [isLoading, setIsLoading] = useState(false)
  const [basicInfo, setBasicInfo] = useState({})

  const endDate = moment().format('YYYY-MM-DD') // 오늘 날짜
  const startDate = moment().subtract(30, 'days').format('YYYY-MM-DD') // 최근 30일 전 날짜

  const [dateFilter, setDateFilter] = useState({
    startDate: startDate,
    endDate: endDate,
  })
  const [openCreditPayModal, setOpenCreditPayModal] = useState(false)
  const [openCreditCancelModal, setOpenCreditCancelModal] = useState(false)
  const [openPolicyAgreementModal, setOpenPolicyAgreementModal] =
    useState(false)
  const [logTabStatus, setLogTabStatus] = useState('chargeLog')
  const [diffDate, setDiffDate] = useState(30)

  const getAdCreditInfo = async () => {
    const result = await backendApis.getAdCreditInfo()
    if (result?.status === 2000) {
      setBasicInfo(result?.data)
    }
  }

  useEffect(() => {
    if (isLoading) return
    setIsLoading(true)
    getAdCreditInfo()
    setIsLoading(false)
  }, [])

  useEffect(() => {
    if (!dateFilter?.startDate || !dateFilter?.endDate) {
      setDiffDate(null)
      return
    }

    const startDate = new Date(dateFilter?.startDate)
    const endDate = new Date(dateFilter?.endDate)
    // Calculate the difference in milliseconds
    const differenceInTime = endDate.getTime() - startDate.getTime()

    // Convert milliseconds to days
    const differenceInDays = differenceInTime / (1000 * 3600 * 24)
    setDiffDate(differenceInDays)
  }, [dateFilter])

  return (
    <div className='w-[85vw] pr-10 flex flex-1 '>
      <Page title='광고 크레딧 관리' Icon={AdIcon}>
        {/* 크레딧 잔액 표시 및 충전,환불하기 버튼 */}
        <div className='flex flex-1 p-10 mb-5 bg-white divide-x-2 shadow-xl rounded-xl'>
          <div className='w-1/3 text-base whitespace-nowrap'>
            <div>총 크레딧 잔액</div>
            <div className='mt-5 text-3xl font-bold'>
              {commaNumber(
                (basicInfo?.adCreditInfo?.paidCredit || 0) +
                  (basicInfo?.adCreditInfo?.supportCredit || 0),
              )}
              원
            </div>
          </div>
          <div className='w-1/3 pl-5 text-base'>
            <div>유상 크레딧 잔액</div>
            <div className='my-5 text-3xl font-bold whitespace-nowrap'>
              {commaNumber(basicInfo?.adCreditInfo?.paidCredit || 0)}원
            </div>
            <div>
              <Button
                className='mr-3'
                onClick={() => {
                  if (SellerStore.sellerInfo?.agreeToSpecficPolicy?.adPolicy) {
                    setOpenCreditPayModal(true)
                  } else {
                    setOpenPolicyAgreementModal(true)
                  }
                }}
              >
                충전하기
              </Button>
              <Button
                appearance='neutral'
                onClick={() => {
                  if (SellerStore.sellerInfo?.agreeToSpecficPolicy?.adPolicy) {
                    setOpenCreditCancelModal(true)
                  } else {
                    setOpenPolicyAgreementModal(true)
                  }
                }}
              >
                환불하기
              </Button>
            </div>
          </div>
          <div className='w-1/3 pl-5 text-base'>
            <div>무상 크레딧 잔액</div>
            <div className='mt-5 text-3xl font-bold whitespace-nowrap'>
              {commaNumber(basicInfo?.adCreditInfo?.supportCredit || 0)}원
            </div>
          </div>
        </div>

        {/* 충전 내역, 소진 내역 테이블 */}
        <div className='flex flex-col flex-1 p-10 bg-white shadow-xl rounded-xl'>
          <div className='mb-10'>
            <div className='flex flex-row justify-between'>
              <div>
                <button
                  type='button'
                  onClick={() => setLogTabStatus('chargeLog')}
                  className={`select-none text-xl pb-1 mr-5  font-bold duration-200 ease-in-out ${
                    logTabStatus === 'chargeLog'
                      ? 'border-b-4 border-positive-500 text-black'
                      : 'text-gray-400'
                  } w-max`}
                >
                  충전 내역
                </button>
                <button
                  type='button'
                  onClick={() => setLogTabStatus('consumeLog')}
                  className={`select-none text-xl pb-1 mr-5 font-bold w-max duration-200 ease-in-out ${
                    logTabStatus === 'consumeLog'
                      ? 'border-b-4 border-positive-500 text-black'
                      : 'text-gray-400'
                  }`}
                >
                  CPC/CPS 소진 내역
                </button>
                <button
                  type='button'
                  onClick={() => setLogTabStatus('cpmConsumeLog')}
                  className={`select-none text-xl pb-1 mr-5 font-bold w-max duration-200 ease-in-out ${
                    logTabStatus === 'cpmConsumeLog'
                      ? 'border-b-4 border-positive-500 text-black'
                      : 'text-gray-400'
                  }`}
                >
                  CPM 내역
                </button>
              </div>
              {logTabStatus !== 'chargeLog' && (
                <div className='flex flex-row'>
                  <div className='flex flex-col mr-4'>
                    {logTabStatus !== 'chargeLog' && (
                      <Datepicker
                        displayFormat='YYYY년 MM월 DD일'
                        placeholder='조회 기간 선택'
                        value={dateFilter}
                        onChange={(d) => {
                          setDateFilter(d)
                        }}
                        i18n='ko'
                        inputClassName='w-full rounded-md focus:ring-0 dark:bg-white dark:placeholder:text-gray-400 dark:border-gray-300 dark:text-gray-800'
                        showShortcuts
                        configs={{
                          shortcuts: {
                            today: '오늘',
                            yesterday: '어제',
                            currentMonth: '이번 달',
                            pastMonth: '지난 달',
                            past: (period) => `지난 ${period}일`,
                          },
                        }}
                      />
                    )}
                  </div>
                  {logTabStatus !== 'cpmConsumeLog' && (
                    <Button
                      size='sm'
                      className='flex items-center whitespace-nowrap'
                      appearance={
                        diffDate <= 90 && diffDate !== null
                          ? 'gray'
                          : 'disabled'
                      }
                      type='button'
                      onClick={async () => {
                        if (!(diffDate <= 90 && diffDate !== null)) {
                          window.alert(
                            '90일 이내의 내역만 엑셀로 다운받을 수 있어요.',
                          )
                          return
                        }

                        // 해당 월, 셀러에 해당하는 tax-invoice document 가져오기
                        SellerStore.setIsLoading(true)

                        const result = await backendApis.getAdUsageLogInfo(
                          '',
                          '',
                          dateFilter?.startDate || '',
                          dateFilter?.endDate || '',
                        )

                        let creditLog = []
                        if (result?.status === 200) {
                          creditLog = result?.data.map((e, idx) => {
                            return {
                              itemId: e?.itemId,
                              itemPrice: e?.itemPrice
                                ? `${commaNumber(e.itemPrice)}`
                                : '-',
                              adCost: `${commaNumber(
                                (e?.detailInfo?.paidCredit || 0) +
                                  (e?.detailInfo?.supportCredit || 0),
                              )}`,
                              paidAdCost: `${commaNumber(
                                e?.detailInfo?.paidCredit || 0,
                              )}`,
                              freeAdCost: `${commaNumber(
                                e?.detailInfo?.supportCredit || 0,
                              )}`,
                              type: typeTranslator(e?.type),
                              createdAt: moment(e?.createdAt).format(
                                'YYYY-MM-DD HH:mm',
                              ),
                            }
                          })
                          if (creditLog?.length === 0) {
                            window.alert('다운로드 할 내역이 없어요.')
                            SellerStore.setIsLoading(false)
                            return
                          }
                        } else {
                          window.alert(
                            '알 수 없는 에러가 발생했어요. 다시 시도해 주세요.',
                          )
                          SellerStore.setIsLoading(false)
                          return
                        }

                        const workbook = new ExcelJS.Workbook()
                        const worksheet =
                          workbook.addWorksheet('광고 크레딧 소진 상세내역')

                        worksheet.columns = headers
                        worksheet.addRows(creditLog)
                        const headerRow = worksheet.getRow(1)
                        headers.forEach((header, index) => {
                          headerRow.getCell(index + 1).fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: 'FFEA3F49' },
                          }
                        })
                        worksheet.getRow(1).font = {
                          color: { argb: 'FFFFFFFF' },
                          bold: true,
                        }
                        const mimeType = {
                          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        }
                        const buffer = await workbook.xlsx.writeBuffer()
                        const blob = new Blob([buffer], mimeType)

                        saveAs(
                          blob,
                          `${SellerStore.sellerInfo.sellerDisplayName}_광고 크레딧 소진 상세내역_${dateFilter?.startDate}_${dateFilter?.endDate}.xlsx`,
                        )

                        SellerStore.setIsLoading(false)
                      }}
                    >
                      <SiGooglesheets className='mr-2' />
                      다운로드
                    </Button>
                  )}
                </div>
              )}
            </div>
          </div>
          {(() => {
            if (logTabStatus === 'chargeLog') {
              return <AdCreditLogTable />
            }
            if (logTabStatus === 'consumeLog') {
              return <AdConsumeLogTable dateFilter={dateFilter} />
            }
            if (logTabStatus === 'cpmConsumeLog') {
              return <AdCpmConsumeLogTable dateFilter={dateFilter} />
            }
            return null
          })()}
        </div>
      </Page>
      <AdPolicyAgreementModal
        showModal={openPolicyAgreementModal}
        setShowModal={setOpenPolicyAgreementModal}
        basicInfo={basicInfo}
        setBasicInfo={setBasicInfo}
      />
      <AdCreditPayModal
        showModal={openCreditPayModal}
        setShowModal={setOpenCreditPayModal}
        basicInfo={basicInfo}
        setBasicInfo={setBasicInfo}
      />
      <AdCreditCancelModal
        showModal={openCreditCancelModal}
        setShowModal={setOpenCreditCancelModal}
        setBasicInfo={setBasicInfo}
      />
    </div>
  )
})
export default AdCreditScreen
