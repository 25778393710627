import moment from 'moment'
import React, { useState, useEffect, useMemo } from 'react'
import {
  FcIdea as BulbIcon,
  FcOk as CheckIcon,
  FcHighPriority as WarnIcon,
} from 'react-icons/fc'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import { useForm } from 'react-hook-form'
import SellerStore from '../../stores/SellerStore'
import adSettingByTab from '../../data/adSettingByTab'
import backendApis from '../../utils/backendApis'
import Table from '../../components/ADS/molecules/table'
import Pagination from '../../components/molecules/pagination'
import RankIconSetter from '../../components/molecules/rankIconSetter'
import resize from '../../utils/resize'
import Button from '../../components/atoms/button'
import commaNumber from 'comma-number'
import ApplyTabHandler from '../../components/molecules/applyTabHandler'
import EggAdStatusBar from '../../components/molecules/eggAdStatusBar'
import AlfarmFreeAdModalForEgg from '../Modals/AlfarmFreeAdModalForEgg'
import AlfarmFreeAdEventNoticeComponent from '../../components/AlfarmFreeAdEventNoticeComponent'
import alfarmFreeAdSellerFilteringManager from '../../utils/alfarmFreeAdSellerFilteringManager'
import EggBreakAdApplicationGraph from '../../components/molecules/eggBreakAdApplicationGraph'

const AlfarmEggBreakersScreenV2 = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const onSubmit = (data) => setAdInfo(data)
  const [startDate, setStartDate] = useState(
    new Date(moment(new Date()).startOf('day').add(3, 'days')),
  )
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(5)
  const [itemsInfo, setItemsInfo] = useState([])
  const [itemsCount, setItemsCount] = useState(0)
  const [checkedItemIds, setCheckedItemIds] = useState([])
  const [checkedItemInfo, setCheckedItemInfo] = useState([])
  const [checkedItemsTitle, setCheckedItemsTitle] = useState([])
  const [adInfo, setAdInfo] = useState({
    price: false,
    coupon: false,
  })
  const [eggCount, setEggCount] = useState(50000)
  const [impCost, setImpCost] = useState(7)
  const [adStatusTab, setAdStatusTab] = useState('candidate')
  const [freeAdTab, setFreeAdTab] = useState(false)
  const [freeAdAvailable, setFreeAdAvailable] = useState(false)
  const [sellerSettlementAmount, setSellerSettlementAmount] = useState(0)
  const [alreadyRegisteredItemIds, setAlreadyRegisteredItemIds] = useState([])

  const cleanUp = () => {
    setStartDate(new Date(moment(new Date()).startOf('day').add(3, 'days')))
    setPage(1)
    setLimit(5)
    setItemsInfo([])
    setItemsCount(0)
    setAdInfo({
      price: false,
      coupon: false,
    })
    setEggCount(50000)
    setImpCost(7)
    setCheckedItemIds([])
  }

  const columns = useMemo(
    () => [
      { Header: '선택', accessor: 'status' },
      { Header: '상품정보', accessor: 'itemInfo' },
    ],
    [],
  )

  const handleCheckboxChange = (e, item) => {
    // if (alreadyRegisteredItemIds?.includes(item?._id)) {
    //   alert(
    //     '3일 이내 신청한 상품은 중복해서 신청할 수 없습니다. 다른 상품을 선택해주세요.',
    //   )
    //   return
    // }
    if (item?.totalRatingScore / item?.totalRatingCount <= 3.5) {
      alert(`평점이 3.5점 이상인 상품만 선택 가능해요.`)
      return
    }
    if (checkedItemIds?.length >= 51) {
      alert('상품은 최대 50개까지 선택 가능해요.')
      return
    }
    if (e.target.checked) {
      setCheckedItemIds((prevIds) => [...prevIds, item._id])
      setCheckedItemsTitle((prevTitles) => [...prevTitles, item.itemTitle])
      setCheckedItemInfo((prevInfo) => [...prevInfo, item])
    } else {
      setCheckedItemIds((prevIds) => prevIds.filter((id) => id !== item._id))
      setCheckedItemsTitle((prevTitles) =>
        prevTitles.filter((title) => title !== item.itemTitle),
      )
      setCheckedItemInfo((prevInfo) =>
        prevInfo.filter((info) => info._id !== item._id),
      )
    }
  }

  const data = useMemo(
    () =>
      itemsInfo
        ?.filter((item) => item?.itemTitle)
        .map((item, idx) => {
          return {
            status: (
              <div className='flex items-center justify-center flex-1 select-none'>
                <div className='flex flex-row flex-1 items-center justify-start min-w-[3%] max-w-[3%] select-none'>
                  <input
                    type='checkbox'
                    checked={checkedItemIds?.includes(item?._id)}
                    onChange={(e) => handleCheckboxChange(e, item)}
                  />
                </div>
              </div>
            ),
            itemInfo: (
              <div key={item?._id}>
                <div className='flex flex-row justify-between flex-1 w-full'>
                  <div className='flex flex-row flex-1 w-max'>
                    <img
                      alt='mainImage'
                      src={
                        item?.mainImageUris
                          ? resize(item?.mainImageUris[0])
                          : ''
                      }
                      className='w-20 h-20 mr-3 rounded-md border border-[#000000F]'
                    />
                    <div className='mr-3'>
                      <div className='mb-1 text-lg font-bold whitespace-normal'>
                        {item?.itemTitle}
                      </div>

                      <div className='flex flex-row items-center mb-1'>
                        {item?.rankInfo && (
                          <div className='flex items-center px-2 py-0.5 bg-gray-100 rounded-md mr-2'>
                            <RankIconSetter
                              itemRank={item?.rankInfo?.itemRank}
                              className='w-4 h-4'
                            />
                            등급
                          </div>
                        )}
                        <div>
                          {item?.teamPurchasePrice?.toLocaleString() || 0}원
                        </div>
                      </div>
                      <div className='p-0.5'>
                        평점:{' '}
                        {!item?.totalRatingCount ? (
                          <>없음</>
                        ) : (
                          <>
                            {(
                              item?.totalRatingScore / item?.totalRatingCount
                            )?.toFixed(1)}
                          </>
                        )}
                      </div>
                      <div className='text-gray-400 '>{item?._id}</div>
                    </div>
                  </div>
                </div>
              </div>
            ),
          }
        }),
    [itemsInfo, checkedItemIds, alreadyRegisteredItemIds],
  )

  const fetchSegmentedItemsInfo = async (isNewCondition) => {
    if (SellerStore.isLoading) return
    SellerStore.setIsLoading(true)
    const itemCondition = {}
    itemCondition.soldOut = false
    itemCondition.outOfStock = { $ne: true }
    itemCondition.$or = [
      { itemFilters: { $eq: null } },
      {
        itemFilters: {
          $not: {
            $elemMatch: {
              $or: [{ key: 'ad', value: adSettingByTab?.[4].type }], // 체크 필요
            },
          },
        },
      },
    ]

    if (isNewCondition) {
      const itemsCountResult = await backendApis.getSellerItemsCountByCondition(
        {
          type: 'item',
          itemCondition,
        },
      )
      if (itemsCountResult?.status === 200) {
        setItemsCount(itemsCountResult?.data)
      } else {
        setItemsCount(0)
        setPage(1)
        setItemsInfo([])
        window.alert('상품 목록을 불러오는 중 문제가 발생했습니다.')
        SellerStore.setIsLoading(false)
        return
      }
    }

    const result = await backendApis.getSellerSegementedItemsInfoByCondition(
      {
        type: 'item',
        itemCondition,
      },
      page,
      limit,
    )
    if (result?.status === 2000) {
      setItemsInfo(result?.data?.itemsInfo)
    } else if (result?.status === 2001) {
      const maxPage = Math.ceil(result.data?.itemsCount / limit) || 1
      const retryResult =
        await backendApis.getSellerSegementedItemsInfoByCondition(
          {
            type: 'item',
            itemCondition,
          },
          maxPage,
          limit,
        )
      if (retryResult?.status === 2000) {
        setPage(maxPage)
        setItemsInfo(retryResult?.data?.itemsInfo)
      } else {
        setPage(1)
        setItemsInfo([])
        if (retryResult?.status !== 2001) {
          window.alert('상품 목록을 불러오는 중 문제가 발생했습니다.')
        }
      }
    }

    SellerStore.setIsLoading(false)
  }

  const { checkFreeAdAvailable } = alfarmFreeAdSellerFilteringManager()

  const checkFreeAdAvailableAndSet = async () => {
    const result = await checkFreeAdAvailable()
    setFreeAdAvailable(result)
  }

  useEffect(() => {
    const fetchData = async () => {
      await SellerStore.loadSettlements()
    }
    fetchData()

    const filteredData = SellerStore?.settlements?.filter((item) => {
      const threeWeeksAgo = new Date(Date.now() - 1000 * 60 * 60 * 24 * 21) // Corrected the threeWeeksAgo calculation
      return !item?.errorType && new Date(item?.createdAt) > threeWeeksAgo // Ensured both are Date objects for comparison
    })
    const settlementSum = filteredData?.reduce((acc, cur) => {
      return acc + cur?.totalSettlementAmount
    }, 0)

    setSellerSettlementAmount(settlementSum)
  }, [])

  useEffect(() => {
    fetchSegmentedItemsInfo(true)
    checkFreeAdAvailableAndSet()
  }, [adStatusTab])

  useEffect(() => {
    if (itemsInfo?.length) {
      fetchSegmentedItemsInfo(false)
    }
  }, [page])

  useEffect(() => {
    setCheckedItemIds([])
    setCheckedItemsTitle([])
  }, [startDate])

  useEffect(() => {
    // 여기서 셀러 정보를 가져와서 지금까지 신청한 광고 정보를 얻는다.
    const fetchRegisteredAdInfo = async () => {
      const convertedStartDate = moment(new Date(startDate))
        .startOf('day')
        .subtract(4, 'days')
      const convertedEndDate = moment(new Date(startDate))
        .startOf('day')
        .subtract(-4, 'days')

      const result = await backendApis.getEggBreakersAdBetweenDate(
        convertedStartDate,
        convertedEndDate,
      )

      const allItemIds = result?.data?.flatMap(
        (entry) => entry.detailInfo.itemIds,
      )
      const uniqueItemIds = [...new Set(allItemIds)]

      setAlreadyRegisteredItemIds(uniqueItemIds)
    }
    fetchRegisteredAdInfo()
  }, [adStatusTab, startDate])

  return (
    <div className='w-[80vw]'>
      <div className='w-2/3 '>
        {freeAdAvailable && <AlfarmFreeAdEventNoticeComponent />}
        {/* <div className='flex flex-col items-start flex-1 p-8 mb-5 bg-white shadow-lg rounded-xl'>
          <div className='flex flex-row items-center mb-5 text-xl font-bold'>
            <div className='mr-3 text-2xl text-red-500'>공지사항</div>
            <div className='mr-3 text-2xl text-amber-500'>
              포춘쿠키 광고가 업그레이드 되었습니다.
            </div>
          </div>
          <div>
            판매자 분들에게 더 많은 상품 노출과 판매를 만들어드리기 위해
            포춘쿠키의 광고 방식이 변경되었습니다.
            <br />{' '}
            <span className='font-bold'>
              판매자 분들은 최소 5개 이상의 상품을 광고 신청해야 하며, 가장 처음
              선택한 상품이 대표 상품이 됩니다.
            </span>
            <br /> - 고객님이 포춘쿠키를 깨면 '대표 상품'의 이미지가 카드 형태로
            먼저 보여지고,
            <br />- 고객님이 이미지를 본 후 '상품 구매하기' 버튼을 누르면 광고를
            신청한 다른 상품들까지 한 번에 노출됩니다.
          </div>
        </div> */}
        <div className='flex flex-col items-start flex-1 p-8 mb-5 bg-white shadow-lg rounded-xl'>
          <div className='flex flex-row items-center mb-5 text-xl font-bold'>
            <div className='mr-3 text-3xl text-amber-500'>All</div> 모든 등급의
            상품 신청 가능합니다.
          </div>
          <div> 15초 노출 보장! 포춘쿠키 광고를 만나보세요.</div>
        </div>
        {/* <EggBreakAdRevenueGraph /> */}

        <div>
          <EggBreakAdApplicationGraph />
        </div>
        <div className='mt-12 mb-10'>
          <ApplyTabHandler
            applyStatusTab={adStatusTab}
            setApplyStatusTab={setAdStatusTab}
            setPage={setPage}
            btnText1='광고 신청'
            btnText2='신청 완료'
          />
        </div>
      </div>
      {adStatusTab === 'candidate' ? (
        <div className='flex flex-row flex-1'>
          <div className='flex w-2/3 p-10 bg-white shadow-lg rounded-xl'>
            <div className='flex flex-col flex-1'>
              <div className='mb-12'>
                <div className='mb-5 text-2xl font-bold'>
                  올팜 포춘쿠키 광고 신청
                </div>
                <div className='flex flex-col flex-1 p-5 bg-gray-100 rounded-xl'>
                  <span className='flex flex-row items-center flex-1 mb-1 font-bold'>
                    <BulbIcon className='mr-2' />
                    포춘쿠키 광고란?
                  </span>
                  <ul className='ml-5 list-disc'>
                    <li>
                      포춘쿠키 광고는 올팜(농장 게임)에 상품을 노출하는
                      광고입니다.
                    </li>
                    <li>
                      올팜은 고객이 선택한 작물을 키우고 키운 작물을 공짜로 받는
                      미니 프로그램으로 매일 120만 명의 고객이 이용하고
                      있습니다.
                    </li>
                    <li>
                      작물을 키우기 위해 물과 양분이 필요하며, 고객님이
                      판매자님의 광고 상품을 15초간 구경하면 물을 보상으로
                      지급합니다.
                    </li>
                    <li>
                      고객님이 제품을 구매할 시 올웨이즈에서 대량의 물과 양분을
                      지급하여 상품 판매를 유도합니다.
                    </li>
                    <li>
                      고객님이 올팜에서 포춘쿠키를 깨면{' '}
                      <span className='font-bold text-blue-500'>
                        '대표 상품'의 이미지가 담긴 카드가 등장하고, 이후 다양한
                        상품들의 15초 노출을 보장합니다.
                      </span>
                    </li>
                    <li>
                      대표 상품은 쿠폰, 최저가 등의 정보를 추가로 노출하여
                      이목을 집중 시킬 수 있습니다. (대표 상품에만 한정)
                    </li>
                    <li>
                      포춘쿠키는 최소{' '}
                      <span className='font-bold text-blue-500'>
                        5만 건 부터
                      </span>{' '}
                      구입 가능합니다.
                    </li>
                    <li>
                      신청해주신 상품들의 입찰가, 상품 퀄리티, 상품 적합성 등을
                      복합적으로 고려하여{' '}
                      <span className='font-bold text-blue-500'>광고 승인</span>
                      이 진행됩니다.
                    </li>
                  </ul>
                </div>
              </div>
              <div className='mb-12'>
                <div className='mb-3 text-xl font-bold'>1. 진행 일자</div>
                <div className='flex flex-row flex-1'>
                  <Calendar
                    onChange={setStartDate}
                    value={startDate}
                    maxDate={
                      new Date(moment(new Date()).startOf('day').add(9, 'days'))
                    }
                    minDate={
                      new Date(moment(new Date()).startOf('day').add(3, 'days'))
                    }
                  />
                  <div className='flex flex-col items-end justify-between flex-1 ml-5'>
                    <div className='flex flex-col p-5 bg-gray-100 rounded-xl'>
                      <div className='flex flex-row items-center flex-1 mb-1'>
                        <CheckIcon className='mr-2' />
                        <span className='font-bold'>신청 후 검수 안내</span>
                      </div>
                      <div>
                        포춘쿠키 광고는{' '}
                        <span className='font-bold text-blue-500'>
                          신청 후 검수
                        </span>
                        를 통해 진행됩니다. 검수 과정에는 상품의 적절성, 가격
                        등이 포함됩니다.
                      </div>
                      <div>
                        날짜별로 구좌 제한이 있습니다. 광고 신청 후 검수가
                        완료되면 본 페이지 및 SMS를 통해 알려드립니다.
                      </div>
                    </div>
                    <div className='flex flex-col items-end'>
                      <div>광고 시작 일자</div>
                      <div className='flex flex-row items-end justify-end flex-1 my-3'>
                        <div className='mr-2 text-gray-500'>
                          {moment(new Date(startDate)).format('YYYY년')}
                        </div>
                        <div className='text-4xl font-bold'>
                          {moment(new Date(startDate)).format('MM월 DD일')}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='mb-12'>
                <div className='mb-3 text-xl font-bold'>2. 상품 선정</div>
                <div>
                  <li>포춘쿠키 광고를 진행할 상품을 선택해주세요.</li>
                  <li>상품은 최소 5개, 최대 50개까지 선택가능해요.</li>
                  <li>
                    상품 선택순으로 노출돼요. 상단에 노출할 상품을 먼저
                    선택해주세요.
                  </li>
                  <li className='text-red-500 font-bold'>
                    가장 처음 선택한 상품이 대표 상품이 됩니다.
                  </li>
                  <li>
                    상품들이 동시 노출되기 때문에 상품 숫자가 많을수록 판매에
                    유리해요.
                  </li>
                  <Table columns={columns} data={data} />
                  <Pagination
                    total={itemsCount}
                    limit={limit}
                    page={page}
                    setPage={setPage}
                    size='lg'
                  />
                </div>
              </div>
              <div className='mb-12'>
                <div className='mt-5 mb-3 text-xl font-bold'>
                  3. 대표 상품 최저가/쿠폰 여부 설정{' '}
                </div>
                <div>대표 상품의 특장점을 선택해주세요.</div>
                <div className='mb-3 font-bold'>
                  필수 사항이 아니에요. 최저가/쿠폰이 아니어도 선정될 수 있어요.
                </div>
                <div>
                  <div className='flex flex-row justify-between flex-1'>
                    <div>
                      <div className='flex flex-col p-5 mb-5 bg-gray-100 rounded-xl'>
                        <div className='flex flex-row items-center flex-1 mb-1'>
                          <CheckIcon className='mr-2' />
                          <span className='font-bold'>특성 작성 안내</span>
                        </div>
                        <div>
                          최저가, 쿠폰 여부를 선택해주세요. <br />
                          체크 여부에 따라 대표 상품의 이미지 표기가 달라져요.{' '}
                          <br />
                          사실대로 작성해주세요. 검수 결과 사실과 다른 경우
                          광고는 반려돼요.
                          <br />
                          선택을 마친 후 하단의 '적용하기' 버튼을 눌러주세요.
                        </div>
                      </div>
                      <form
                        onSubmit={handleSubmit(onSubmit)}
                        className='flex flex-col gap-2 '
                      >
                        <label htmlFor='price' className='checkbox-label'>
                          <input
                            id='price'
                            type='checkbox'
                            {...register('price', true)}
                            style={{
                              marginRight: '5px',
                            }}
                          />{' '}
                          온라인 최저가 여부
                        </label>
                        <label htmlFor='coupon' className='checkbox-label'>
                          <input
                            id='coupon'
                            type='checkbox'
                            {...register('coupon', true)}
                            style={{
                              marginRight: '5px',
                            }}
                          />{' '}
                          쿠폰 제공 여부
                        </label>
                        <input
                          type='submit'
                          value='적용하기'
                          className='py-2 mt-5 text-lg font-bold text-white border-b-2 border-r-2 border-gray-700 rounded-lg shadow-md bg-gradient-to-r from-gray-400 to-gray-600 hover:bg-gradient-to-r hover:from-gray-500 hover:to-gray-800'
                        />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className='mt-5 mb-3 text-xl font-bold'>
                  4. 광고 수량 선택
                </div>
                <div>광고 수량과 개당 가격을 선택해주세요.</div>
                <div>
                  광고 구좌는 일 소진량이 정해져있는 한정 구좌로 상품 등급과
                  입찰가, 상품 가격을 반영하여{' '}
                  <span className='font-bold text-blue-500'>일단위로 선정</span>
                  됩니다.
                </div>
                <div className='mb-3'>
                  최소 입찰가는 7원이며 입찰가가 높을 수록 선정에 유리합니다.
                </div>
                <div className='flex flex-row flex-1'>
                  <div className='mr-5'>
                    <div className='font-bold'>광고 수량</div>
                    <select
                      onChange={(e) => {
                        setEggCount(e.target.value)
                      }}
                    >
                      <option value={50000}>5만 개</option>
                      <option value={100000}>10만 개</option>
                      <option value={200000}>20만 개</option>
                      <option value={400000}>40만 개</option>
                      <option value={600000}>60만 개</option>
                    </select>
                  </div>
                  <div className='mr-5'>
                    <div className='font-bold'>광고당 비용(₩)</div>
                    <input
                      type='number'
                      value={impCost}
                      onChange={(e) => {
                        setImpCost(e.target.value)
                      }}
                      min={7}
                      max={100}
                    />
                  </div>
                  <div className='flex flex-col justify-start flex-1 mr-5'>
                    <div className='mb-2 font-bold'>결제 방식</div>
                    <div className='flex items-center flex-1'>
                      <input type='radio' checked className='mr-2' readOnly />{' '}
                      정산액 차감
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {!freeAdTab && (
            <div className='fixed right-0 z-50 flex flex-col items-start flex-1 w-1/4 p-10 mr-20 bg-white shadow-xl rounded-xl bottom-10'>
              {/* 무료 광고 가능할 때에만 스위칭 버튼 노출 */}
              {freeAdAvailable && (
                <div className='flex-algin'>
                  <Button className='mb-6 mr-4' appearance='positive'>
                    일반 광고 신청
                  </Button>
                  <Button
                    appearance='neutral'
                    onClick={() => setFreeAdTab(true)}
                  >
                    무료 광고 신청 (이벤트)
                  </Button>
                </div>
              )}
              <div className='mb-5'>
                <div className='mb-3 text-lg font-bold'>광고 신청서</div>
                <div className='mb-2'>
                  신청일자:{' '}
                  <span className='font-bold text-blue-500'>
                    {moment(new Date(startDate)).format('MM월 DD일')}
                  </span>
                </div>
                <div className='mb-0'>
                  대표 상품:{' '}
                  <span className='font-bold text-amber-500'>
                    {checkedItemsTitle?.[0]}
                  </span>
                </div>
                <div className='mb-0'>
                  - 대표 상품의 온라인 최저가 여부:{' '}
                  <span className='font-bold text-amber-500'>
                    {adInfo.price ? 'O' : 'X'}
                  </span>
                </div>
                <div className='mb-4'>
                  - 대표 상품의 쿠폰 제공 여부:{' '}
                  <span className='font-bold text-amber-500'>
                    {adInfo.coupon ? 'O' : 'X'}
                  </span>
                </div>
                <div className='mb-2'>
                  신청 상품명(노출 순서) <br />
                  {checkedItemsTitle?.map((item, index) => {
                    return (
                      <div key={item._id}>
                        {index + 1}.{' '}
                        <span className='font-bold text-blue-500'>
                          {item} <br />{' '}
                        </span>
                      </div>
                    )
                  })}
                </div>
                <div className='mb-2'>
                  신청 광고 수:{' '}
                  <span className='font-bold text-blue-500'>
                    {commaNumber(eggCount)}
                  </span>
                  회
                </div>
                <div className='mb-2'>
                  총 가격:{' '}
                  <span className='font-bold text-blue-500'>
                    ￦{commaNumber(eggCount * impCost)}(정산액 차감)
                  </span>
                </div>
              </div>
              <Button
                onClick={async () => {
                  const adUnit = {
                    itemIds:
                      checkedItemIds?.filter(
                        (item, index, self) =>
                          item && index === self.findIndex((t) => t === item),
                      ) || [],
                    itemId: checkedItemIds?.[0],
                    itemImage: checkedItemInfo?.[0]?.mainImageUris?.[0],
                    price: adInfo?.price,
                    coupon: adInfo?.coupon,
                    eggCount,
                    costPerEgg: impCost,
                    adStartedAt: new Date(
                      moment(new Date(startDate)).startOf('day'),
                    ),
                    adEndedAt: new Date(
                      moment(new Date(startDate)).startOf('day').add(3, 'days'),
                    ),
                  }
                  if (sellerSettlementAmount <= 0) {
                    alert(
                      '최근 3주 정산액이 없는 경우 광고 신청이 불가능합니다.',
                    )
                    return
                  }
                  if (checkedItemIds?.length <= 4) {
                    alert(`최소 5개의 상품을 선택해주세요.`)
                    return
                  }
                  if (!adUnit.eggCount) {
                    alert('광고 수량을 입력해주세요.')
                    return
                  }
                  if (!adUnit.costPerEgg) {
                    alert('광고당 비용을 입력해주세요.')
                    return
                  }
                  if (adUnit.costPerEgg < 7) {
                    alert('광고 최소 입찰가는 광고당 7원입니다.')
                    return
                  }
                  if (adUnit.costPerEgg.toString().includes('.')) {
                    alert('단가는 정수로 입력해야 합니다.')
                    return
                  }
                  if (!adUnit.adStartedAt) {
                    alert('광고 시작일을 입력해주세요.')
                    return
                  }
                  if (SellerStore.isLoading) return
                  SellerStore.setIsLoading(true)
                  const result = await backendApis.registerEggAdV2(adUnit)
                  if (result?.status === 200) {
                    alert(
                      '광고 신청이 완료되었습니다. 입력해주신 광고 시작일자 전일에 광고 진행이 확정되며 승인된 건에 한하여 광고가 시작됩니다.',
                    )
                    cleanUp()

                    setAdStatusTab('complete')
                  } else {
                    alert('광고 신청에 실패했습니다. 다시 시도해주세요.')
                  }
                  SellerStore.setIsLoading(false)
                }}
                appearance='positive'
                size='flexlg'
              >
                신청하기
              </Button>
            </div>
          )}
          {/* 무료 광고용 신청모달 */}
          {freeAdTab && (
            <AlfarmFreeAdModalForEgg
              startDate={startDate}
              checkedItemsTitle={checkedItemsTitle}
              checkedItemIds={checkedItemIds}
              cleanUp={cleanUp}
              setAdStatusTab={setAdStatusTab}
              setFreeAdTab={setFreeAdTab}
              checkedItemInfo={checkedItemInfo}
              adInfo={adInfo}
            />
          )}
        </div>
      ) : (
        <div>
          <EggAdStatusBar />
        </div>
      )}
    </div>
  )
}
export default AlfarmEggBreakersScreenV2
