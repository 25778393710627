const IntroSection = () => {
  const Explain = () => {
    return (
      <div className='mb-5 text-left'>
        올웨이즈는 사업주님의 안전한 판매활동과 정상적인 정산을 위해
        <br />
        정산담당 파트너사인 나이스페이먼츠와 함께 사업자 정보를 검증하고
        있습니다.
        <br />
        <br />
        보안 강화를 위해 아래 안내에 따라 사업자 정보 검증 절차를 진행해주세요.
        <br />
        <br />
        <span className='font-bold text-blue-500'>
          ✅ 적합성검사를 통과하지 않은 사업주님들은 판매활동 및 정산이 중단될
          수 있습니다.
          <br />✅ 미승인으로 인한 판매 및 정산 상의 불이익이 발생하지 않도록
          업데이트 권장 드립니다.
        </span>
      </div>
    )
  }
  return (
    <div className='p-5'>
      <Explain />
      <div className='mt-8 space-y-4'>
        <h4 className='font-bold text-lg'>진행 단계 안내</h4>
        <div className='space-y-3'>
          <div className='flex items-start'>
            <span className='font-medium mr-2'>1.</span>
            <p>
              사업자등록증 업로드 및 검증: <br />
              가장 최근 발급받은 사업자등록증을 스캔하여 업로드하면 사업자등록증
              적합성 검증이 시작됩니다.
            </p>
          </div>
          <div className='flex items-start'>
            <span className='font-medium mr-2'>2.</span>
            <p>
              사업자등록번호 등록: <br />
              사업자등록증에 기재된 번호를 입력하면 국세청을 통해 계속사업자
              여부를 검증합니다.
            </p>
          </div>
          <div className='flex items-start'>
            <span className='font-medium mr-2'>3.</span>
            <p>
              사업자계좌 등록: <br />
              정산받으실 사업자명의 계좌를 등록하면 나이스페이먼츠를 통해
              계좌적합성을 검증합니다.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default IntroSection
