import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory, useLocation } from 'react-router-dom'
import backendApis from '../utils/backendApis'
import SellerStore from '../stores/SellerStore'
import AuthStore from '../stores/AuthStore'
import queryString from 'query-string'
import Button from '../components/atoms/button'

const RegisterSellerScreen = observer(() => {
  const history = useHistory()
  const { search } = useLocation()
  const { recommender } = queryString.parse(search)
  const [sellerName, setSellerName] = useState('')
  const [password, setPassword] = useState('')
  const [sellerDisplayName, setSellerDisplayName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [email, setEmail] = useState('')
  const [businessRegistrationNumber, setBusinessRegistrationNumber] = useState()
  const [isNewBusinessRegistrationNumber, setIsNewBusinessRegistrationNumber] =
    useState(false)
  const [representorName, setRepresentorName] = useState('')
  const [recommenderName, setRecommenderName] = useState(recommender || '')
  const [route, setRoute] = useState('')
  const [termsOpened, setTermsOpened] = useState(false)
  const [termsAgreed, setTermsAgreed] = useState(false)

  function validateId(id) {
    const pattern = /^[a-z0-9_.-]{6,20}$/
    return pattern.test(id)
  }

  function validatePassword(password) {
    const pattern =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,15}$/
    return pattern.test(password)
  }

  function isValidRegistrationNumber(registrationNumber) {
    const regExp = /^\d{3}-?\d{2}-?\d{5}$/ // 하이픈이 있는 경우와 없는 경우 모두를 포함한 정규표현식
    if (!regExp.test(registrationNumber)) {
      // 정규표현식에 맞지 않는 경우
      return false
    }
    const numbers = registrationNumber.replace(/-/g, '') // 하이픈을 제거한 숫자만 추출
    if (numbers.length !== 10) {
      // 총 자릿수가 10자리가 아닌 경우
      return false
    }
    return true
  }

  function addHyphenToPhoneNumber(phoneNumber) {
    // 숫자 세 개씩 끊어서 하이픈을 추가한 전화번호 생성
    const regex = /(\d{1,3})(\d{1,4})?(\d{0,4})?/
    const matches = phoneNumber.match(regex)
    if (matches) {
      const firstPart = matches[1]
      const secondPart = matches[2] ? `-${matches[2]}` : ''
      const thirdPart = matches[3] ? `-${matches[3]}` : ''
      return firstPart + secondPart + thirdPart
    }
    return phoneNumber
  }

  const handlePhoneNumberChange = (event) => {
    const rawPhoneNumber = event.target.value.replace(/[^0-9]/g, '') // 입력된 값에서 숫자만 추출
    const formattedPhoneNumber = addHyphenToPhoneNumber(rawPhoneNumber) // 하이픈을 추가한 전화번호 생성
    setPhoneNumber(formattedPhoneNumber) // 입력된 전화번호 설정
  }

  const handleBusinessNumberChange = (event) => {
    const rawBusinessNumber = event.target.value.replace(/[^0-9]/g, '') // 입력된 값에서 숫자만 추출
    const formattedBusinessNumber = addHyphenToBusinessNumber(rawBusinessNumber) // 하이픈을 추가한 사업자 등록번호 생성
    setBusinessRegistrationNumber(formattedBusinessNumber) // 입력된 사업자 등록번호 설정
    setIsNewBusinessRegistrationNumber(false) // 사업자 번호 변경시 중복검사 다시 하도록 설정
  }

  const addHyphenToBusinessNumber = (businessNumber) => {
    // 숫자 네 개씩 끊어서 하이픈을 추가한 사업자 등록번호 생성
    const regex = /(\d{1,3})(\d{1,2})?(\d{1,5})?(\d{0,1})?/
    const matches = businessNumber.match(regex)
    if (matches) {
      const firstPart = matches[1]
      const secondPart = matches[2] ? `-${matches[2]}` : ''
      const thirdPart = matches[3] ? `-${matches[3]}` : ''
      const fourthPart = matches[4] ? `-${matches[4]}` : ''
      return firstPart + secondPart + thirdPart + fourthPart
    }
    return businessNumber
  }

  function maskString(str, start, end, mask = '*') {
    return str.slice(0, start) + mask.repeat(end - start) + str.slice(end)
  }

  return (
    <div className='flex flex-col items-center bg-gradient-to-r from-red-500 to-red-400 py-12'>
      <div className='flex flex-col items-center min-w-[480px] prose text-center bg-white rounded-xl'>
        <div className='flex flex-col justify-center'>
          <h2>
            <span className='text-theme-500'>올웨이즈</span>와 함께 하기 위해
            <br />
            입점사 신청을 부탁드려요
          </h2>

          <span className='flex flex-row justify-start flex-1 mb-1 text-sm text-gray-500'>
            아이디
          </span>
          <input
            className='mb-1 bg-red-100 rounded-md h-[48px] w-full'
            type='text'
            placeholder='아이디'
            value={sellerName}
            onChange={(e) => {
              setSellerName(e.target.value)
            }}
          />
          {validateId(sellerName) ? (
            <span className='flex flex-row justify-start flex-1 mb-1 text-xs text-blue-500'>
              사용 가능한 아이디입니다.
            </span>
          ) : (
            <span className='flex flex-row justify-start flex-1 mb-1 text-xs text-red-500'>
              *6~20자의 영문 소문자, 숫자와 특수문자(_, -, .)만 사용 가능
            </span>
          )}
          <span className='flex flex-row justify-start flex-1 mb-1 text-sm text-gray-500'>
            비밀번호
          </span>
          <input
            className='mb-1 bg-red-100 rounded-md h-[48px]'
            type='password'
            placeholder='비밀번호'
            value={password}
            onChange={(e) => {
              setPassword(e.target.value)
            }}
          />
          <span className='flex flex-row justify-start flex-1 mb-1 text-xs text-red-500'>
            {validatePassword(password) ? (
              <span className='flex flex-row justify-start flex-1 mb-1 text-xs text-blue-500'>
                사용 가능한 비밀번호입니다.
              </span>
            ) : (
              <span className='flex flex-row justify-start flex-1 mb-2 text-xs text-red-500'>
                *8-15자 사이 / 영문, 숫자, 특수문자를 모두 포함
              </span>
            )}
          </span>
          <span className='flex flex-row justify-start flex-1 mb-1 text-sm text-gray-500'>
            판매처 업체명
          </span>
          <input
            className='mb-2 bg-red-100 rounded-md h-[48px]'
            type='text'
            value={sellerDisplayName}
            placeholder='사업자등록증상 업체명'
            onChange={(e) => {
              console.log(`text: ${e.target.value}`)
              setSellerDisplayName(e.target.value)
            }}
          />
          <span className='flex flex-row justify-start flex-1 mb-1 text-sm text-gray-500'>
            대표자 성함
          </span>
          <input
            className='mb-2 bg-red-100 rounded-md h-[48px]'
            type='text'
            value={representorName}
            placeholder='대표자 성함'
            onChange={(e) => {
              setRepresentorName(e.target.value)
            }}
          />
          <span className='flex flex-row justify-start flex-1 mb-1 text-sm text-gray-500'>
            대표자 핸드폰 번호
          </span>
          <input
            className='mb-2 bg-red-100 rounded-md h-[48px]'
            type='text'
            value={phoneNumber}
            placeholder='대표자 핸드폰 번호'
            onChange={handlePhoneNumberChange}
          />
          <span className='flex flex-row justify-start flex-1 mb-1 text-sm text-gray-500'>
            판매처 이메일
          </span>
          <input
            className='mb-2 bg-red-100 rounded-md h-[48px]'
            type='email'
            value={email}
            placeholder='판매처 이메일'
            onChange={(e) => {
              setEmail(e.target.value)
            }}
          />

          <span className='flex flex-row justify-start flex-1 mb-1 text-sm text-gray-500'>
            사업자 등록번호
          </span>
          <div className='flex flex-row items-center justify-between flex-1 h-[48px]'>
            <input
              className='flex flex-1 mb-2 mr-2 bg-red-100 rounded-md'
              type='text'
              value={businessRegistrationNumber}
              placeholder='사업자 등록번호'
              maxLength={12}
              onChange={handleBusinessNumberChange}
            />
            <Button
              appearance='neutral'
              onClick={async () => {
                if (!isValidRegistrationNumber(businessRegistrationNumber)) {
                  alert(
                    `사업자 등록번호는 하이픈(-)을 포함한 10자리 숫자로 적어주세요.
실제로 존재하는 사업자 번호를 적어주셔야 합니다.`,
                  )
                  return
                }
                const result = await backendApis.checkSellerBizNum(
                  businessRegistrationNumber,
                )
                if (result.status === 2000) {
                  setIsNewBusinessRegistrationNumber(false)
                  alert(
                    `이미 등록된 사업자 번호입니다. 기존 등록 아이디는 ${maskString(
                      result?.data?.sellerName,
                      2,
                      4,
                    )}입니다.
올웨이즈는 한 사업자 번호당 한 아이디만 등록할 수 있습니다.`,
                  )
                } else {
                  setIsNewBusinessRegistrationNumber(true)
                  alert(`등록 이력이 없는 사업자 번호입니다.
                  
*등록 이후 사업자 등록증 제출 및 증빙 과정이 있습니다.
*유효한 사업자 번호가 아닌 경우 판매 진행이 불가합니다.`)
                }
              }}
            >
              중복검사
            </Button>
          </div>

          <span className='flex flex-row justify-start flex-1 mb-1 text-sm text-gray-500'>
            추천인 아이디
          </span>
          <input
            className='mb-4 bg-red-100 rounded-md h-1/10'
            type='text'
            value={recommenderName}
            placeholder='추천인 아이디'
            onChange={(e) => {
              setRecommenderName(e.target.value)
            }}
          />
          <div className='flex flex-col items-center mb-4'>
            유입 경로를 선택해주세요
            <div className='flex flex-row items-center'>
              <input
                className='mr-3'
                type='radio'
                name='route'
                value='Marketing'
                onChange={(e) => {
                  setRoute(e.target.value)
                }}
              />
              마케팅
              <input
                className='mx-3'
                type='radio'
                name='route'
                value='People'
                onChange={(e) => {
                  setRoute(e.target.value)
                }}
              />
              지인소개
              <input
                className='mx-3'
                type='radio'
                name='route'
                value='Call'
                onChange={(e) => {
                  setRoute(e.target.value)
                }}
              />
              전화영업
            </div>
          </div>
          <div className='font-bold'>
            <button
              type='button'
              className='font-bold text-blue-500'
              onClick={() => {
                setTermsOpened(true)
                window.open(
                  'https://levitinc.notion.site/72eed1be3a584a5b85c7335225a98ced',
                  '_blank',
                )
              }}
            >
              판매 이용약관 열람하기
            </button>
          </div>
          <div className='mb-4'>
            <input
              type='checkbox'
              className='mr-5'
              value={termsAgreed}
              onClick={(v) => {
                alert('판매 이용약관을 반드시 확인해주세요.')
                setTermsAgreed(v.target.checked)
              }}
            />
            올웨이즈 판매 이용약관에 동의합니다.
          </div>
          <button
            className='py-4 px-16 mb-10 text-xl font-black text-white rounded-xl bg-theme-500'
            type='button'
            onClick={async () => {
              const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
              const phoneNumberRegex = /^010-\d{3,4}-\d{4}$/
              if (!isNewBusinessRegistrationNumber) {
                alert('사업자 등록번호 중복검사를 해주세요.')
                return
              }

              if (!sellerName) {
                alert('아이디를 입력해주세요.')
                return
              }
              if (!password) {
                alert('비밀번호를 입력해주세요.')
                return
              }
              if (!sellerDisplayName) {
                alert('판매처 업체명을 입력해주세요.')
                return
              }
              if (!representorName) {
                alert('대표자 성함을 입력해주세요.')
                return
              }
              if (!phoneNumber) {
                alert('대표자 핸드폰 번호를 입력해주세요.')
                return
              }
              if (!email) {
                alert('판매처 이메일을 입력해주세요.')
                return
              }
              if (!businessRegistrationNumber) {
                alert('사업자 등록번호를 입력해주세요.')
                return
              }

              if (!route) {
                alert('유입 경로를 선택해주세요.')
                return
              }

              if (!isNewBusinessRegistrationNumber) {
                alert('사업자 등록번호 중복검사를 해주세요.')
                return
              }
              if (!validateId(sellerName)) {
                alert(
                  '아이디 형식이 올바르지 않습니다. 공백 또는 한글은 허용되지 않습니다.',
                )
                return
              }
              if (!validatePassword(password)) {
                alert(
                  '비밀번호 형식이 올바르지 않습니다. 8자 - 15자 사이, 영문, 숫자, 특수문자를 포함해주세요.',
                )
                return
              }
              if (!emailRegex.test(email)) {
                alert('이메일 형식이 올바르지 않습니다.')
                return
              }
              if (!phoneNumberRegex.test(phoneNumber)) {
                alert(
                  '핸드폰 번호 형식이 올바르지 않습니다. 하이픈 없이 숫자만 입력해주세요.',
                )
                return
              }
              if (!termsAgreed) {
                alert('이용약관에 동의해주세요.')
                return
              }
              if (SellerStore.isLoading) {
                return
              }
              SellerStore.setIsLoading(true)

              const result = await backendApis.registerSeller(
                sellerName,
                password,
                sellerDisplayName,
                representorName,
                phoneNumber?.replace(/[-\s]/g, ''),
                email,
                recommenderName,
                route,
                termsAgreed,
              )
              if (result?.status === 200) {
                alert(
                  `${sellerDisplayName}님 가입이 완료되었습니다.이메일로 입점 절차 및 매출 활성화 가이드를 확인 부탁드립니다.`,
                )
                SellerStore.setIsLoading(false)
                setSellerName('')
                setSellerDisplayName('')
                setPassword('')
                setPhoneNumber('')
                setEmail('')
                setRepresentorName('')
                AuthStore.setToken(result.data.token)
                localStorage.setItem('@alwayz@seller@token@', result.data.token)
                const loadSellerResult = await SellerStore.loadSellerInfo()
                if (loadSellerResult) {
                  SellerStore.setIsLoading(false)
                  history.replace('/register-basics')
                } else {
                  alert(
                    '정보를 불러오는데 실패했습니다. 잠시 후 다시 시도해주세요.',
                  )
                }
              } else if (result.status === 401) {
                alert('이미 존재하는 아이디입니다.')
              } else {
                alert('등록에 실패했습니다. 잠시 후 다시 시도해주세요.')
              }

              SellerStore.setIsLoading(false)
            }}
          >
            입점사 신청
          </button>
        </div>
      </div>
    </div>
  )
})

export default RegisterSellerScreen
