import React from 'react'
import { Button, Image } from 'antd'

const Stage0Introduction = ({ dispatch, setModalStatus }) => {
  return (
    <div className='mb-20 text-slate-700'>
      <div>
        <div className='bg-white shadow-lg rounded-xl p-12 flex flex-1 flex-col items-center justify-center'>
          <div className='whitespace-none flex flex-col flex-1 items-start w-full mb-12 text-slate-800'>
            <p>
              올팜 등 인기 서비스들에서 100만 고객에게 상품을 광고하고 판매를
              유도해보세요
            </p>
            <p>AI 맞춤으로 구매 가능성이 높은 고객들에게만 상품을 노출해요</p>
          </div>
          <Image
            src='https://assets.ilevit.com/2154c14f-8bff-42b1-a64d-d05a803b06fb.png'
            preview={false}
          />
        </div>
      </div>
      <div className='mt-5 flex flex-1 w-full gap-5 justify-end'>
        <Button
          type='default'
          onClick={() =>
            setModalStatus((prev) => {
              return { ...prev, adExplanationModal: true }
            })
          }
          className='bg-white'
          size='large'
        >
          CPM 광고 소개 자세히 보기
        </Button>
        <Button
          type='primary'
          onClick={() => dispatch({ type: 'SET_STAGE', payload: 1 })}
          size='large'
        >
          간편 신청하기
        </Button>
      </div>
    </div>
  )
}

export default Stage0Introduction
