import commaNumber from 'comma-number'
import moment from 'moment'
import tableUtils from './tableUtils'
import {
  getCommissionRate,
  getCouponSettlementAmount,
  getSettlementAmount,
  getCouponSettlementAmountForRevenueDiscount,
  getPlatformSupportPriceInfo,
} from '../helper/order'

const Format = {
  Title: (value) => {
    return value.replace(/\p{Emoji_Presentation}/gu, '')
  },
  Recipient: (value) => {
    if (!value) return '고객님'
    return (
      value.replace(/\p{Emoji_Presentation}|\p{S}|[^a-zA-Z0-9가-힣]/gu, '') ||
      '고객님'
    )
  },
  Price: (value) => {
    if (!value) return '0원'
    return `${commaNumber(parseInt(value, 10))}원`
  },
  DateString: (value) => {
    if (!value) return ''
    const saveDate = new Date(value)
    const year = saveDate.getFullYear()
    const month = saveDate.getMonth() + 1
    const date = saveDate.getDate()
    const hours = saveDate.getHours()
    return `${year}년 ${month}월 ${date}일 ${hours}시`
  },
  DateToday: (value) => {
    const saveDate = new Date(value)
    // const year = saveDate.getFullYear()
    // const month = saveDate.getMonth() + 1
    // const date = saveDate.getDate()
    // return `${year}${month}${date}`
    return moment(saveDate).format('YYYYMMDD')
  },

  SettlementExcelHeaders: [
    { header: '주문아이디', key: '_id', width: 40 },
    { header: '합배송 아이디', key: 'addressId', width: 30 },
    { header: '상품명', key: 'itemTitle', width: 30 },
    { header: '옵션', key: 'option', width: 30 },
    { header: '수량', key: 'quantity', width: 15 },
    { header: '주문 상태', key: 'status', width: 15 },
    { header: '결제 수단', key: 'cardOrMoney', width: 15 },
    { header: '상품 구매금액', key: 'totalPrice', width: 15 },
    { header: '배송비', key: 'shippingFee' },
    { header: '면세 금액', key: 'taxFreeAmount' },
    {
      header: '올웨이즈 부담 할인금',
      key: 'discountAmountForRevenueDiscount',
      width: 15,
    },
    {
      header: '판매자 부담 쿠폰할인금',
      key: 'couponNotSettlementAmount',
      width: 25,
    },
    {
      header: '추가 지원금액',
      key: 'platformSupportPrice',
      width: 15,
    },
    { header: '취소 금액', key: 'refundAmount' },
    { header: '기 정산 금액', key: 'originallySettledAmount', width: 15 },
    { header: '기 정산 수수료', key: 'originalCommissionAmount', width: 20 },
    { header: '정산 대상 금액(수수료 제)', key: 'settlementAmount', width: 20 },
    { header: '수수료율(3월부터 유효)', key: 'commissionRate', width: 15 },
    { header: '수수료', key: 'commissionAmount' },
    {
      header: '특별 수수료(기타 매출)',
      key: 'platformSupportPriceRevenue',
    },
    // {
    //   header: '기타 정산금액',
    //   key: 'otherSettlementAmount',
    // },
    { header: '주문 성사 시점', key: 'preShippingTimeStamp', width: 25 },
    { header: '배송 완료 시점', key: 'arrivedTimeStamp', width: 25 },
    { header: '취소 완료 시점', key: 'canceledTimeStamp', width: 25 },
    {
      header: '구매(취소) 확정 시점',
      key: 'orderConfirmedTimeStamp',
      width: 25,
    },
  ],
  SettlementExceptExcelHeaders: [
    { header: '정산 일자', key: 'settlementDate', width: 30 },
    { header: '신상품 매대 체험단', key: 'newItemReviewEvent', width: 30 },
    { header: '사진후기', key: 'alfarmPhotoReview', width: 20 },
    { header: '올팜 퀴즈', key: 'alfarmQuiz', width: 20 },
    { header: '올팜 상점', key: 'alfarmStore', width: 20 },
  ],

  taxInvoiceExcelHeaders: [
    // TODO: 엑셀에 필요한 헤더 추가 및 수정
    { header: '주문아이디', key: '_id', width: 40 },
    { header: '합배송 아이디', key: 'addressId', width: 30 },
    { header: '상품명', key: 'itemTitle', width: 30 },
    { header: '옵션', key: 'option', width: 30 },
    { header: '수량', key: 'quantity', width: 15 },
    { header: '주문 상태', key: 'status', width: 15 },
    { header: '결제 수단', key: 'paymentType', width: 15 },
    { header: '수수료대상 거래금액', key: 'totalPrice', width: 20 },
    { header: '수수료', key: 'commissionAmount', width: 15 },
    { header: '특별수수료', key: 'platformSupportPriceRevenue', width: 20 },
    {
      header: '매출에누리(올웨이즈 부담)',
      key: 'discountAmountForRevenueDiscount',
      width: 60,
    },
    { header: '세금계산서 발급금액', key: 'taxInvoicePrice', width: 30 },
    { header: '구매(취소) 확정 시점', key: 'timeStamp', width: 30 },
  ],

  adDetailExcelHeaders: [
    // TODO: 엑셀에 필요한 헤더 추가 및 수정
    { header: '상품아이디', key: 'itemId', width: 40 },
    { header: '판매가', key: 'itemPrice', width: 30 },
    { header: '소진(유상)', key: 'paidAdCost', width: 30 },
    { header: '소진(무상)', key: 'freeAdCost', width: 30 },
    { header: '소진금액', key: 'adCost', width: 15 },
    { header: '유형', key: 'type', width: 15 },
    { header: '소진일시', key: 'createdAt', width: 30 },
  ],

  checkIfValidUUID: (str) => {
    // Regular expression to check if string is a valid UUID
    const regexExp =
      /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi

    return regexExp.test(str)
  },

  formatOrderForSettlementExcel: (order) => {
    const couponNotSettlementAmount =
      (order?.selectedCouponInfo?.couponInfo?.type === 'bySeller' ||
        order?.selectedCouponInfo?.condition?.type === 'bySeller') &&
      !order?.selectedCouponInfo?.condition?.noCalculate
        ? Number(order?.selectedCouponInfo?.finalDiscountAmount)
        : 0

    const commissionRate = getCommissionRate(order)
    const couponSettlementAmount = getCouponSettlementAmount(order)
    const couponSettlementAmountForRevenueDiscount =
      getCouponSettlementAmountForRevenueDiscount(order)
    const otherSettlementAmount =
      couponSettlementAmount - couponSettlementAmountForRevenueDiscount
    const settlementAmount = getSettlementAmount(order)

    const originallySettledAmount =
      order?.settlementLogs
        ?.map((settlementLog) => settlementLog?.settlementAmount)
        .reduce((a, b) => a + Number(b), 0) || 0
    const originalCommissionAmount =
      order?.settlementLogs
        ?.map((settlementLog) => settlementLog?.commissionAmount)
        .reduce((a, b) => a + Number(b), 0) || 0

    const addressId = tableUtils.parseAddressId(
      order?.payedAt,
      order?.addressInfo?.addressId,
      order?._id,
    )

    const preShippingTimeStamp =
      (order?.preShippingTimeStamp &&
        moment(order?.preShippingTimeStamp).format('YYYY-MM-DD HH:mm')) ||
      '_'

    const arrivedTimeStamp =
      (order?.arrivedInfo?.recordedAt &&
        moment(order?.arrivedInfo?.recordedAt).format('YYYY-MM-DD HH:mm')) ||
      '_'

    const canceledTimeStamp =
      order?.refundedInfo?.recordedAt || order?.canceledInfo?.recordedAt
        ? moment(
            order?.refundedInfo?.recordedAt || order?.canceledInfo?.recordedAt,
          ).format('YYYY-MM-DD HH:mm')
        : '-'

    const orderConfirmedTimeStamp =
      moment(
        order?.refundedInfo?.recordedAt ||
          order?.canceledInfo?.recordedAt ||
          order?.arrivedInfo?.recordedAt,
      )
        .add(7, 'days')
        .format('YYYY-MM-DD HH:mm') || '_'

    const optionsText = order?.itemInfo?.optionsInfo?.optionNames?.map(
      (value, optionIndex) => {
        const optionDetail =
          order?.itemInfo?.optionsInfo?.totalOptions?.[optionIndex]?.[
            order?.selectedOption?.[optionIndex]
          ]?.name
        if (optionDetail === undefined) {
          return ''
        }
        return `${optionIndex}. ${value}: ${optionDetail}`
      },
    )

    const recipientPhoneNumber = order?.addressInfo?.recipientPhoneNumber
    const recipientPhoneNumberProcessed = recipientPhoneNumber

    const {
      originalPlatFormSupportPrice,
      platformSupportPrice,
      platformSupportPriceRevenue,
      platformSupportPriceRevenueDiscount,
    } = getPlatformSupportPriceInfo(order)

    const discountAmountForRevenueDiscount =
      typeof order?.totalReturnFee === 'number'
        ? 0
        : couponSettlementAmountForRevenueDiscount +
            platformSupportPriceRevenueDiscount || 0

    return {
      ...order,
      itemId: order?.itemId || order?.itemInfo?.itemId,
      itemTitle: order?.itemTitle || order?.itemInfo?.itemTitle,
      status: tableUtils.orderStatusName(order?.status),
      addressId,
      optionCode: order?.selectedOption?.optionCode || '',
      option: optionsText?.join('\n') || '',
      quantity: order?.quantity,
      postcode: order?.addressInfo?.postcode,
      frontDoorPassword: order?.addressInfo?.frontDoorPassword,
      detailForReceivingMethod:
        order?.addressInfo?.receivingMethod === 'door'
          ? '문 앞'
          : order?.addressInfo?.detailForReceivingMethod,
      recipient: Format.Recipient(order?.addressInfo?.recipient),
      recipientPhoneNumber: recipientPhoneNumberProcessed,
      shippingCompany: order?.shippingInfo?.shippingCompanyName,
      shippingNumber: order?.shippingInfo?.shippingNumber || '',
      postalShipping: order?.shippingInfo?.isPostalShipping ? 'O' : '-',
      directShipping: order?.shippingInfo?.isDirectShipping ? 'O' : '-',
      payedAt: moment(order?.payedAt).format('YYYY-MM-DD HH:mm'),
      cardOrMoney: order?.paymentType || order?.cardOrMoney,
      totalPrice: order?.totalPrice,
      shippingFee: order?.shippingFee,
      taxFreeAmount: order?.taxFreeAmount,
      couponDiscountAmount:
        typeof order?.totalReturnFee === 'number'
          ? 0
          : couponSettlementAmount || 0,
      couponNotSettlementAmount:
        typeof order?.totalReturnFee === 'number'
          ? 0
          : couponNotSettlementAmount || 0,
      discountAmountForRevenueDiscount,

      // otherSettlementAmount:
      //   typeof order?.totalReturnFee === 'number'
      //     ? 0
      //     : otherSettlementAmount || 0,
      refundAmount: Number(order?.refundAmount || 0),
      platformSupportPrice,
      platformSupportPriceRevenue,
      commissionRate: `${Math.round(commissionRate * 100 * 100) / 100}%`,
      settlementAmount,
      commissionAmount:
        (typeof order?.totalReturnFee === 'number'
          ? order?.totalReturnFee
          : Number(order?.totalPrice) +
            Number(order?.shippingFee) -
            Number(order?.refundAmount || 0) +
            (couponSettlementAmount || 0) +
            originalPlatFormSupportPrice) -
        settlementAmount -
        originallySettledAmount -
        originalCommissionAmount,
      originallySettledAmount,
      originalCommissionAmount,
      preShippingTimeStamp,
      arrivedTimeStamp,
      canceledTimeStamp,
      orderConfirmedTimeStamp,
    }
  },
}
export default Format
