import React, { useState, useEffect, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import SellerStore from '../../stores/SellerStore'
import backendApis from '../../utils/backendApis'
import Pagination from '../../components/molecules/pagination'
import ApplyTabHandler from '../../components/molecules/applyTabHandler'
import ListLimitSelector from '../../components/atoms/listLimitSelector'
import Table from '../../components/ADS/molecules/table'
import adSettingByTab from '../../data/adSettingByTab'
import SearchAdApplyModal from '../../components/molecules/searchAdApplyModal'
import resize from '../../utils/resize'
import RankIconSetter from '../../components/molecules/rankIconSetter'
import Button from '../../components/atoms/button'
import { Bar } from 'react-chartjs-2'
import AdBatchApplyModal from '../adBatchApplyModal'
import { FcIdea, FcHighPriority } from 'react-icons/fc'
import { Tooltip, Badge } from '../../components/ADS/atoms'
import ItemSearchComponent from '../../components/ItemComponents/ItemSearchComponent'
import { useSearchParam } from 'react-use'

const escapeRegExp = (string) => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
// $& means the whole matched string

const AdScreen = observer(() => {
  const [page, setPage] = useState(1)
  const [tab, setTab] = useState(0)
  const [adStatusTab, setAdStatusTab] = useState('candidate')
  const [limit, setLimit] = useState(20)
  const [itemsInfo, setItemsInfo] = useState([])
  const [selectedItemInfo, setSelectedItemInfo] = useState({})
  const [itemsCount, setItemsCount] = useState(0)
  const [showAdApplyModal, setShowAdApplyModal] = useState(false)
  const [showAdBatchApplyModal, setShowAdBatchApplyModal] = useState(false)
  const [itemQuery, setItemQuery] = useState('')
  const [chartData, setChartData] = useState([])
  const [loadChartData, setLoadChartData] = useState(false)
  const batchApplyOn = useSearchParam('batchApplyOn')

  const columns = useMemo(() => {
    let result = [
      { Header: '상태', accessor: 'status' },
      { Header: '상품정보', accessor: 'itemInfo' },
      { Header: '시작 날짜', accessor: 'adCreatedAt' },
      { Header: '일 예산', accessor: 'dailyCostLimit' },
      {
        Header: (
          <>
            클릭수
            <Tooltip text='해당 상품의 광고가 클릭된 횟수입니다.' />
          </>
        ),
        accessor: 'visits',
      },
      {
        Header: (
          <>
            판매수
            <Tooltip
              text='해당 상품이 광고로 판매된 횟수입니다.<br/>
              광고 기여도가 있는 판매건만 집계됩니다.'
            />
          </>
        ),
        accessor: 'purchase',
      },
      {
        Header: (
          <>
            총 비용(VAT 포함)
            <Tooltip text='부가세(VAT)가 포함된, 해당 상품의 광고 소진액입니다.' />
          </>
        ),
        accessor: 'adCost',
      },
      {
        Header: (
          <>
            ROAS
            <Tooltip
              text='ROAS는 (광고 전환 매출/부가세 제외 광고비)로 계산되며<br/>
                광고가 얼마나 효율적인지 나타내는 지표입니다.<br/>
              (ex. ROAS 600%이면 투입한 광고 비용의 <br/>6배만큼 매출이 발생한 것입니다.)'
            />
          </>
        ),
        accessor: 'roas',
      },
      {
        Header: (
          <>
            광고 전환 매출
            <Tooltip text='광고로 발생한 매출액입니다.' />
          </>
        ),
        accessor: 'revenueWithAd',
      },
    ]

    if (adStatusTab === 'candidate') {
      result = [
        { Header: '상태', accessor: 'status' },
        { Header: '상품정보', accessor: 'itemInfo' },
      ]
    }

    return result
  }, [adStatusTab])

  const handleSearch = (selectedSearchField, itemQuery) => {
    fetchSegmentedItemsInfo(true, '', selectedSearchField, itemQuery) // 검색 정보 넘겨주기
  }

  const adStatus = (item) => {
    let status
    if (adStatusTab === 'candidate') {
      status = <Badge text='광고 미신청' color='gray' />
    } else if (item?.status === 'costPause') {
      status = (
        <Badge
          text='일시중지(예산 도달)'
          color='red'
          isTooltip
          tooltipText='설정한 하루 광고 예산을 모두 소진해 광고가 일시 중지되었어요. 내일부터 다시 광고가 노출돼요.'
        />
      )
    } else if (item?.status === 'creditPause') {
      status = (
        <Badge
          text='일시중지(크레딧 부족)'
          color='red'
          isTooltip
          tooltipText='크레딧이 부족해 광고가 중지되었어요. 광고를 진행하려면 [광고 크레딧 관리] 메뉴에서 크레딧을 충전해주세요.'
        />
      )
    } else if (!item?.adOngoing) {
      status = (
        <Badge
          text='노출 대기'
          color='yellow'
          isTooltip
          tooltipText='광고 신청 평균 1시간 내에 노출이 시작되며, 상품 가격, 품질 등에 의해 노출되지 않을 수 있어요.'
        />
      )
    } else if (!item?.adVisits || item?.adVisits === 0) {
      status = (
        <Badge
          text='광고 노출중(미클릭)'
          color='yellow'
          isTooltip
          tooltipText='광고가 노출되었지만 클릭이 발생하지 않았어요. 상품 가격, 품질 등을 확인해주세요.'
        />
      )
    } else {
      status = <Badge text='광고 노출중' color='green' />
    }
    return status
  }

  const adData = useMemo(
    () =>
      itemsInfo
        ?.filter((item) => item?.itemTitle)
        .map((item, idx) => {
          return {
            onOff: (
              <div className='ml-2' key={item?._id}>
                <div className='relative inline-block w-10 mr-2 align-middle select-none'>
                  <label
                    htmlFor={`onOff_${idx}`}
                    className='block h-6 overflow-hidden bg-gray-300 rounded-full outline-none cursor-pointer checked:bg-blue-500'
                  >
                    <input
                      type='checkbox'
                      name='toggle'
                      id={`onOff_${idx}`}
                      checked={item?.adOngoing}
                      className='absolute block w-4 h-4 duration-200 ease-in bg-white border-0 rounded-full appearance-none cursor-pointer ring-transparent ring-offset-0 focus:ring-offset-0 focus:ring-transparent outline-0 top-1 checked:h-6 checked:w-6 checked:bg-blue-500 right-5 checked:top-0 checked:right-0'
                    />
                  </label>
                </div>
              </div>
            ),
            status: <div key={item?._id}>{adStatus(item)}</div>,
            itemInfo: (
              <div key={item?._id}>
                <div className='flex flex-row justify-between flex-1 max-w-4xl'>
                  <div className='flex flex-row flex-1 w-max'>
                    <img
                      alt='mainImage'
                      src={
                        item?.mainImageUris
                          ? resize(item?.mainImageUris[0])
                          : ''
                      }
                      className='w-20 h-20 mr-3 rounded-md border border-[#000000F]'
                    />
                    <div className='mr-3'>
                      <div className='mb-1 text-lg font-bold whitespace-normal'>
                        {item?.itemTitle}
                      </div>

                      <div className='flex flex-row items-center mb-1'>
                        {item?.rankInfo && (
                          <div className='flex items-center px-2 py-0.5 bg-gray-100 rounded-md mr-2'>
                            <RankIconSetter
                              itemRank={item?.rankInfo?.itemRank}
                              className='w-4 h-4'
                            />
                            등급
                          </div>
                        )}
                        <div>
                          {item?.teamPurchasePrice?.toLocaleString() || 0}원
                        </div>
                      </div>

                      <div className='text-gray-400 '>{item?._id}</div>
                    </div>
                  </div>

                  <div className='flex items-center'>
                    {item?.teamPurchasePrice <= 100 ||
                    (item?.rankInfo?.itemRank === 'C' &&
                      adStatusTab === 'candidate') ||
                    !item?.rankInfo ? (
                      <Button appearance='disabled' size='lg' disabled>
                        광고 신청 불가
                      </Button>
                    ) : (
                      <Button
                        appearance={
                          adStatusTab === 'candidate'
                            ? 'positive'
                            : 'positiveSub'
                        }
                        size='lg'
                        onClick={() => {
                          setSelectedItemInfo(item)
                          setShowAdApplyModal(true)
                        }}
                      >
                        {adStatusTab === 'candidate'
                          ? '광고 신청 →'
                          : '광고 수정'}
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            ),
            adCreatedAt: (
              <div key={item?._id} className='text-left'>
                {item?.adCreatedAt ? (
                  <>{new Date(item?.adCreatedAt).toLocaleDateString()}</>
                ) : (
                  '-'
                )}
              </div>
            ),
            keywords: (
              <div key={item?._id} className='flex flex-wrap'>
                {item?.keywords
                  ? item?.keywords?.map((keywords) => (
                      <Badge
                        text={keywords?.keyword}
                        className='inline-flex mb-1'
                        color='gray'
                      />
                    ))
                  : '-'}
              </div>
            ),
            dailyCostLimit: (
              <div key={item?._id} className='text-right'>
                {item?.dailyCostLimit?.toLocaleString() || '-'}원
              </div>
            ),
            visits: (
              <div key={item?._id} className='text-right'>
                {item?.adVisits?.toLocaleString() || '-'}
              </div>
            ),
            purchase: (
              <div key={item?._id} className='text-right'>
                {item?.purchase?.toLocaleString() || '-'}
              </div>
            ),
            adCost: (
              <div key={item?._id} className='text-right'>
                {item?.adCost?.toLocaleString() || '-'}원
              </div>
            ),
            roas: (
              <div key={item?._id} className='text-right'>
                {item?.roas?.toLocaleString() || '-'}%
              </div>
            ),
            revenueWithAd: (
              <div key={item?._id} className='text-right'>
                {item?.revenueWithAd?.toLocaleString() || '-'}원
              </div>
            ),
          }
        }),
    [itemsInfo],
  )

  const chart = {
    type: 'bar',
    data: {
      labels: chartData?.map((item) => item?.date),
      datasets: [
        {
          label: '광고 클릭수',
          data: chartData?.map((item) => item?.adVisits),
          backgroundColor: '#7695FF',
          borderWidth: 0,
        }, // 광고 판매수, 광고 전환매출 추가 예정
      ],
    },
    options: {
      indexAxis: 'x',
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
          align: 'end',
        },
      },
    },
  }

  const fetchChartData = async () => {
    const adChartResult = await backendApis.getAdChartData(14)

    if (adChartResult?.status === 200) {
      setLoadChartData(true)
      setChartData(adChartResult?.data)
    }
  }

  const fetchSegmentedItemsInfo = async (
    isNewCondition,
    reset = false,
    selectedSearchField,
    itemQuery,
  ) => {
    if (SellerStore.isLoading) return
    SellerStore.setIsLoading(true)

    const itemCondition = {
      soldOut: false,
      outOfStock: { $ne: true },
    }
    if (adStatusTab === 'candidate') {
      itemCondition.$or = [
        { itemFilters: { $eq: null } },
        {
          itemFilters: {
            $not: {
              $elemMatch: {
                $and: [{ key: 'ad', value: adSettingByTab?.[tab].type }],
              },
            },
          },
        },
      ]
    } else if (adStatusTab === 'complete') {
      itemCondition.itemFilters = {
        $ne: null,
        $elemMatch: {
          $and: [{ key: 'ad', value: adSettingByTab?.[tab].type }],
        },
      }
    }

    if (itemQuery && !reset) {
      if (selectedSearchField === 'itemTitle') {
        itemCondition.itemTitle = { $regex: escapeRegExp(itemQuery) }
      } else if (selectedSearchField === 'itemId') {
        itemCondition._id = {
          $in: [itemQuery],
        }
      }
    }

    if (isNewCondition) {
      const itemsCountResult = await backendApis.getSellerItemsCountByCondition(
        {
          type: 'item',
          itemCondition,
        },
      )

      if (itemsCountResult?.status === 200) {
        setItemsCount(itemsCountResult?.data)
      } else {
        setItemsCount(0)
        setPage(1)
        setItemsInfo([])
        window.alert('상품 목록을 불러오는 중 문제가 발생했습니다.')
        SellerStore.setIsLoading(false)
        return
      }
    }

    const result = await backendApis.getSellerSegementedItemsInfoByCondition(
      {
        type: 'item',
        itemCondition,
      },
      page,
      limit,
    )

    if (result?.status === 2000) {
      setItemsInfo(result?.data?.itemsInfo)

      if (adStatusTab === 'complete') {
        const adResult = await backendApis.getAdItemCreditUsage(
          'searchAd',
          true,
        )
        if (adResult?.status === 200) {
          const allResult = new Map()

          result?.data?.itemsInfo.forEach((item) =>
            allResult.set(String(item._id), item),
          )
          adResult?.data
            .filter((item) => !item?.deletedAt)
            .forEach((item) =>
              allResult.set(String(item.itemId), {
                ...allResult.get(String(item.itemId)),
                adVisits: item?.adVisits,
                adCost: item?.adCost,
                roas:
                  item?.adCost > 0
                    ? Math.round(
                        (1000 * Number(item?.revenueWithAd)) /
                          (Number(item?.adCost) / 1.1),
                      ) / 10
                    : '-',
                purchase: item?.purchase,
                keywords: item?.detailInfo?.keywords,
                adOngoing: item?.adOngoing,
                adCreatedAt: item?.createdAt,
                dailyCostLimit: item?.detailInfo?.dailyCostLimit,
                revenueWithAd: item?.revenueWithAd,
                status: item?.status,
              }),
            )
          setItemsInfo(Array.from(allResult.values()))
        }
      }
    } else if (result?.status === 2001) {
      const maxPage = Math.ceil(result.data?.itemsCount / limit) || 1
      const retryResult =
        await backendApis.getSellerSegementedItemsInfoByCondition(
          {
            type: 'item',
            itemCondition,
          },
          maxPage,
          limit,
        )
      if (retryResult?.status === 2000) {
        setPage(maxPage)
        setItemsInfo(retryResult?.data?.itemsInfo)
      } else {
        setPage(1)
        setItemsInfo([])
        if (retryResult?.status !== 2001) {
          window.alert('상품 목록을 불러오는 중 문제가 발생했습니다.')
        }
      }
    }

    SellerStore.setIsLoading(false)
  }

  useEffect(() => {
    fetchSegmentedItemsInfo(true)
    fetchChartData()
  }, [limit, adStatusTab])

  useEffect(() => {
    if (itemsInfo?.length) {
      fetchSegmentedItemsInfo(false)
    }
  }, [page])

  useEffect(() => {
    if (itemsInfo?.length > 0) {
      setShowAdBatchApplyModal(batchApplyOn ?? false)
    }
  }, [batchApplyOn, itemsInfo?.length])

  return (
    <div>
      <div className='flex flex-wrap justify-between'>
        <div className='shadow-lg rounded-2xl p-8 bg-white min-w-[405px] flex-auto mr-4 mb-4'>
          <div className='flex flex-row items-center mb-5 text-xl font-bold'>
            <RankIconSetter
              itemRank='B'
              className='inline-block w-8 h-8 mr-3'
            />{' '}
            등급 이상 상품만 신청 가능합니다.
          </div>
          <div>
            판매가 이뤄질때만 광고비가 차감되며, 주문 취소되면 광고비를
            환불해드립니다. <br />
            ROAS(광고비용 대비 수익) 보장형 광고로 ROAS 1,000%*를 보장받으실 수
            있어요.(*S등급 상품 기준)
          </div>
          <div className='flex flex-col flex-1 p-5 mt-4 bg-gray-100 rounded-xl'>
            <span className='flex flex-row items-center flex-1 mb-1 font-bold'>
              <FcIdea className='mr-2' />
              판매 보장 광고란?
            </span>
            <ul className='mx-4 list-disc'>
              <li>
                판매 보장 광고는 제품을 검색, 추천화면 등에 더 많이 노출하는
                광고입니다.
              </li>
              <li>
                CPS 과금방식으로 노출수와 상관없이{' '}
                <span className='font-bold text-blue-500'>
                  고객이 광고 상품을 실제 구매했을 때만
                </span>{' '}
                광고비가 차감됩니다.
              </li>
              <li>
                고객이 상품을 구매했을 때 광고의 기여도가 낮은 경우,{' '}
                <span className='font-bold text-blue-500'>광고비가 무료</span>로
                계산됩니다.
                <br />
                타임특가 등의 딜 매대에서 팀구매가보다 할인된 가격으로 판매된
                경우에는 최소 ROAS 500%가 보장됩니다.
                <br />
              </li>
            </ul>
            <span className='flex flex-row items-center flex-1 mt-6 mb-1 font-bold'>
              <FcHighPriority className='mr-2' />
              주의사항
            </span>
            <ul className='mx-4 list-disc'>
              <li>
                <span className='font-bold text-blue-500'>
                  해외 사업자 쇼핑몰 상품, 미성년자 구매 불가 상품
                </span>
                은 광고 신청이 불가능합니다.
              </li>
              <li>
                AI가 연관성을 판단하여{' '}
                <span className='font-bold text-blue-500'>
                  상품과 관련 없는 키워드
                </span>
                에는 광고를 노출하지 않습니다.
              </li>
              <li>
                <span className='font-bold text-blue-500'>
                  오픈 마켓 최저가보다 비싼 상품
                </span>
                은 광고를 노출히지 않습니다. (*최저가 정보가 없는 경우 제외)
              </li>
            </ul>
          </div>
          <div className='flex justify-start mt-6'>
            <button
              type='button'
              className='inline-block px-4 py-2 mr-5 border rounded-lg w-fit hover:bg-gray-100'
              onClick={() =>
                window.open(
                  'https://drive.google.com/file/d/1LAp8lDK4tD96rS8xpacthYnFFAH_eQUc/view',
                  '_blank',
                )
              }
            >
              광고 소개서 ↗
            </button>
            <button
              type='button'
              className='inline-block px-4 py-2 border rounded-lg w-fit hover:bg-gray-100'
              onClick={() =>
                window.open(
                  'https://levitinc.notion.site/ee08e47ee6934a59b8f97176afd4bfa2',
                  '_blank',
                )
              }
            >
              광고 가이드 ↗
            </button>
          </div>
        </div>
        {chartData?.length > 0 ? (
          <>
            {chartData?.some((item) => item?.adVisits > 0) && (
              <div className='flex-auto p-8 mb-4 mr-4 bg-white shadow-lg rounded-2xl'>
                <div className='flex flex-row items-center mb-5 text-xl font-bold'>
                  광고 성과 그래프
                </div>
                <Bar
                  data={chart.data}
                  width={50}
                  height={15}
                  options={chart.options}
                />
              </div>
            )}
          </>
        ) : (
          <div className='flex-auto p-8 mb-4 mr-4 bg-white shadow-lg rounded-2xl'>
            <div className='flex flex-row items-center mb-5 text-xl font-bold'>
              광고 성과 그래프
            </div>
            {loadChartData ? (
              <div className='flex justify-center items-center mb-5 text-xl'>
                최근 2주간 광고 성과 데이터가 없습니다.
              </div>
            ) : (
              <div className='flex justify-center items-center mb-5 text-xl'>
                데이터를 불러오는 중입니다...
              </div>
            )}
          </div>
        )}
      </div>
      <div className='flex flex-row items-center justify-between flex-1 mt-10 mb-5 '>
        <ApplyTabHandler
          applyStatusTab={adStatusTab}
          setApplyStatusTab={setAdStatusTab}
          setPage={setPage}
        />
        <div className='flex flex-row'>
          {itemQuery && (
            <Button
              className='w-24 mr-3 text-gray-600 rounded-md hover:bg-gray-200'
              onClick={() => {
                setItemQuery('')
                fetchSegmentedItemsInfo(true, true)
              }}
              appearance='transparent'
              size='md'
            >
              초기화
            </Button>
          )}
          <Button
            onClick={() => setShowAdBatchApplyModal(true)}
            appearance='positiveSub'
            size='md'
            className='px-4 mx-3 w-35'
          >
            광고 대량등록
          </Button>
          <ItemSearchComponent onSearch={handleSearch} />
          <ListLimitSelector limit={limit} setLimit={setLimit} />
        </div>
      </div>
      <Table columns={columns} data={adData} />
      <Pagination
        total={itemsCount}
        limit={limit}
        page={page}
        setPage={setPage}
        size='lg'
      />
      <SearchAdApplyModal
        itemInfo={selectedItemInfo}
        tab={tab}
        status={adStatusTab}
        showModal={showAdApplyModal && adSettingByTab[tab].type === 'searchAd'}
        setShowModal={setShowAdApplyModal}
        callbackFunction={() => fetchSegmentedItemsInfo(true)}
      />
      <AdBatchApplyModal
        showModal={showAdBatchApplyModal}
        setShowModal={setShowAdBatchApplyModal}
        callbackFunction={() => fetchSegmentedItemsInfo(true)}
      />
    </div>
  )
})
export default AdScreen
