const StepIndicator = ({ currentStep }) => {
  const steps = [
    { number: 1, title: '시작하기' },
    { number: 2, title: '사업자등록증 업로드' },
    { number: 3, title: '사업자등록번호 입력' },
    { number: 4, title: '정산계좌 등록' },
  ]

  const getStepClassName = (current, stepNum) => {
    if (current === stepNum) return 'bg-blue-500 text-white'
    if (current > stepNum) return 'bg-blue-500 text-white'
    return 'bg-gray-200 text-gray-600'
  }

  return (
    <div className='w-4/5 mb-8'>
      <div className='flex justify-between items-center relative'>
        <div className='absolute top-5 left-0 w-full h-1 bg-gray-200'>
          <div
            className='h-full bg-blue-500 transition-all duration-300'
            style={{
              width: `${((currentStep - 1) / (steps.length - 1)) * 100}%`,
            }}
          />
        </div>

        <div className='flex justify-between w-full relative'>
          {steps.map((step) => (
            <div key={step.number} className='flex flex-col items-center'>
              <div
                className={`w-10 h-10 rounded-full flex items-center justify-center mb-2
                    ${getStepClassName(currentStep, step.number)}`}
              >
                {currentStep > step.number ? (
                  <svg
                    className='w-6 h-6'
                    fill='none'
                    stroke='currentColor'
                    viewBox='0 0 24 24'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      strokeWidth={1}
                      d='M5 13l4 4L19 7'
                    />
                  </svg>
                ) : (
                  step.number
                )}
              </div>
              <span
                className={`text-sm font-medium
                    ${
                      currentStep === step.number
                        ? 'text-blue-500'
                        : 'text-gray-500'
                    }`}
              >
                {step.title}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default StepIndicator
