import { message, Pagination, List, Steps, Modal, Button } from 'antd'
import React, { useReducer, useState, useEffect, useRef, useMemo } from 'react'
import 'moment/locale/ko'
import { animated, useSpring, useTransition } from 'react-spring'
import { CloseOutlined } from '@ant-design/icons'
import backendApis from '../../../utils/backendApis'
import Stage0Introduction from './stages/Stage0Introduction'
import Stage1DateSelection from './stages/Stage1DateSelection'
import Stage2ProductSelection from './stages/Stage2ProductSelection'
import Stage3BidSettings from './stages/Stage3BidSettings'
import ConfirmCpmAdModal from './components/ConfirmCpmAdModal'
import moment from 'moment'

moment.locale('ko')

const initialState = {
  stage: 0,
  dateRange: [],
  gender: 'all',
  minAge: 0,
  maxAge: 100,
  customAge: 'all',
  selectedItemIds: [], // 추가된 부분
  selectedItems: [], // { _id, title, teamPurchasePrice } 객체들의 배열
  adQuantity: 3000000,
  bidPrice: 0.1,
}

function reducer(state, action) {
  switch (action.type) {
    case 'SET_STAGE':
      return { ...state, stage: action.payload }
    case 'SET_DATE_RANGE':
      return { ...state, dateRange: action.payload }
    case 'SET_GENDER':
      return { ...state, gender: action.payload }
    case 'SET_CUSTOM_AGE':
      return { ...state, customAge: action.payload }
    case 'SET_MIN_AGE':
      return { ...state, minAge: action.payload }
    case 'SET_MAX_AGE':
      return { ...state, maxAge: action.payload }
    case 'ADD_SELECTED_ITEM':
      return {
        ...state,
        selectedItems: [...state.selectedItems, action.payload],
        selectedItemIds: [...state.selectedItemIds, action.payload._id],
      }
    case 'REMOVE_SELECTED_ITEM':
      // 단일 상품 제거 (기존 로직)
      return {
        ...state,
        selectedItems: state.selectedItems.filter(
          (item) => item._id !== action.payload,
        ),
        selectedItemIds: state.selectedItemIds.filter(
          (id) => id !== action.payload,
        ),
      }
    case 'SET_AD_QUANTITY':
      return { ...state, adQuantity: action.payload }
    case 'SET_BID_PRICE':
      return { ...state, bidPrice: action.payload }
    default:
      return state
  }
}

const CpmAdApplicationSection = ({
  setModalStatus = () => {},
  setTabStatus = () => {},
  refreshTrigger,
}) => {
  const [candidateItems, setCandidateItems] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [page, setPage] = useState(1)
  const [state, dispatch] = useReducer(reducer, initialState)
  const {
    stage,
    dateRange,
    selectedItemIds,
    selectedItems,
    adQuantity,
    bidPrice,
  } = state
  const [selectedItemCurrentPage, setSelectedItemCurrentPage] = useState(1)
  const [creditInfo, setCreditInfo] = useState({})
  const sectionRef = useRef(null)
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false)
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false)
  const [isRegistering, setIsRegistering] = useState(false)

  const adPaymentAmount = bidPrice * adQuantity
  const remainingCredit = useMemo(() => {
    return (
      (creditInfo?.adCreditInfo?.paidCredit || 0) +
      (creditInfo?.adCreditInfo?.supportCredit || 0)
    )
  }, [creditInfo])
  const remainingCreditAfterAd = useMemo(() => {
    return remainingCredit - adPaymentAmount
  }, [remainingCredit, adPaymentAmount])

  const pageSize = 5

  const startDate = dateRange?.[0] && moment(dateRange[0]).format('YYYY-MM-DD')
  const endDate = dateRange?.[1] && moment(dateRange[1]).format('YYYY-MM-DD')

  const handleItemSelect = (item) => {
    if (selectedItemIds.includes(item._id)) {
      dispatch({ type: 'REMOVE_SELECTED_ITEM', payload: item._id })
    } else if (selectedItemIds.length < 50) {
      dispatch({
        type: 'ADD_SELECTED_ITEM',
        payload: {
          _id: item._id,
          title: item.itemTitle,
          teamPurchasePrice: item.teamPurchasePrice,
          mainImageUri: item.mainImageUris[0],
        },
      })
      // 새 아이템이 추가되어 새 페이지가 생성되었는지 확인
      const newMaxPage = Math.ceil((selectedItems.length + 1) / pageSize)
      if (newMaxPage > Math.ceil(selectedItems.length / pageSize)) {
        setSelectedItemCurrentPage(newMaxPage)
      }
    } else {
      message.warning('최대 50개의 상품만 선택할 수 있습니다.')
    }
  }

  const handleItemRemove = (itemId) => {
    dispatch({ type: 'REMOVE_SELECTED_ITEM', payload: itemId })
  }

  const transitions = useTransition(stage, {
    from: { opacity: 0, transform: 'translateX(100%)' },
    enter: { opacity: 1, transform: 'translateX(0%)' },
    leave: { opacity: 0, transform: 'translateX(-100%)' },
  })

  useEffect(() => {
    const maxPage = Math.ceil(selectedItems.length / pageSize)
    if (selectedItemCurrentPage > maxPage && maxPage > 0) {
      setSelectedItemCurrentPage(maxPage)
    } else if (
      selectedItems.length > 0 &&
      selectedItems.length % pageSize === 0
    ) {
      // 새 페이지가 생성되었을 때 마지막 페이지로 이동
      setSelectedItemCurrentPage(maxPage)
    }
  }, [selectedItems, selectedItemCurrentPage])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const creditResult = await backendApis.getAdCreditInfo()
        if (creditResult?.status === 2000) {
          setCreditInfo(creditResult?.data)
        }
      } catch (error) {
        console.error('데이터 fetch 중 오류 발생:', error)
        message.error('크레딧 정보를 불러오는데 실패했습니다.')
      }
    }

    fetchData()
  }, [refreshTrigger])

  const truncateTitle = (title, maxLength = 24) => {
    if (title.length <= maxLength) return title
    return `${title.slice(0, maxLength)}...`
  }

  const renderContent = (stage) => {
    const props = {
      dispatch,
      ...state,
      startDate,
      endDate,
      candidateItems,
      setCandidateItems,
      totalCount,
      setTotalCount,
      page,
      setPage,
      handleItemSelect,
      renderSelectedItems,
      setIsConfirmModalVisible,
      adPaymentAmount,
      remainingCredit,
      remainingCreditAfterAd,
      isRegistering,
    }

    switch (stage) {
      case 0:
        return <Stage0Introduction {...props} setModalStatus={setModalStatus} />
      case 1:
        return <Stage1DateSelection {...props} />
      case 2:
        return <Stage2ProductSelection {...props} />
      case 3:
        return <Stage3BidSettings {...props} />
      default:
        return null
    }
  }

  const renderSelectedItems = () => {
    const startIndex = (selectedItemCurrentPage - 1) * pageSize
    const endIndex = startIndex + pageSize
    const paginatedItems = selectedItems.slice(startIndex, endIndex)

    return (
      <div
        style={{
          position: 'sticky',
          top: '92px',
          width: '280px',
          marginLeft: '20px',
          alignSelf: 'flex-start',
        }}
      >
        <div className='bg-white p-4 rounded-lg shadow-lg max-h-[calc(100vh-40px)] overflow-auto'>
          <h3 className='text-lg font-bold mb-4'>
            선택된 상품 ({selectedItems?.length})
          </h3>
          <div>
            {selectedItems.length < 1 && (
              <div className='text-red-500 mb-8'>
                *최소 1개의 상품을 선택해주세요.
              </div>
            )}
          </div>
          <List
            size='small'
            dataSource={paginatedItems}
            renderItem={(item) => (
              <List.Item
                key={item._id}
                extra={
                  <CloseOutlined
                    onClick={() => handleItemSelect(item)}
                    style={{ cursor: 'pointer' }}
                  />
                }
              >
                <List.Item.Meta
                  avatar={
                    <img
                      src={item.mainImageUri || '기본 이미지 URL'}
                      alt={item.title}
                      style={{ width: 40, height: 40, objectFit: 'cover' }}
                    />
                  }
                  title={truncateTitle(item.title)}
                  description={`${item.teamPurchasePrice.toLocaleString()}원`}
                />
              </List.Item>
            )}
          />
          <Pagination
            current={selectedItemCurrentPage}
            total={selectedItems.length}
            pageSize={pageSize}
            onChange={(page) => setSelectedItemCurrentPage(page)}
            size='small'
            style={{ marginTop: '10px', textAlign: 'center' }}
            showSizeChanger={false}
          />
        </div>
      </div>
    )
  }

  const progressBarAnimation = useSpring({
    opacity: stage > 0 ? 1 : 0,
    height: stage > 0 ? 60 : 0,
    marginBottom: stage > 0 ? 8 : 0,
    from: { opacity: 0, height: 0, marginBottom: 0 },
    config: { tension: 300, friction: 20 },
  })

  const renderProgressBar = () => {
    const steps = [
      { title: '광고 소개', description: '광고 유형 설명' },
      { title: '기간 선택', description: '광고 집행 기간' },
      { title: '상품 선택', description: '광고할 상품 선택' },
      { title: '신청 완료', description: '광고 신청 확인' },
    ]

    return (
      <animated.div style={progressBarAnimation}>
        <Steps current={stage} items={steps} />
      </animated.div>
    )
  }

  useEffect(() => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }, [stage])

  const handleAdRegistration = async () => {
    if (isRegistering) return
    setIsRegistering(true)

    const targeting = {
      ageRange: [state.minAge, state.maxAge],
      gender: state.gender,
    }

    try {
      const apiEndDate = moment(endDate).add(1, 'days').format('YYYY-MM-DD')

      const result = await backendApis.postCpmAdSet({
        costPerImpression: state.bidPrice,
        maxImpressions: state.adQuantity,
        startDate,
        endDate: apiEndDate,
        targeting,
        itemIds: state.selectedItemIds,
      })

      if (result?.status === 200) {
        const creditResult = await backendApis.getAdCreditInfo()
        if (creditResult?.status === 2000) {
          setCreditInfo(creditResult?.data)
        }

        setIsConfirmModalVisible(false)
        setIsSuccessModalVisible(true)
        dispatch({ type: 'SET_STAGE', payload: 0 })
        setSelectedItemCurrentPage(1)
      } else {
        message.error('광고 등록에 실패했습니다. 다시 시도해주세요.')
      }
    } catch (error) {
      console.error('광고 등록 중 오류 발생:', error)
      message.error('광고 등록 중 오류가 발생했습니다. 다시 시도해주세요.')
    } finally {
      setIsRegistering(false)
    }
  }

  // 크레딧 정보 새로고침 함수 추가
  const refreshCreditInfo = async () => {
    const creditResult = await backendApis.getAdCreditInfo()
    if (creditResult?.status === 2000) {
      setCreditInfo(creditResult?.data)
    }
  }

  return (
    <div className='mb-20 text-slate-700' ref={sectionRef}>
      {renderProgressBar()}
      {transitions((style, item) => (
        <animated.div style={style} className='absolute w-full max-w-7xl'>
          {renderContent(item)}
        </animated.div>
      ))}
      <ConfirmCpmAdModal
        visible={isConfirmModalVisible}
        onCancel={() => setIsConfirmModalVisible(false)}
        onConfirm={handleAdRegistration}
        adDetails={{
          dateRange: [startDate, endDate],
          gender: state.gender,
          ageRange: [state.minAge, state.maxAge],
          selectedItems: state.selectedItems,
          adQuantity: state.adQuantity,
          bidPrice: state.bidPrice,
          adPaymentAmount,
        }}
        onItemRemove={handleItemRemove}
        isRegistering={isRegistering}
      />
      <Modal
        visible={isSuccessModalVisible}
        footer={[
          <Button
            key='complete'
            type='primary'
            onClick={async () => {
              try {
                await refreshCreditInfo()
                setIsSuccessModalVisible(false)
                setModalStatus(false)
                setTabStatus('2')
              } catch (error) {
                console.error('데이터 fetch 중 오류 발생:', error)
                message.error('데이터 로딩 중 오류가 발생했습니다.')
              }
            }}
          >
            신청 완료 목록 보기
          </Button>,
        ]}
        closable
        centered
        width={800}
        onCancel={async () => {
          try {
            await refreshCreditInfo()
            setIsSuccessModalVisible(false)
            setModalStatus(false)
          } catch (error) {
            console.error('데이터 fetch 중 오류 발생:', error)
            message.error('데이터 로딩 중 오류가 발생했습니다.')
          }
        }}
      >
        <div className='py-8'>
          <div className='text-center mb-8'>
            <div className='text-6xl mb-4'>🎉</div>
            <h2 className='text-2xl font-bold mb-4'>광고 등록 완료!</h2>
          </div>

          <div className='bg-gray-50 p-6 rounded-lg space-y-6'>
            <div className='grid grid-cols-2 gap-6'>
              <div>
                <h3 className='font-bold mb-3'>광고 기간</h3>
                <p>
                  {startDate} ~ {endDate}
                </p>
              </div>
              <div>
                <h3 className='font-bold mb-3'>타겟팅 정보</h3>
                <p>
                  성별:{' '}
                  {{
                    all: '전체',
                    male: '남성',
                    female: '여성',
                  }[state.gender] || '전체'}
                </p>
                <p>
                  연령: {state.minAge}세 ~ {state.maxAge}세
                </p>
              </div>
            </div>

            <div>
              <h3 className='font-bold mb-3'>광고 설정</h3>
              <p>노출 수: {state.adQuantity.toLocaleString()}회</p>
              <p>노출당 비용: {state.bidPrice}원</p>
              <p>
                총 광고비:{' '}
                {(state.adQuantity * state.bidPrice).toLocaleString()}원
              </p>
            </div>

            <div>
              <h3 className='font-bold mb-3'>
                등록된 상품 ({state.selectedItems.length}개)
              </h3>
              <div className='max-h-60 overflow-y-auto'>
                <List
                  dataSource={state.selectedItems}
                  renderItem={(item) => (
                    <List.Item>
                      <List.Item.Meta
                        avatar={
                          <img
                            src={item.mainImageUri}
                            alt={item.title}
                            style={{
                              width: 40,
                              height: 40,
                              objectFit: 'cover',
                            }}
                          />
                        }
                        title={item.title}
                        description={`${item.teamPurchasePrice.toLocaleString()}원`}
                      />
                    </List.Item>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default CpmAdApplicationSection
