import React from 'react'
import { Button, Select, InputNumber } from 'antd'
import commaNumber from 'comma-number'

const Stage3BidSettings = ({
  dispatch,
  adQuantity,
  bidPrice,
  adPaymentAmount,
  remainingCredit,
  remainingCreditAfterAd,
  setIsConfirmModalVisible,
  isRegistering,
}) => {
  return (
    <div className='mb-20 text-slate-700'>
      <div className='flex flex-col items-start flex-1 p-12 mb-5 bg-white shadow-lg rounded-xl'>
        <div className='flex flex-1 flex-col w-full'>
          <div className='mb-12 font-bold text-lg'>
            <p>CPM 광고는 경매 방식으로 진행돼요</p>
            <p>입찰가와 상품 매력도가 함께 반영되어 노출 우선순위가 결정돼요</p>
          </div>
          <div className='flex flex-1 flex-row w-full'>
            <div className='grid grid-cols-2 gap-8 w-full'>
              <div className='flex flex-1 w-full flex-col'>
                <div className='flex flex-col items-start mb-8'>
                  <div className='font-bold text-lg text-blue-500 mb-4 flex flex-1 items-center flex-row'>
                    <span className='mr-2 w-8 h-8 rounded-full  bg-blue-100 flex items-center justify-center'>
                      1
                    </span>
                    총 광고 수량을 선택해주세요
                  </div>
                  <div className='ml-10'>
                    <div>
                      <Select
                        className='w-[200px]'
                        size='large'
                        value={adQuantity}
                        onChange={(value) =>
                          dispatch({
                            type: 'SET_AD_QUANTITY',
                            payload: value,
                          })
                        }
                        disabled={isRegistering}
                      >
                        {[
                          3000000, 4000000, 5000000, 6000000, 7000000, 8000000,
                        ].map((value) => (
                          <Option key={value} value={value}>
                            {value.toLocaleString()} 회
                          </Option>
                        ))}
                      </Select>
                    </div>
                    <div className='text-sm font-bold text-slate-600 mt-2'>
                      *광고 수량은 신청한 광고 제품들의 총 노출 횟수를 의미해요
                    </div>
                    <div className='text-sm text-slate-600 mt-2'>
                      *상품 1개가 1번 노출될 때마다 1회씩 차감돼요
                    </div>
                    <div className='text-sm text-slate-600 mt-2'>
                      *고객 별로 상품이 개별 맞춤되기 때문에 상품마다 노출 횟수
                      차이가 발생할 수 있어요
                    </div>
                    <div className='text-sm text-slate-600 mt-2'>
                      *최소 신청 수량은 300만 개 입니다.
                    </div>
                  </div>
                </div>
                <div className='font-bold text-lg text-blue-500 mb-4 flex flex-1 items-center flex-row'>
                  <span className='mr-2 w-8 h-8 rounded-full  bg-blue-100 flex items-center justify-center'>
                    2
                  </span>
                  입찰가를 선택해주세요
                </div>
                <div className='ml-10'>
                  <div>
                    <InputNumber
                      className='w-[200px]'
                      size='large'
                      value={bidPrice}
                      onChange={(value) =>
                        dispatch({ type: 'SET_BID_PRICE', payload: value })
                      }
                      disabled={isRegistering}
                      min={0.1}
                      max={0.3}
                      step={0.01}
                      precision={2}
                      addonAfter='원'
                    />
                  </div>
                  <div className='text-sm mt-2'>
                    <p className='text-red-600 font-bold'>
                      *최소 입찰가는 0.10원이며, 최대 입찰가는 0.30원입니다.
                    </p>
                    <p className='text-sm text-slate-600'>
                      *0.01원 단위로 입찰가를 조정할 수 있습니다.
                    </p>
                  </div>
                </div>
              </div>
              <div className='flex flex-1 w-full flex-col  justify-end'>
                <div className='border grid grid-cols-2 w-full gap-12 rounded-xl p-8 h-48 border-gray-300'>
                  <div className='flex flex-col justify-center items-end'>
                    <div className='text-lg text-gray-600'>
                      예상 광고 결제 금액
                    </div>
                    <div className='text-lg text-gray-600'>내 잔여 크레딧</div>
                    <div className='text-lg text-gray-600'>
                      신청 후 남은 크레딧
                    </div>
                  </div>
                  <div className='flex flex-col items-end justify-center font-bold text-lg'>
                    <div className='text-blue-500'>
                      {commaNumber(Math.floor(adPaymentAmount))}원
                    </div>
                    <div>{commaNumber(Math.floor(remainingCredit))}원</div>
                    <div
                      className={
                        remainingCreditAfterAd < 0 ? 'text-red-500' : ''
                      }
                    >
                      {commaNumber(Math.floor(remainingCreditAfterAd))}원
                    </div>
                  </div>
                </div>
                <div className='text-sm text-slate-600 mt-2'>
                  *무상 크레딧이 있는 경우 무상 크레딧이 먼저 사용됩니다
                </div>
                {remainingCreditAfterAd < 0 && (
                  <div className='mt-4 text-red-500'>
                    잔여 크레딧이 부족합니다. 크레딧을 충전하신 후 다시
                    시도해주세요.
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-col items-start flex-1 p-12 mb-5 bg-white shadow-lg rounded-xl'>
        <div className='font-bold text-lg'>유의사항</div>
        <div className='w-[50%]'>
          <p>광고는 신청 후 검수를 거쳐 통과돼요.</p>
          <p>
            광고 수량이 모두 노출되지 않을 경우 남은 크레딧은 환불돼요. 아래와
            같은 상품은 검수 과정에서 반려되거나, 추후 광고 진행이 제재될 수
            있어요.
          </p>
          <p className='mt-2'>* 과장 / 과대 광고, 성인용품 등</p>
        </div>
      </div>
      <div className='mt-4 gap-5 flex justify-end w-full'>
        <Button
          className='bg-white w-[150px]'
          size='large'
          onClick={() => dispatch({ type: 'SET_STAGE', payload: 2 })}
          disabled={isRegistering}
        >
          이전
        </Button>
        <Button
          type='primary'
          size='large'
          className='w-[150px]'
          onClick={() => setIsConfirmModalVisible(true)}
          disabled={remainingCreditAfterAd < 0 || isRegistering}
          loading={isRegistering}
        >
          {isRegistering ? '등록 중...' : '광고 등록'}
        </Button>
      </div>
    </div>
  )
}

export default Stage3BidSettings
