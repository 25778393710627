import getCommissionRate from './getCommissionRate'
import getCouponSettlementAmount from './getCouponSettlementAmount'

const getSettlementAmount = (order) => {
  const couponSettlementAmount = getCouponSettlementAmount(order)

  const originalPayedAmount =
    typeof order.totalReturnFee === 'number'
      ? order.totalReturnFee
      : order.totalPrice +
        order.shippingFee -
        (order.refundAmount || 0) +
        (couponSettlementAmount || 0) +
        (order.selectedOption?.platformSupportPrice || 0) * order.quantity

  const commissionRate = getCommissionRate(order)

  const settlementAmount =
    originalPayedAmount -
    Math.floor(Math.floor(commissionRate * originalPayedAmount) * 1.1) -
    (order.settlementLogs
      ?.map((settlementLog) => settlementLog.settlementAmount)
      .reduce((a, b) => a + b, 0) || 0)

  return settlementAmount
}

export default getSettlementAmount
