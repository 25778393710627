import React, { useState, useEffect } from 'react'
import {
  Card,
  Row,
  Col,
  DatePicker,
  Button,
  Table,
  Modal,
  Tag,
  message,
} from 'antd'
import moment from 'moment'
import backendApis from '../../../utils/backendApis'
import RadioGroup from '../../../components/ADS/molecules/radioGroup'
import { CopyOutlined, ReloadOutlined } from '@ant-design/icons'

const { RangePicker } = DatePicker

const CpmAdAnalyticsSection = ({ refreshTrigger }) => {
  const [dateRange, setDateRange] = useState([null, null])
  const [dateOption, setDateOption] = useState('all')
  const [adSets, setAdSets] = useState([])
  const [selectedAdSet, setSelectedAdSet] = useState(null)
  const [analyticsData, setAnalyticsData] = useState([])
  const [loading, setLoading] = useState(true)
  const [isDetailModalVisible, setIsDetailModalVisible] = useState(false)
  const [totalStats, setTotalStats] = useState({
    totalImpressions: 0,
    totalSpentBudget: 0,
    totalRevenue: 0,
  })
  const [detailLoading, setDetailLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(20)
  const [total, setTotal] = useState(0)
  const [allAdSets, setAllAdSets] = useState([])
  const [displayedAdSets, setDisplayedAdSets] = useState([])
  const REFRESH_COOLDOWN = 30 // 30초
  const REFRESH_TIMESTAMP_KEY = 'cpmAdAnalytics_lastRefreshTime'

  const [isRefreshDisabled, setIsRefreshDisabled] = useState(() => {
    const lastRefreshTime = localStorage.getItem(REFRESH_TIMESTAMP_KEY)
    if (!lastRefreshTime) return false

    const timeSinceLastRefresh =
      (Date.now() - parseInt(lastRefreshTime, 10)) / 1000
    return timeSinceLastRefresh < REFRESH_COOLDOWN
  })

  const [remainingTime, setRemainingTime] = useState(0)

  const dateOptions = [
    { label: '전체 기간', value: 'all' },
    { label: '날짜 선택', value: 'custom' },
  ]

  const calculateTotalStats = (data) => {
    const stats = data.reduce(
      (acc, curr) => ({
        totalImpressions:
          (acc.totalImpressions || 0) + (curr.analytics?.impressions || 0),
        totalSpentBudget:
          (acc.totalSpentBudget || 0) + (curr.analytics?.spentBudget || 0),
        totalRevenue: (acc.totalRevenue || 0) + (curr.analytics?.revenue || 0),
      }),
      {
        totalImpressions: 0,
        totalSpentBudget: 0,
        totalRevenue: 0,
      },
    )

    setTotalStats(stats)
  }

  const fetchAdSets = async () => {
    setLoading(true)
    try {
      const [startDate, endDate] = dateRange
      const res = await backendApis.getCpmAdSetList({
        size: total || pageSize,
        page: 1,
        orderBy: 'createdAt',
        startDate:
          dateOption === 'custom' && startDate
            ? startDate.format('YYYY-MM-DD')
            : undefined,
        endDate:
          dateOption === 'custom' && endDate
            ? endDate.format('YYYY-MM-DD')
            : undefined,
      })

      if (res?.status === 200) {
        const filteredAdSets = res.data.filter((adSet) =>
          ['active', 'completed', 'cancelledAfterActive'].includes(
            adSet.status,
          ),
        )

        const adSetsWithAnalytics = await Promise.all(
          filteredAdSets.map(async (adSet) => {
            try {
              const analyticsRes = await backendApis.getCpmAdSetAnalytics({
                adSetId: adSet._id,
              })
              return {
                ...adSet,
                analytics: {
                  impressions:
                    analyticsRes?.data?.adSetAnalytics?.impressions || 0,
                  spentBudget:
                    analyticsRes?.data?.adSetAnalytics?.spentBudget || 0,
                  revenue: analyticsRes?.data?.adSetAnalytics?.revenue || 0,
                  error:
                    analyticsRes?.status !== 200 ? '데이터 로드 실패' : null,
                },
              }
            } catch (error) {
              console.error(
                `Error fetching analytics for ad set ${adSet._id}:`,
                error,
              )
              return {
                ...adSet,
                analytics: {
                  impressions: 0,
                  spentBudget: 0,
                  revenue: 0,
                  error: error.message || '데이터 로드 실패',
                },
              }
            }
          }),
        )

        setAllAdSets(adSetsWithAnalytics)
        updateDisplayedAdSets(1, pageSize, adSetsWithAnalytics)
        setTotal(adSetsWithAnalytics.length)
        calculateTotalStats(adSetsWithAnalytics)
      }
    } catch (error) {
      console.error('Error fetching ad sets:', error)
    } finally {
      setLoading(false)
    }
  }

  const updateDisplayedAdSets = (page, pageSize, data = allAdSets) => {
    const start = (page - 1) * pageSize
    const end = start + pageSize
    setDisplayedAdSets(data.slice(start, end))
    setCurrentPage(page)
  }

  const fetchAnalyticsData = async (adSetId) => {
    setDetailLoading(true)
    try {
      const res = await backendApis.getCpmAdSetAnalytics({
        adSetId,
      })
      if (res?.status === 200) {
        setAnalyticsData(res.data)
      }
    } catch (error) {
      console.error('Error fetching analytics data:', error)
    } finally {
      setDetailLoading(false)
    }
  }

  const columns = [
    {
      title: '광고 세트 ID',
      key: 'id',
      width: '180px',
      render: (_, record) => (
        <Button
          type='link'
          onClick={() => handleAdSetSelect(record)}
          className='text-blue-500 hover:text-blue-700'
        >
          <span>{record._id.slice(-8)}</span>
        </Button>
      ),
    },
    {
      title: '광고 세트',
      key: 'adSet',
      render: (_, record) => (
        <span>{record.cpmItemIds?.length || 0}개 상품 광고</span>
      ),
    },
    {
      title: '상태',
      key: 'status',
      width: '100px',
      align: 'center',
      render: (_, record) => getStatusTag(record.status),
    },
    {
      title: '광고 기간',
      key: 'period',
      render: (_, record) => (
        <div>
          {moment(record.startDate).format('YYYY-MM-DD')} ~{' '}
          {moment(record.endDate).subtract(1, 'days').format('YYYY-MM-DD')}
        </div>
      ),
    },
    {
      title: '최대 노출 수',
      dataIndex: 'maxImpressions',
      key: 'maxImpressions',
      width: '15%',
      align: 'right',
      render: (_, record) => record.maxImpressions?.toLocaleString() || '-',
    },
    {
      title: '현재 노출 수',
      dataIndex: ['analytics', 'impressions'],
      key: 'impressions',
      width: '15%',
      align: 'right',
      sorter: (a, b) =>
        (a.analytics?.impressions || 0) - (b.analytics?.impressions || 0),
      render: (_, record) =>
        (record.analytics?.impressions || 0).toLocaleString(),
    },
    {
      title: '소진 예산',
      dataIndex: ['analytics', 'spentBudget'],
      key: 'spentBudget',
      width: '20%',
      align: 'right',
      sorter: (a, b) =>
        (a.analytics?.spentBudget || 0) - (b.analytics?.spentBudget || 0),
      render: (_, record) =>
        `₩${(record.analytics?.spentBudget || 0).toLocaleString()}`,
    },
    {
      title: '매출',
      dataIndex: ['analytics', 'revenue'],
      key: 'revenue',
      width: '20%',
      align: 'right',
      sorter: (a, b) =>
        (a.analytics?.revenue || 0) - (b.analytics?.revenue || 0),
      render: (_, record) =>
        `₩${(record.analytics?.revenue || 0).toLocaleString()}`,
    },
    {
      title: 'ROAS',
      key: 'roas',
      width: '10%',
      align: 'right',
      sorter: (a, b) => {
        const roasA =
          a.analytics?.spentBudget > 0
            ? (a.analytics.revenue / a.analytics.spentBudget) * 100
            : 0
        const roasB =
          b.analytics?.spentBudget > 0
            ? (b.analytics.revenue / b.analytics.spentBudget) * 100
            : 0
        return roasA - roasB
      },
      render: (_, record) => {
        const roas =
          record.analytics?.spentBudget > 0
            ? (
                (record.analytics.revenue / record.analytics.spentBudget) *
                100
              ).toFixed(0)
            : 0
        return `${roas}%`
      },
    },
  ]

  const handleAdSetSelect = async (adSet) => {
    setSelectedAdSet(adSet)
    setIsDetailModalVisible(true)
    setDetailLoading(true)

    try {
      const analyticsRes = await backendApis.getCpmAdSetAnalytics({
        adSetId: adSet._id,
      })
      if (analyticsRes?.status === 200) {
        setAnalyticsData(analyticsRes.data)
      }
    } catch (error) {
      console.error('Error fetching analytics data:', error)
    } finally {
      setDetailLoading(false)
    }
  }

  const handleDateOptionChange = (e) => {
    setDateOption(e.target.value)
    if (e.target.value === 'all') {
      setDateRange([null, null])
      fetchAdSets()
    }
  }

  useEffect(() => {
    setCurrentPage(1)
    fetchAdSets(1)
  }, [dateRange, dateOption])

  const renderTableContent = () => {
    if (loading) {
      return (
        <div className='flex-auto p-8 mb-4 mr-4 bg-white shadow-lg rounded-2xl'>
          <div className='flex justify-center items-center mb-5 text-xl'>
            데이터를 불러오는 중입니다...
          </div>
        </div>
      )
    }

    return (
      <Table
        columns={columns}
        dataSource={displayedAdSets}
        rowKey={(record) => record._id.$oid}
        loading={loading}
        pagination={{
          current: currentPage,
          pageSize,
          total,
          onChange: (page, pageSize) => {
            updateDisplayedAdSets(page, pageSize)
          },
          showSizeChanger: true,
          showTotal: (total) => `총 ${total}개`,
          position: ['bottomCenter'],
        }}
        locale={{
          emptyText: '광고 데이터가 없습니다.',
        }}
      />
    )
  }

  const renderTotalStats = () => {
    if (loading) {
      return (
        <div className='bg-white shadow-lg rounded-2xl p-8 mb-6'>
          <div className='text-xl font-bold mb-5'>전체 광고 성과</div>
          <div className='flex justify-center items-center p-8'>
            <div className='text-gray-500'>데이터를 불러오는 중입니다...</div>
          </div>
        </div>
      )
    }

    if (allAdSets.length === 0) return null

    const roas =
      totalStats.totalSpentBudget > 0
        ? (
            (totalStats.totalRevenue / totalStats.totalSpentBudget) *
            100
          ).toFixed(0)
        : 0

    return (
      <div className='bg-white shadow-lg rounded-2xl p-8 mb-6'>
        <div className='text-xl font-bold mb-5'>전체 광고 성과</div>
        <Row gutter={[16, 16]}>
          <Col span={6}>
            <Card bordered={false} className='bg-gray-50'>
              <div className='text-sm text-gray-600 mb-1'>총 노출 수</div>
              <div className='text-xl font-bold text-blue-600'>
                {totalStats.totalImpressions.toLocaleString()}
              </div>
            </Card>
          </Col>
          <Col span={6}>
            <Card bordered={false} className='bg-gray-50'>
              <div className='text-sm text-gray-600 mb-1'>총 소진 예산</div>
              <div className='text-xl font-bold text-orange-600'>
                ₩{totalStats.totalSpentBudget.toLocaleString()}
              </div>
            </Card>
          </Col>
          <Col span={6}>
            <Card bordered={false} className='bg-gray-50'>
              <div className='text-sm text-gray-600 mb-1'>총 매출</div>
              <div className='text-xl font-bold text-green-600'>
                ₩{totalStats.totalRevenue.toLocaleString()}
              </div>
            </Card>
          </Col>
          <Col span={6}>
            <Card bordered={false} className='bg-gray-50'>
              <div className='text-sm text-gray-600 mb-1'>ROAS</div>
              <div className='text-xl font-bold text-purple-600'>{roas}%</div>
              <div className='text-xs text-gray-500'>(광고비 대비 매출)</div>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }

  const itemColumns = [
    {
      title: '상품명',
      key: 'itemName',
      width: '35%',
      sorter: (a, b) => a.itemName.localeCompare(b.itemName),
      render: (_, record) => (
        <div className='flex items-center'>
          <img
            src={record.itemImage}
            alt={record.itemName}
            className='w-10 h-10 object-cover rounded mr-2 flex-shrink-0'
          />
          <span className='truncate' title={record.itemName}>
            {record.itemName}
          </span>
        </div>
      ),
    },
    {
      title: '노출 수',
      dataIndex: 'impressions',
      key: 'impressions',
      width: '15%',
      align: 'right',
      sorter: (a, b) => (a.impressions || 0) - (b.impressions || 0),
      render: (imp) => (imp || 0).toLocaleString(),
    },
    {
      title: '소진 예산',
      dataIndex: 'spentBudget',
      key: 'spentBudget',
      width: '20%',
      align: 'right',
      sorter: (a, b) => (a.spentBudget || 0) - (b.spentBudget || 0),
      render: (spent) => `₩${(spent || 0).toLocaleString()}`,
    },
    {
      title: '매출',
      dataIndex: 'revenue',
      key: 'revenue',
      width: '20%',
      align: 'right',
      sorter: (a, b) => (a.revenue || 0) - (b.revenue || 0),
      render: (revenue) => `₩${(revenue || 0).toLocaleString()}`,
    },
    {
      title: 'ROAS',
      key: 'roas',
      width: '10%',
      align: 'right',
      defaultSortOrder: 'descend',
      sorter: (a, b) => {
        const roasA = a.spentBudget > 0 ? (a.revenue / a.spentBudget) * 100 : 0
        const roasB = b.spentBudget > 0 ? (b.revenue / b.spentBudget) * 100 : 0
        return roasA - roasB
      },
      render: (_, record) => {
        const roas =
          record.spentBudget > 0
            ? ((record.revenue / record.spentBudget) * 100).toFixed(0)
            : 0
        return `${roas}%`
      },
    },
  ]

  const renderItemAnalytics = () => {
    if (!analyticsData?.adSetAnalytics?.items?.length) {
      return (
        <div className='flex justify-center items-center p-8 bg-white rounded-xl'>
          <div className='text-xl text-gray-500'>
            상품별 성과 데이터가 없습니다.
          </div>
        </div>
      )
    }

    return (
      <Card title='상품별 성과'>
        <Table
          columns={itemColumns}
          dataSource={analyticsData.adSetAnalytics.items}
          rowKey='itemId'
          pagination={false}
          scroll={{ y: 400 }}
          loading={detailLoading}
          onChange={(pagination, filters, sorter) => {
            console.log('정렬 변경:', sorter)
          }}
          defaultSortOrder='descend'
          sortDirections={['descend', 'ascend']}
          defaultSortField='roas'
        />
      </Card>
    )
  }

  const getStatusTag = (status) => {
    switch (status) {
      case 'active':
        return <Tag color='green'>진행중</Tag>
      case 'completed':
        return <Tag color='blue'>종료</Tag>
      case 'cancelledAfterActive':
        return <Tag color='orange'>중단</Tag>
      default:
        return null
    }
  }

  const handleRefresh = () => {
    if (isRefreshDisabled) {
      const lastRefreshTime = parseInt(
        localStorage.getItem(REFRESH_TIMESTAMP_KEY),
        10,
      )
      const remaining = Math.ceil(
        REFRESH_COOLDOWN - (Date.now() - lastRefreshTime) / 1000,
      )
      message.warning(`새로고침은 ${remaining}초 후에 가능합니다`)
      return
    }

    fetchAdSets()
    setIsRefreshDisabled(true)
    localStorage.setItem(REFRESH_TIMESTAMP_KEY, Date.now().toString())

    setTimeout(() => {
      setIsRefreshDisabled(false)
      setRemainingTime(0)
    }, REFRESH_COOLDOWN * 1000)
  }

  useEffect(() => {
    let intervalId

    if (isRefreshDisabled) {
      intervalId = setInterval(() => {
        const lastRefreshTime = parseInt(
          localStorage.getItem(REFRESH_TIMESTAMP_KEY),
          10,
        )
        const remaining = Math.ceil(
          REFRESH_COOLDOWN - (Date.now() - lastRefreshTime) / 1000,
        )

        if (remaining <= 0) {
          setIsRefreshDisabled(false)
          setRemainingTime(0)
          clearInterval(intervalId)
        } else {
          setRemainingTime(remaining)
        }
      }, 1000)
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
    }
  }, [isRefreshDisabled])

  useEffect(() => {
    const lastRefreshTime = localStorage.getItem(REFRESH_TIMESTAMP_KEY)
    if (lastRefreshTime) {
      const timeSinceLastRefresh =
        (Date.now() - parseInt(lastRefreshTime, 10)) / 1000
      if (timeSinceLastRefresh < REFRESH_COOLDOWN) {
        setIsRefreshDisabled(true)
        setRemainingTime(Math.ceil(REFRESH_COOLDOWN - timeSinceLastRefresh))
      }
    }
  }, [])

  useEffect(() => {
    fetchAdSets()
  }, [refreshTrigger])

  return (
    <div className='space-y-6'>
      <div className='mb-6'>
        <div className='flex justify-between items-center mb-4'>
          <div className='flex gap-8'>
            <div className='max-w-xs'>
              <RadioGroup
                options={dateOptions}
                value={dateOption}
                onChange={handleDateOptionChange}
              />
            </div>
          </div>
          <Button
            onClick={handleRefresh}
            icon={<ReloadOutlined spin={isRefreshDisabled} />}
            loading={loading}
            disabled={isRefreshDisabled}
          >
            {isRefreshDisabled
              ? `${remainingTime}초 후 새로고침 가능`
              : '새로고침'}
          </Button>
        </div>
        {dateOption === 'custom' && (
          <div className='flex items-center gap-4 mt-4'>
            <span className='text-[0.9vw] font-bold'>광고 시작일</span>
            <RangePicker
              onChange={setDateRange}
              value={dateRange}
              format='YYYY-MM-DD'
            />
          </div>
        )}
      </div>

      {renderTotalStats()}
      {renderTableContent()}

      <Modal
        title='광고 세트 상세 분석'
        open={isDetailModalVisible}
        onCancel={() => {
          setIsDetailModalVisible(false)
          setSelectedAdSet(null)
        }}
        width={1200}
        footer={null}
      >
        {selectedAdSet && (
          <div className='space-y-6'>
            <div className='flex items-center gap-2 mb-4'>
              <span className='font-bold'>광고 세트 ID:</span>
              <span className='text-gray-600'>{selectedAdSet._id}</span>
              <Button
                type='text'
                icon={<CopyOutlined />}
                onClick={() => {
                  navigator.clipboard.writeText(selectedAdSet._id)
                  message.success('ID가 복사되었습니다')
                }}
                className='text-gray-400 hover:text-gray-600'
              />
            </div>
            <div className='flex items-center gap-2 mb-4'>
              <span className='font-bold'>광고 기간:</span>
              <span className='text-gray-600'>
                {moment(selectedAdSet.startDate).format('YYYY-MM-DD')} ~{' '}
                {moment(selectedAdSet.endDate)
                  .subtract(1, 'days')
                  .format('YYYY-MM-DD')}
              </span>
            </div>

            <Row gutter={[16, 16]}>
              <Col span={6}>
                <Card>
                  <div className='text-lg font-bold mb-2'>총 노출 수</div>
                  <div className='text-2xl text-blue-600'>
                    {selectedAdSet.analytics?.impressions?.toLocaleString() ||
                      '0'}
                  </div>
                </Card>
              </Col>
              <Col span={6}>
                <Card>
                  <div className='text-lg font-bold mb-2'>소진 예산</div>
                  <div className='text-2xl text-orange-600'>
                    ₩
                    {selectedAdSet.analytics?.spentBudget?.toLocaleString() ||
                      '0'}
                  </div>
                </Card>
              </Col>
              <Col span={6}>
                <Card>
                  <div className='text-lg font-bold mb-2'>매출</div>
                  <div className='text-2xl text-green-600'>
                    ₩{selectedAdSet.analytics?.revenue?.toLocaleString() || '0'}
                  </div>
                </Card>
              </Col>
              <Col span={6}>
                <Card>
                  <div className='text-lg font-bold mb-2'>ROAS</div>
                  <div className='text-2xl text-purple-600'>
                    {selectedAdSet.analytics?.spentBudget > 0
                      ? (
                          (selectedAdSet.analytics.revenue /
                            selectedAdSet.analytics.spentBudget) *
                          100
                        ).toFixed(0)
                      : '0'}
                    %
                  </div>
                  <div className='text-xs text-gray-500'>
                    (광고비 대비 매출)
                  </div>
                </Card>
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col span={24}>
                {selectedAdSet.cpmItemIds?.length > 0 ? (
                  <Card title='상품별 성과'>
                    <Table
                      columns={itemColumns}
                      dataSource={analyticsData?.adSetAnalytics?.items || []}
                      rowKey='itemId'
                      pagination={false}
                      scroll={{ y: 400 }}
                      loading={detailLoading}
                      onChange={(pagination, filters, sorter) => {
                        console.log('정렬 변경:', sorter)
                      }}
                      defaultSortOrder='descend'
                      sortDirections={['descend', 'ascend']}
                      defaultSortField='roas'
                    />
                  </Card>
                ) : (
                  <div className='flex justify-center items-center p-8 bg-white rounded-xl'>
                    <div className='text-xl text-gray-500'>
                      상품별 성과 데이터가 없습니다.
                    </div>
                  </div>
                )}
              </Col>
            </Row>
          </div>
        )}
      </Modal>
    </div>
  )
}

export default CpmAdAnalyticsSection
