import React, { useState, useEffect } from 'react'
import {
  Table,
  Tag,
  Space,
  DatePicker,
  Button,
  message,
  Modal,
  Spin,
  List,
  Select,
} from 'antd'
import moment from 'moment'
import backendApis from '../../../utils/backendApis'
import RadioGroup from '../../../components/ADS/molecules/radioGroup'
import { ReloadOutlined, CopyOutlined } from '@ant-design/icons'

const { RangePicker } = DatePicker

const CpmAdModificationSection = ({ refreshTrigger }) => {
  const [targetAd, setTargetAd] = useState([])
  const [dateRange, setDateRange] = useState([null, null])
  const [dateOption, setDateOption] = useState('all')
  const [isDetailModalVisible, setIsDetailModalVisible] = useState(false)
  const [selectedAd, setSelectedAd] = useState(null)
  const [selectedAdItems, setSelectedAdItems] = useState([])
  const [isLoadingItems, setIsLoadingItems] = useState(false)
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false)
  const [selectedAdForCancel, setSelectedAdForCancel] = useState(null)
  const [refundCredit, setRefundCredit] = useState(null)
  const [statusFilter, setStatusFilter] = useState('all')
  const [filteredAds, setFilteredAds] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(20)
  const [total, setTotal] = useState(0)
  const [isCancelling, setIsCancelling] = useState(false)

  const statusOptions = [
    { label: '전체', value: 'all' },
    { label: '대기 중', value: 'pending' },
    { label: '진행중', value: 'active' },
    { label: '취소됨', value: 'cancelled' },
    { label: '완료됨', value: 'completed' },
  ]

  useEffect(() => {
    fetchData()
  }, [refreshTrigger])

  useEffect(() => {
    if (!targetAd?.length) return

    const filtered = targetAd.filter((ad) => {
      if (statusFilter === 'all') return true
      if (statusFilter === 'cancelled') {
        return ['cancelledAfterActive', 'cancelledBeforeActive'].includes(
          ad.status,
        )
      }
      return ad.status === statusFilter
    })

    setFilteredAds(filtered)
  }, [targetAd, statusFilter])

  const fetchData = async (page = currentPage) => {
    try {
      const [startDate, endDate] = dateRange
      const res = await backendApis.getCpmAdSetList({
        size: pageSize,
        page,
        orderBy: 'createdAt',
        startDate:
          dateOption === 'custom' && startDate
            ? startDate.format('YYYY-MM-DD')
            : undefined,
        endDate:
          dateOption === 'custom' && endDate
            ? endDate.format('YYYY-MM-DD')
            : undefined,
        status: statusFilter === 'all' ? undefined : statusFilter,
      })

      if (res?.status === 200) {
        setTargetAd(res.data)
        setTotal(res.data.length)

        const filtered = res.data.filter((ad) => {
          if (statusFilter === 'all') return true
          if (statusFilter === 'cancelled') {
            return ['cancelledAfterActive', 'cancelledBeforeActive'].includes(
              ad.status,
            )
          }
          return ad.status === statusFilter
        })
        setFilteredAds(filtered)
      }
    } catch (error) {
      console.error('Error fetching CPM ad data:', error)
      setTargetAd([])
      setTotal(0)
      setFilteredAds([])
    }
  }

  const handleDateChange = (dates) => {
    setDateRange(dates)
  }

  const handleDateOptionChange = (e) => {
    setDateOption(e.target.value)
    if (e.target.value === 'all') {
      setDateRange([null, null])
      fetchData()
    }
  }

  const showCancelConfirm = async (record) => {
    setSelectedAdForCancel(record)
    setIsConfirmModalVisible(true)
    setRefundCredit(null)
    if (record.status === 'active') {
      await fetchRefundCredit(record._id)
    }
  }

  const handleCancelConfirm = async () => {
    if (!selectedAdForCancel || isCancelling) return

    setIsCancelling(true)
    try {
      const res = await backendApis.cancelCpmAdSet({
        adCpmSetId: selectedAdForCancel._id,
      })

      if (res?.status === 200) {
        message.success('광고가 취소되었습니다.')
        fetchData()
      } else {
        message.error(res?.message || '광고 취소에 실패했습니다.')
      }
    } catch (error) {
      console.error('Error canceling ad:', error)
      message.error('광고 취소 중 오류가 발생했습니다.')
    } finally {
      setIsConfirmModalVisible(false)
      setSelectedAdForCancel(null)
      setRefundCredit(null)
      setIsCancelling(false)
    }
  }

  const dateOptions = [
    { label: '전체 기간', value: 'all' },
    { label: '날짜 선택', value: 'custom' },
  ]

  const columns = [
    {
      title: '광고 세트 ID',
      key: 'id',
      align: 'center',
      width: 200,
      render: (_, record) => (
        <Button
          type='link'
          onClick={() => handleOpenModal(record)}
          className='text-blue-500 hover:text-blue-700'
        >
          <span>{record._id.slice(-8)}</span>
        </Button>
      ),
    },
    {
      title: '생성일',
      key: 'createdAt',
      align: 'center',
      render: (_, record) => (
        <span>{moment(record.createdAt).format('YYYY-MM-DD HH:mm:ss')}</span>
      ),
    },
    {
      title: '광고 세트',
      key: 'itemCount',
      align: 'center',
      render: (_, record) => {
        const itemCount = record.cpmItemIds?.length || 0
        return <span>{itemCount}개 상품</span>
      },
    },
    {
      title: '예산',
      dataIndex: 'budget',
      key: 'budget',
      align: 'center',
      render: (budget) => `${budget.toLocaleString()}원`,
    },
    {
      title: '노출 단가',
      dataIndex: 'costPerImpression',
      key: 'costPerImpression',
      align: 'center',
      render: (cost) => `${cost}원`,
    },
    {
      title: '최대 노출 수',
      dataIndex: 'maxImpressions',
      key: 'maxImpressions',
      align: 'center',
      render: (impressions) => impressions.toLocaleString(),
    },
    {
      title: '광고 기간',
      key: 'adPeriod',
      align: 'center',
      render: (_, record) => (
        <span>
          {moment(record.startDate).format('YYYY-MM-DD')} ~{' '}
          {moment(record.endDate).subtract(1, 'days').format('YYYY-MM-DD')}
        </span>
      ),
    },
    {
      title: '성별',
      key: 'gender',
      align: 'center',
      render: (_, record) => {
        if (!record.targeting) return '-'

        const genderMap = {
          all: '전체',
          male: '남성',
          female: '여성',
        }

        return genderMap[record.targeting.gender] || '-'
      },
    },
    {
      title: '연령',
      key: 'age',
      align: 'center',
      render: (_, record) => {
        if (!record.targeting?.ageRange) return '전체'
        return getAgeRangeText(record.targeting.ageRange)
      },
    },
    {
      title: '상태',
      key: 'status',
      dataIndex: 'status',
      align: 'center',
      render: (status) => {
        const statusConfig = {
          pending: { color: 'gold', text: '대기 중' },
          rejected: { color: 'red', text: '거부됨' },
          active: { color: 'green', text: '진행중' },
          cancelledAfterActive: { color: 'gray', text: '취소됨' },
          cancelledBeforeActive: { color: 'gray', text: '취소됨' },
          completed: { color: 'blue', text: '종료됨' },
        }

        const { color, text } = statusConfig[status] || statusConfig.approved
        return <Tag color={color}>{text}</Tag>
      },
    },
    {
      title: '액션',
      key: 'action',
      align: 'center',
      render: (_, record) => (
        <Space size='middle'>
          {(record.status === 'pending' || record.status === 'active') && (
            <Button type='link' onClick={() => showCancelConfirm(record)}>
              취소
            </Button>
          )}
        </Space>
      ),
    },
  ]

  const handleOpenModal = async (record) => {
    setSelectedAd(record)
    setIsDetailModalVisible(true)
    setIsLoadingItems(true)

    try {
      const itemsRes = await backendApis.getItemsByAdSetId({
        adCpmSetId: record._id,
      })
      setSelectedAdItems(itemsRes.data || [])
    } catch (error) {
      console.error('Error fetching items:', error)
      setSelectedAdItems([])
    } finally {
      setIsLoadingItems(false)
    }
  }

  // 상태 표시를 위한 헬퍼 함수 추가
  const getStatusConfig = (status) => {
    const statusMap = {
      pending: { color: 'gold', text: '대기 중' },
      rejected: { color: 'red', text: '거부됨' },
      active: { color: 'green', text: '진행중' },
      cancelledAfterActive: { color: 'gray', text: '취소됨' },
      cancelledBeforeActive: { color: 'gray', text: '취소됨' },
      completed: { color: 'blue', text: '종료됨' },
    }
    return statusMap[status] || statusMap.approved
  }

  // 성별 표시를 위한 헬퍼 함수 추가
  const getGenderText = (gender) => {
    const genderMap = {
      all: '전체',
      male: '남성',
      female: '여성',
    }
    return genderMap[gender] || '-'
  }

  const handleSearch = () => {
    setCurrentPage(1)
    fetchData(1)
  }

  const fetchRefundCredit = async (adCpmSetId) => {
    try {
      const res = await backendApis.getRefundCreditAmount({ adCpmSetId })
      if (res?.status === 200) {
        setRefundCredit(res.data.refundAmount)
      }
    } catch (error) {
      console.error('Error fetching refund credit:', error)
    }
  }

  const handleStatusFilterChange = (value) => {
    setStatusFilter(value)
  }

  // 연령 표시를 위한 헬퍼 함수 추가
  const getAgeRangeText = (ageRange) => {
    if (!ageRange) return '전체'
    return ageRange[0] === 0 && ageRange[1] === 100
      ? '전체'
      : `${ageRange[0]}~${ageRange[1]}세`
  }

  useEffect(() => {
    setCurrentPage(1)
    fetchData(1)
  }, [dateRange, dateOption, statusFilter, isDetailModalVisible])

  return (
    <div className='text-slate-700'>
      <div style={{ marginBottom: 16 }}>
        <div className='flex gap-8 mb-4'>
          <div className='max-w-xs'>
            <RadioGroup
              options={dateOptions}
              value={dateOption}
              onChange={handleDateOptionChange}
            />
          </div>
        </div>
        <div className='flex items-center gap-4 mt-4'>
          {dateOption === 'custom' && (
            <>
              <span className='text-[0.9vw] font-bold'>광고 시작일</span>
              <RangePicker
                onChange={handleDateChange}
                value={dateRange}
                format='YYYY-MM-DD'
              />
              <Button
                type='primary'
                onClick={handleSearch}
                disabled={
                  dateOption === 'custom' && (!dateRange[0] || !dateRange[1])
                }
              >
                조회하기
              </Button>
            </>
          )}
        </div>
      </div>
      <div className='flex justify-between mb-4'>
        <Select
          style={{ width: 120 }}
          value={statusFilter}
          onChange={handleStatusFilterChange}
          options={statusOptions}
          placeholder='상태 선택'
        />
        <Button
          icon={<ReloadOutlined />}
          onClick={() => fetchData(currentPage)}
          className='ml-2'
        >
          새로고침
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={filteredAds}
        rowKey={(record) => record._id}
        pagination={{
          current: currentPage,
          pageSize,
          total,
          onChange: (page, pageSize) => {
            setCurrentPage(page)
            setPageSize(pageSize)
            fetchData(page)
          },
          showSizeChanger: true,
          showTotal: (total) => `총 ${total}개`,
          position: ['bottomCenter'],
        }}
      />
      <Modal
        title='광고 세트 상세 정보'
        open={isDetailModalVisible}
        onCancel={() => setIsDetailModalVisible(false)}
        footer={null}
        width={800}
      >
        {selectedAd && (
          <div className='space-y-4'>
            <div className='flex justify-between items-center mb-4'>
              <div className='flex items-center gap-2'>
                <span className='font-bold'>광고 세트 ID:</span>
                <span className='text-gray-600'>{selectedAd._id}</span>
                <Button
                  type='text'
                  icon={<CopyOutlined />}
                  onClick={() => {
                    navigator.clipboard.writeText(selectedAd._id)
                    message.success('ID가 복사되었습니다')
                  }}
                  className='text-gray-400 hover:text-gray-600'
                />
              </div>
            </div>

            <div className='grid grid-cols-2 gap-4'>
              <div>
                <h3 className='font-bold mb-2'>타겟팅 정보</h3>
                <p>성별: {getGenderText(selectedAd.targeting?.gender)}</p>
                <p>연령: {getAgeRangeText(selectedAd.targeting?.ageRange)}</p>
              </div>
              <div>
                <h3 className='font-bold mb-2'>광고 정보</h3>
                <p>예산: {selectedAd.budget.toLocaleString()}원</p>
                <p>노출 단가: {selectedAd.costPerImpression}원</p>
                <p>
                  최대 노출 수: {selectedAd.maxImpressions.toLocaleString()}
                </p>
              </div>
            </div>

            <div>
              <div className='flex justify-between items-center mb-2'>
                <h3 className='font-bold'>
                  등록된 상품 목록 ({selectedAdItems.length}개)
                </h3>
              </div>

              {isLoadingItems && (
                <div className='text-center py-4'>
                  <Spin /> 상품 정보를 불러오는 중...
                </div>
              )}

              {!isLoadingItems && selectedAdItems.length > 0 && (
                <List
                  grid={{ gutter: 16, column: 2 }}
                  dataSource={selectedAdItems}
                  renderItem={(item) => (
                    <List.Item
                      key={item._id}
                      className='hover:bg-gray-50 rounded-lg'
                    >
                      <div className='flex p-2 h-full'>
                        <div className='w-[5vw] h-[5vw] mr-3 flex-shrink-0'>
                          <img
                            src={item.mainImageUri || item.mainImageUris?.[0]}
                            alt={item.title}
                            className='w-full h-full object-cover rounded-md'
                            onError={(e) => {
                              e.target.onerror = null
                              e.target.src = '/default-product-image.png'
                            }}
                          />
                        </div>
                        <div className='flex-grow min-w-0'>
                          <div className='font-medium text-sm mb-1 truncate'>
                            {item.itemTitle}
                          </div>
                          <div className='text-[0.7vw] space-y-0.5'>
                            <div className='flex justify-between'>
                              <span>판매가</span>
                              <span>
                                {item.individualPurchasePrice?.toLocaleString()}
                                원
                              </span>
                            </div>
                            <div className='flex justify-between'>
                              <span>팀구매가</span>
                              <span>
                                {item.teamPurchasePrice?.toLocaleString()}원
                              </span>
                            </div>
                            <div className='flex justify-between'>
                              <span>재고</span>
                              <span>
                                {item.outOfStock ? '품절' : '재고 있음'}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </List.Item>
                  )}
                  pagination={{
                    pageSize: 6,
                    position: 'bottom',
                    align: 'center',
                    total: selectedAdItems.length,
                    showSizeChanger: false,
                  }}
                />
              )}

              {!isLoadingItems && selectedAdItems.length === 0 && (
                <div className='text-center text-gray-500 py-4'>
                  등록된 상품이 없습니다.
                </div>
              )}
            </div>

            <div>
              <h3 className='font-bold mb-2'>광고 기간</h3>
              <p>
                {moment(selectedAd.startDate).format('YYYY-MM-DD')} ~{' '}
                {moment(selectedAd.endDate)
                  .subtract(1, 'days')
                  .format('YYYY-MM-DD')}
              </p>
            </div>

            <div>
              <h3 className='font-bold mb-2'>상태</h3>
              {(() => {
                const { color, text } = getStatusConfig(selectedAd.status)
                return <Tag color={color}>{text}</Tag>
              })()}
            </div>
          </div>
        )}
      </Modal>
      <Modal
        title='광고 취소 확인'
        open={isConfirmModalVisible}
        onOk={handleCancelConfirm}
        onCancel={() => {
          setIsConfirmModalVisible(false)
          setSelectedAdForCancel(null)
          setRefundCredit(null)
        }}
        okText='확인'
        cancelText='취소'
        okButtonProps={{
          loading: isCancelling,
          disabled: isCancelling,
        }}
      >
        <p>선택한 광고를 취소하시겠습니까?</p>
        {selectedAdForCancel && (
          <div className='mt-4 p-4 bg-gray-50 rounded-md space-y-2'>
            <p className='text-sm text-gray-500'>
              광고 기간:{' '}
              {moment(selectedAdForCancel.startDate).format('YYYY-MM-DD')} ~{' '}
              {moment(selectedAdForCancel.endDate).format('YYYY-MM-DD')}
            </p>
            <p className='text-sm text-gray-500'>
              등록 상품 수: {selectedAdForCancel.cpmItemIds?.length || 0}개
            </p>
            <p className='text-sm text-gray-500'>
              예산: {selectedAdForCancel.budget?.toLocaleString()}원
            </p>
            <div className='mt-3 p-3 bg-blue-50 rounded border border-blue-100'>
              <p className='text-blue-600 font-medium'>
                환불 예정 크레딧:{' '}
                {selectedAdForCancel.status === 'pending'
                  ? selectedAdForCancel.budget?.toLocaleString()
                  : refundCredit?.toLocaleString()}
                원
              </p>
              <p className='text-xs text-blue-500 mt-1'>
                {selectedAdForCancel.status === 'pending'
                  ? '* 대기 중인 광고 취소 시 전체 예산이 크레딧으로 환불됩니다.'
                  : '* 진행 중인 광고 취소 시 잔여 예산이 크레딧으로 환불됩니다. 잔여 예산은 광고 기간 중 노출된 횟수에 따 결정됩니다. 현재 광고가 진행 중이므로 실제 환불 크레딧과 상이할 수 있습니다.'}
              </p>
            </div>
          </div>
        )}
        <p className='mt-4 text-red-500'>* 취소된 광고는 복구할 수 없습니다.</p>
      </Modal>
    </div>
  )
}

export default CpmAdModificationSection
