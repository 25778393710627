import { useState } from 'react'
import backendApis from '../../utils/backendApis'
import SellerStore from '../../stores/SellerStore'
import { observer } from 'mobx-react-lite'

export default observer(function BusinessRegistrationNumberModal() {
  const [showModal, setShowModal] = useState(true)
  const [businessRegistrationNumber, setBusinessRegistrationNumber] = useState()
  const [businessStartDate, setBusinessStartDate] = useState()
  const [ceoName, setCeoName] = useState()
  const regex = /[^0-9]/g
  return (
    <>
      {showModal && (
        <>
          <div className='fixed inset-0 z-50 flex items-center justify-center px-2 overflow-x-hidden overflow-y-auto outline-none'>
            <div className='relative w-full max-w-xl mx-auto my-6 shadow-lg'>
              {/* content */}
              <div className='relative flex flex-col w-full bg-white border-0 outline-none'>
                {/* header */}
                <div className='flex items-start justify-between p-5 border-b border-solid border-slate-200'>
                  <h3 className='text-xl font-semibold text-black'>
                    사업자 번호를 등록해주세요
                  </h3>
                </div>
                {/* body */}
                <div className='relative flex flex-col items-center justify-center px-10 py-10'>
                  {/* <div className='flex flex-row mb-5 font-bold'>
                    현재 사업자 번호는{' '}
                    <span className='mx-2 text-red-500'>
                      {SellerStore?.sellerInfo?.businessRegistrationNumber}
                    </span>
                    입니다.
                  </div> */}
                  <div />
                  <div className='mb-5 text-center'>
                    2024년 계산서 발급을 위한 사업자 번호를
                    <br />
                    아래 양식에 맞게 새로 입력해주세요.
                    <br />
                    사업자 번호가 다를 경우{' '}
                    <span className='font-bold text-red-500'>
                      정산이 불가합니다.
                    </span>
                  </div>
                  <div className='w-2/3'>
                    <form
                      className='flex flex-col flex-1 gap-2'
                      onSubmit={async (e) => {
                        e.preventDefault()

                        if (SellerStore.isLoading === true) {
                          return
                        }
                        SellerStore.setIsLoading(true)
                        if (
                          !businessRegistrationNumber ||
                          !businessStartDate ||
                          !ceoName
                        ) {
                          alert('모든 항목을 입력해주세요.')
                          SellerStore.setIsLoading(false)
                          return
                        }
                        if (!businessRegistrationNumber.includes('-')) {
                          alert(
                            '사업자 등록번호는 하이픈(-)을 포함해야 합니다.',
                          )
                          SellerStore.setIsLoading(false)
                          return
                        }
                        if (businessRegistrationNumber.length !== 12) {
                          alert('사업자등록번호를 정확히 입력해주세요.')
                          SellerStore.setIsLoading(false)
                          return
                        }
                        // const registerCheck =
                        //   await backendApis.checkRegisteredBizNum(
                        //     businessRegistrationNumber,
                        //   )
                        // if (registerCheck?.status === 200) {
                        //   if (registerCheck?.data?.isRegistered === true) {
                        //     alert(
                        //       '이미 등록된 사업자 번호입니다. 하나의 사업자 번호로 한 번만 등록이 가능합니다.',
                        //     )
                        //     SellerStore.setIsLoading(false)
                        //     return
                        //   }
                        // }
                        const check =
                          await backendApis.confirmBusinessRegistrationNumber(
                            businessRegistrationNumber.replace(regex, ''),
                            businessStartDate,
                            ceoName,
                          )
                        if (check?.status_code === 'OK') {
                          if (check?.data?.[0]?.valid !== '01') {
                            alert(
                              '제출이 거부되었습니다. 정상적인 사업자 번호/개업일자/대표자명을 다시 입력해주세요.',
                            )
                            SellerStore.setIsLoading(false)
                            return
                          }
                          if (
                            check?.data?.[0]?.status?.b_stt !== '계속사업자'
                          ) {
                            alert(
                              '제출이 거부되었습니다. 폐업 신고된 사업자번호입니다.',
                            )
                            SellerStore.setIsLoading(false)
                            return
                          }
                          const result =
                            await backendApis.updateBusinessRegistrationNumber(
                              businessRegistrationNumber,
                              businessStartDate,
                              ceoName,
                            )
                          if (result?.status === 200) {
                            alert('제출이 완료되었습니다.')
                            setShowModal(false)
                            SellerStore.setIsLoading(false)
                            return
                          }
                          alert('제출에 실패했습니다. 다시 시도해주세요.')
                          SellerStore.setIsLoading(false)
                        }
                      }}
                    >
                      <input
                        type='text'
                        onChange={(e) => {
                          setBusinessRegistrationNumber(e.target.value)
                        }}
                        maxLength='12'
                        placeholder='사업자 등록번호 ( - 포함)'
                      />
                      <input
                        type='text'
                        onChange={(e) => {
                          setBusinessStartDate(e.target.value)
                        }}
                        placeholder='개업일 (ex.20110220)'
                        maxLength='8'
                      />
                      <input
                        type='text'
                        onChange={(e) => {
                          setCeoName(e.target.value)
                        }}
                        placeholder='대표자명'
                      />
                      <input
                        className='py-3 font-bold text-white cursor-pointer bg-positive-500 rounded-xl'
                        type='submit'
                        value='등록하기'
                      />
                    </form>
                  </div>
                </div>
              </div>
              {/* footer */}
              <div className='flex items-center justify-end p-6 bg-gray-100 border-t border-solid rounded-b border-slate-200'>
                {/* <button
                  className='px-6 py-2 text-lg font-bold uppercase transition-all duration-150 ease-linear outline-none text-neutral-500 background-transparent focus:outline-none'
                  type='button'
                  onClick={() => setShowModal(false)}
                >
                  닫기
                </button> */}
              </div>
            </div>
          </div>
          <div className='fixed inset-0 z-40 bg-black opacity-25' />
        </>
      )}
    </>
  )
})
