import React, { useState } from 'react'
import { Calendar } from 'antd'
import moment from 'moment'
import 'moment/locale/ko'
import PickerLocale from 'antd/es/date-picker/locale/ko_KR'

// moment 로케일 설정
moment.locale('ko')

const CalendarPicker = ({
  setDateRange,
  startDateOffset = 1,
  endDateOffset = 7,
  exposureDays = 5,
}) => {
  const [selectedDate, setSelectedDate] = useState(null)

  const onSelect = (date) => {
    const startDate = date
    const endDate = date.clone().add(exposureDays - 1, 'days')

    setSelectedDate(date)
    setDateRange([startDate.toDate(), endDate.toDate()])
  }

  const disabledDate = (current) => {
    const tomorrow = moment().add(startDateOffset, 'days').startOf('day')
    const lastSelectableDay = moment().add(endDateOffset, 'days').endOf('day')

    return current && (current < tomorrow || current > lastSelectableDay)
  }

  return (
    <>
      <Calendar
        locale={PickerLocale}
        fullscreen={false}
        onSelect={onSelect}
        disabledDate={disabledDate}
        value={selectedDate}
      />
    </>
  )
}

export default CalendarPicker
