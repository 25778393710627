import { useEffect, useState } from 'react'
import SellerStore from '../../stores/SellerStore'
import { observer } from 'mobx-react-lite'
import backendApis from '../../utils/backendApis'
import { Tooltip as TooltipOrigin } from 'react-tippy'
import IntroSection from '../businessValidation/introSection'
import StepIndicator from '../businessValidation/stepIndicator'
import BusinessImageSection from '../businessValidation/businessImageSection'
import BusinessNumberSection from '../businessValidation/businessNumberSection'
import BankAccountSection from '../businessValidation/bankAccountSection'

export default observer(function BusinessRegistrationModal({
  onClose = () => {},
}) {
  const [showModal, setShowModal] = useState(true)
  const [businessRegistrationNumber, setBusinessRegistrationNumber] =
    useState('')
  const [businessRegistrationNumberImage, setBusinessRegistrationNumberImage] =
    useState('')
  const [bankInfo, setBankInfo] = useState([])
  const [sellerBankCode, setSellerBankCode] = useState('')
  const [sellerBankAccountNumber, setSellerBankAccountNumber] = useState('')
  const [sellerBankAccountHolderName, setSellerBankAccountHolderName] =
    useState('')
  const [isReLoad, setIsReLoad] = useState(false)
  const [currentStep, setCurrentStep] = useState(1)

  const handleClose = () => {
    setShowModal(false)
    onClose()
  }

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Escape') {
        setShowModal(false)
      }
    }
    document.addEventListener('keydown', handleKeyPress)
    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, [onClose])

  useEffect(async () => {
    const sellerBusinessInfo =
      await backendApis.loadSellerBusinessRegistrationInfo()
    setBusinessRegistrationNumber(
      sellerBusinessInfo?.data?.businessRegistrationNumber,
    )
    setBusinessRegistrationNumberImage(
      sellerBusinessInfo?.data?.businessRegistrationNumberImage,
    )
    setSellerBankCode(sellerBusinessInfo?.data?.bankAccountInfo?.bankCode)
    setSellerBankAccountNumber(
      sellerBusinessInfo?.data?.bankAccountInfo?.bankAccountNumber,
    )
    setSellerBankAccountHolderName(
      sellerBusinessInfo?.data?.bankAccountInfo?.depositorName,
    )
  }, [
    businessRegistrationNumber,
    businessRegistrationNumberImage,
    sellerBankAccountHolderName,
    sellerBankCode,
    sellerBankAccountNumber,
  ])

  useEffect(() => {
    const loadBankInfo = async () => {
      const bankInfo = await backendApis.loadBanksInfo()
      setBankInfo(bankInfo?.data)
    }
    loadBankInfo()
  }, [])

  useEffect(async () => {
    const loadSellerInfo = async () => {
      await SellerStore.loadSellerInfo()
    }
    loadSellerInfo()
  }, [isReLoad])

  const Header = ({ onClose }) => {
    return (
      <div className='flex items-start justify-between p-5 border-b border-solid border-slate-200'>
        <h3 className='text-xl font-semibold text-black'>
          <span className='text-yellow-500'>⚠️</span> 사업자 정보 등록
        </h3>
        <div className='flex flex-row items-center justify-end flex-1'>
          <TooltipOrigin
            arrow='true'
            theme='dark'
            title='❎ 키보드 esc로도 종료하실 수 있어요.'
            animation='fade'
            position='top'
          >
            <button
              type='button'
              onClick={onClose}
              className='px-2 py-1 mr-2 font-bold text-white bg-slate-300 hover:bg-slate-400 rounded-xl'
            >
              esc
            </button>
          </TooltipOrigin>
        </div>
      </div>
    )
  }

  const handleNextStep = () => {
    if (currentStep === 1) {
      const message =
        '검증 시작 전, 아래 내용을 확인해주세요!\n\n' +
        '1. 사업자등록증 스캔 파일을 미리 준비해주세요.\n' +
        '2. 사업자등록번호를 준비해주세요.\n' +
        '3. 정산지급 대상 계좌 정보를 미리 준비해주세요.'

      if (confirm(message)) {
        setCurrentStep((prevStep) => prevStep + 1)
      }
    } else {
      setCurrentStep((prevStep) => prevStep + 1)
    }
  }

  const handlePreviousStep = () => {
    setCurrentStep((prevStep) => prevStep - 1)
  }

  return (
    <>
      {showModal && (
        <>
          <div className='fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none'>
            <div className='relative w-[1000px] mx-auto my-6'>
              <div
                className='relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none'
                style={{ height: 'calc(100vh - 100px)' }}
              >
                <Header onClose={handleClose} />
                <div className='relative flex-auto overflow-y-auto'>
                  <div className='flex flex-col items-center justify-center p-5'>
                    <StepIndicator currentStep={currentStep} />
                    {currentStep === 1 && (
                      <div className='w-4/5'>
                        <IntroSection />
                        <button
                          type='button'
                          className='mt-8 px-6 py-3 bg-blue-500 text-white rounded-md w-full'
                          onClick={handleNextStep}
                        >
                          시작하기
                        </button>
                      </div>
                    )}
                    {currentStep === 2 && (
                      <div className='w-4/5'>
                        <div className='mb-6 p-4 bg-gray-50 rounded-lg'>
                          <h4 className='font-bold mb-2'>
                            📄 사업자등록증 이미지 등록
                          </h4>
                          <p className='text-gray-600'>
                            사업자등록증 스캔본 또는 선명한 사진을
                            업로드해주세요. (jpg/jpeg/png, 최대 5MB)
                            <br />
                            사업자등록증 상 모든 정보가 명확히 보여야 합니다.
                          </p>
                        </div>
                        <BusinessImageSection
                          sellerInfo={SellerStore?.sellerInfo}
                          onNextStep={handleNextStep}
                          onPreviousStep={handlePreviousStep}
                        />
                      </div>
                    )}
                    {currentStep === 3 && (
                      <div className='w-4/5'>
                        <div className='mb-6 p-4 bg-gray-50 rounded-lg'>
                          <h4 className='font-bold mb-2'>
                            🔢 사업자등록번호 입력
                          </h4>
                          <p className='text-gray-600'>
                            사업자등록증에 기재된 10자리 사업자등록번호를 정확히
                            입력해주세요.
                            <br />
                            형식: XXX-XX-XXXXX
                          </p>
                        </div>
                        <BusinessNumberSection
                          sellerInfo={SellerStore?.sellerInfo}
                          onNextStep={handleNextStep}
                          onPreviousStep={handlePreviousStep}
                        />
                      </div>
                    )}
                    {currentStep === 4 && (
                      <div className='w-4/5'>
                        <div className='mb-6 p-4 bg-gray-50 rounded-lg'>
                          <h4 className='font-bold mb-2'>
                            💳 정산계좌 정보 등록
                          </h4>
                          <p className='text-gray-600'>
                            판매대금을 받으실 사업자 명의의 계좌정보를
                            입력해주세요.
                            <br />
                            예금주명은 정확하게 전문으로 입력해주세요.
                            계좌검증은 나이스페이먼츠를 통해 진행됩니다.
                          </p>
                        </div>
                        <BankAccountSection
                          onPreviousStep={handlePreviousStep}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className='flex items-center justify-end p-6 bg-gray-100 border-t border-solid rounded-b border-slate-200'>
                  <button
                    className='px-6 text-lg font-bold uppercase transition-all duration-150 ease-linear outline-none text-neutral-500 background-transparent focus:outline-none'
                    type='button'
                    onClick={() => setShowModal(false)}
                  >
                    닫기
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='fixed inset-0 z-40 bg-black opacity-25' />
        </>
      )}
    </>
  )
})
