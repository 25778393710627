const ListLimitSelector = ({ limit, setLimit, setPage }) => {
  return (
    <>
      <select
        className='border-gray-300 rounded-md shadow-sm select-none focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
        onChange={(e) => {
          if (
            window.confirm(
              '수정 중인 내용이 있을 경우 초기화 될 수 있습니다. 변경하시겠습니까?',
            )
          ) {
            setLimit(Number(e.target.value))
            setPage(1)
          }
        }}
        value={limit}
      >
        {/* <option value={5}>5개씩</option> */}
        {/* <option value={10}>10개씩</option> */}
        <option value={20}>20개씩</option>
        <option value={40}>40개씩</option>
        <option value={60}>60개씩</option>
        <option value={80}>80개씩</option>
        <option value={100}>100개씩</option>
        <option value={200}>200개씩</option>
      </select>
    </>
  )
}
export default ListLimitSelector
