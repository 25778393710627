import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'
import ImageUploading from 'react-images-uploading'
import backendApis from '../utils/backendApis'
import OrderStore from '../stores/OrderStore'
import SellerStore from '../stores/SellerStore'
import AuthStore from '../stores/AuthStore'
import socket from '../utils/socket'
import ItemStore from '../stores/ItemStore'
import Button from '../components/atoms/button'
import { FcOk as OkIcon } from 'react-icons/fc'
import Input from '../components/atoms/input'
import Log from '../utils/log'

const RegisterSellerBasicsScreen = observer(() => {
  const history = useHistory()
  const [description, setDescription] = useState('')
  const [contactNumber, setContactNumber] = useState('')
  const [address, setAddress] = useState('')
  const [mailOrderBusinessNumber, setMailOrderBusinessNumber] = useState('')
  // const [kakaoChannelUrl, setKakaoChannelUrl] = useState('')
  const [businessRegistrationNumber, setBusinessRegistrationNumber] =
    useState('')
  const [defaultShippingCompanyId, setDefaultShippingCompanyId] = useState('')
  const [bankAccountInfo, setBankAccountInfo] = useState({})
  const [receivingAccountNumber, setReceivingAccountNumber] = useState('')
  const [shippingOrderCapa, setShippingOrderCapa] = useState('')
  const [sellerDistributionType, setSellerDistributionType] = useState('')
  const [businessRegistrationNumberImage, setBusinessRegistrationNumberImage] =
    useState('')
  const [croppedMainImagesData, setCroppedMainImagesData] = useState([])
  const [businessStartDate, setBusinessStartDate] = useState()
  const [ceoName, setCeoName] = useState()
  const [categoryId, setCategoryId] = useState('')
  const [urlLink, setUrlLink] = useState('')
  const regex = /[^0-9]/g

  const validateBusinessRegistrationNumber = async (
    businessRegistrationNumber,
    businessStartDate,
    ceoName,
  ) => {
    const check = await backendApis.confirmBusinessRegistrationNumber(
      businessRegistrationNumber.replace(regex, ''),
      businessStartDate,
      ceoName,
    )
    if (check?.status_code === 'OK') {
      if (
        check?.data?.[0]?.valid === '01' &&
        check?.data?.[0]?.status?.b_stt === '계속사업자'
      ) {
        return true
      }
    }
    return false
  }
  useEffect(() => {
    if (ItemStore.categories.length === 0) {
      ItemStore.loadCategories()
    }
  }, [])

  return (
    <>
      <div className='flex flex-col items-center flex-1 w-screen max-w-4xl '>
        <div className='flex flex-col items-center justify-center flex-1 mt-10 '>
          <div className='flex flex-row items-center justify-center flex-1 text-2xl font-bold mb-2'>
            <OkIcon className='mr-2' />
            추가 정보 입력을 부탁드려요!
          </div>
          <div>추가 정보를 입력한 이후 상품 등록이 가능합니다</div>
        </div>
        <div className='flex flex-col items-center justify-center flex-1 w-screen max-w-4xl mt-5'>
          <input
            type='text'
            value={contactNumber}
            placeholder='대표번호: 고객 문의전화를 수신할 번호를 기재해 주세요! (예: 07012345678)(앱 노출 O)'
            className='w-1/2 mb-3'
            onChange={(e) => {
              console.log(`text: ${e.target.value}`)
              setContactNumber(e.target.value)
            }}
          />
          <input
            type='text'
            value={description}
            placeholder='판매처 설명 (예: 맛 좋은 고구마를 합리적인 가격에 판매합니다)'
            className='w-1/2 mb-3'
            onChange={(e) => {
              console.log(`text: ${e.target.value}`)
              setDescription(e.target.value)
            }}
          />
          <input
            type='text'
            value={address}
            placeholder='주소 (예: 서울시 관악구 남부순환로 99, 1801호)'
            className='w-1/2 mb-3'
            onChange={(e) => {
              console.log(`text: ${e.target.value}`)
              setAddress(e.target.value)
            }}
          />
          <select
            placeholder='대표 택배사'
            className='w-1/2 mb-3'
            id='shipping_company'
            name='shipping_company'
            onChange={(e) => {
              const correspondingShippingCompanyInfo =
                OrderStore.shippingCompaniesInfo.find(
                  (shippingCompanyInfo) =>
                    shippingCompanyInfo.shippingCompanyCode === e.target.value,
                )
              if (correspondingShippingCompanyInfo) {
                console.log(
                  `shipping info: ${JSON.stringify({
                    ...correspondingShippingCompanyInfo,
                  })}`,
                )
                // setShippingInfo({
                //   ...shippingInfo,
                //   ...correspondingShippingCompanyInfo,
                // })
                setDefaultShippingCompanyId(
                  correspondingShippingCompanyInfo._id,
                )
              } else {
                setDefaultShippingCompanyId('')
              }
            }}
            value={
              OrderStore.shippingCompaniesInfo.find(
                (shippingCompany) =>
                  shippingCompany._id === defaultShippingCompanyId,
              )?.shippingCompanyCode || ''
            }
          >
            <option value=''>대표 택배사</option>
            {/* <option value=''>===</option>
          <option value='2021'>2021</option>
          <option value='2020'>2020</option>
          <option value='2019'>2019</option> */}
            {OrderStore.shippingCompaniesInfo.map((shippingCompanyInfo) => (
              <option
                key={shippingCompanyInfo.shippingCompanyCode}
                value={shippingCompanyInfo.shippingCompanyCode}
                //   onChange={(e) => {

                //   }}
              >
                {shippingCompanyInfo.shippingCompanyName}
              </option>
            ))}
          </select>
          {/* <input
          type='email'
          value={email}
          placeholder='  판매처 이메일'
          style={{ width: 400, height: 30, marginBottom: 10 }}
          onChange={(e) => {
            
            setEmail(e.target.value)
          }}
        /> */}
          <input
            type='text'
            placeholder='예금주명'
            className='w-1/2 mb-3'
            value={bankAccountInfo.depositorName || ''}
            onChange={(e) => {
              setBankAccountInfo({
                ...bankAccountInfo,
                depositorName: e.target.value,
              })
            }}
          />
          <select
            placeholder='입금 은행'
            className='w-1/2 mb-3'
            value={bankAccountInfo.bankCode}
            onChange={(e) => {
              const correspondingBankInfo = SellerStore.banksInfo.find(
                (bankInfo) => bankInfo.bankCode === e.target.value,
              )
              if (correspondingBankInfo) {
                setBankAccountInfo({
                  ...bankAccountInfo,
                  ...correspondingBankInfo,
                })
              } else {
                setBankAccountInfo({})
              }
            }}
          >
            <option value=''>입금 은행</option>
            {SellerStore.banksInfo.map((bankInfo) => (
              <option key={bankInfo.bankCode} value={bankInfo.bankCode}>
                {bankInfo.bankName}
              </option>
            ))}
          </select>

          <input
            type='text'
            placeholder='입금 계좌번호'
            className='w-1/2 mb-3'
            onChange={(e) => {
              setReceivingAccountNumber(e.target.value)
            }}
            value={receivingAccountNumber}
          />
          <input
            type='text'
            value={mailOrderBusinessNumber}
            placeholder='통신판매업 신고번호 (예: 2023-서울강북-9999)'
            className='w-1/2 mb-3'
            onChange={(e) => {
              setMailOrderBusinessNumber(e.target.value)
            }}
          />
          <input
            type='text'
            value={businessRegistrationNumber}
            placeholder='사업자 등록번호 (예: 123-45-67890)'
            className='w-1/2 mb-3'
            onChange={(e) => {
              setBusinessRegistrationNumber(e.target.value)
            }}
            maxLength='12'
          />
          <input
            type='text'
            onChange={(e) => {
              setBusinessStartDate(e.target.value)
            }}
            className='w-1/2 mb-3'
            placeholder='개업일자 (예. 20110220)'
            maxLength='8'
          />
          <input
            type='text'
            onChange={(e) => {
              setCeoName(e.target.value)
            }}
            className='w-1/2 mb-3'
            placeholder='대표자명'
          />
          <ImageUploading
            multiple
            value={businessRegistrationNumberImage}
            onChange={(imageList, addUpdateIndex) => {
              setBusinessRegistrationNumberImage(imageList)
              const croppedMainImagesDataTemp = JSON.parse(
                JSON.stringify(croppedMainImagesData),
              )
              if (addUpdateIndex > croppedMainImagesData.length) {
                croppedMainImagesDataTemp.push({ sourceUri: '' })
                setCroppedMainImagesData(croppedMainImagesDataTemp)
              } else {
                croppedMainImagesDataTemp[addUpdateIndex] = {
                  sourceUri: '',
                }
                setCroppedMainImagesData(croppedMainImagesDataTemp)
              }
            }}
            maxFileSize={5e6}
            acceptType={['jpg', 'png', 'jpeg']}
            resolutionType='more'
            resolutionWidth={800}
            resolutionHeight={800}
            onError={(e) => {
              if (e.maxFileSize) {
                alert('이미지 파일 용량을 확인해주세요.(개당 최대 5MB)')
              } else if (e.acceptType) {
                alert(
                  '지원 가능한 이미지 파일 형식을 확인해주세요.(jpg/ jpeg/ png)',
                )
              } else if (e.resolution) {
                alert(
                  '선명한 이미지를 올려주세요.\n(최소 800px*800px 이상, 권장 이미지 1200px*1200px)',
                )
              }
            }}
            allowNonImageType='false'
            maxNumber={10}
            dataURLKey='sourceUri'
          >
            {({
              imageList,
              onImageUpload,
              onImageUpdate,
              onImageRemove,
              dragProps,
            }) => (
              <div {...dragProps}>
                <Button
                  type='button'
                  onClick={onImageUpload}
                  appearance='neutralSub'
                >
                  사업자 등록증 등록
                </Button>
                <div style={{ marginTop: 8 }}>
                  <div className='flex flex-row overflow-x-auto '>
                    {imageList.map((image, index) => (
                      <div key={JSON.stringify(image)}>
                        <div className='flex mr-10 w-80 h-80'>
                          <img
                            alt='img'
                            src={image.sourceUri}
                            className='object-cover object-center border-2 shadow-lg h-80 w-80 border-sub-300'
                          />
                        </div>

                        <div>
                          <div style={{ margin: 8 }}>
                            <p>
                              1. 반드시 해상도를 800px*800px 이상으로
                              맞춰야합니다.
                            </p>
                            <p>2. 파일 용량은 최대 5mb 입니다.</p>
                            <p>
                              3. 파일 확장자는 jpg, jpeg, png 만 가능합니다.
                            </p>
                          </div>
                          <button
                            className='px-2 py-2 my-2 font-bold text-white bg-red-500 rounded-lg hover:bg-black '
                            type='button'
                            onClick={() => onImageUpdate(index)}
                          >
                            사진변경
                          </button>
                          <button
                            className='px-2 py-2 mx-10 my-2 font-bold text-white bg-red-500 rounded-lg hover:bg-black '
                            type='button'
                            onClick={() => {
                              onImageRemove(index)
                            }}
                          >
                            삭제
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </ImageUploading>
          <input
            type='number'
            value={shippingOrderCapa}
            placeholder='하루 평균 일 택배건수 (예: 500)'
            className='w-1/2 mt-3 mb-3'
            onChange={(e) => {
              console.log(`text: ${e.target.value}`)
              setShippingOrderCapa(parseInt(e.target.value, 10) || 0)
            }}
          />
          <select
            placeholder='셀러 유형'
            className='w-1/2 mb-3'
            value={sellerDistributionType}
            onChange={(e) => {
              setSellerDistributionType(e.target.value)
            }}
          >
            <option value=''>셀러 유형을 선택해주세요</option>
            <option value='producer'>생산</option>
            <option value='distribution'>유통</option>
          </select>
          <select
            className='w-1/2 mb-3'
            value={categoryId}
            id='large_category'
            name='large_category'
            onChange={(e) => {
              setCategoryId(parseInt(e.target.value, 10))
            }}
          >
            <option value=''>대표 카테고리를 선택해주세요</option>
            {ItemStore.categories.map((category) => (
              <option key={category._id} value={category._id}>
                {category.largeCategoryNames.kr}
              </option>
            ))}
          </select>
          <Input
            className='w-1/2 mb-3'
            type='text'
            placeholder='대표 판매 채널의 URL을 입력해주세요'
            value={urlLink}
            onChange={(e) => {
              setUrlLink(e.target.value)
            }}
          />
          <div className='mb-5 mt-2'>
            * 가입하신 이메일로 상품 등록 방법 및 매출 활성화 가이드를
            보내드렸으니 확인해주세요!
          </div>
          <Button
            appearance='positive'
            size='xl'
            type='button'
            onClick={async () => {
              if (
                // !sellerName ||
                // !password ||
                // !sellerDisplayName ||
                // !representorName ||
                !description ||
                // !phoneNumber ||
                !contactNumber ||
                // !email
                !address ||
                !mailOrderBusinessNumber ||
                !businessRegistrationNumber ||
                // !kakaoChannelUrl ||
                !defaultShippingCompanyId ||
                !bankAccountInfo.bankCode ||
                !bankAccountInfo.depositorName ||
                !receivingAccountNumber ||
                !shippingOrderCapa ||
                !sellerDistributionType ||
                !businessRegistrationNumberImage ||
                categoryId === null ||
                urlLink.length === 0
              ) {
                console.log(`seller information is not complete!`)
                alert(`입력하지 않은 정보가 없는지 확인해주세요`)
                return
              }
              if (businessRegistrationNumber.length !== 12) {
                alert('사업자등록번호를 정확히 입력해주세요.')
                return
              }
              if (!businessRegistrationNumber.includes('-')) {
                alert('사업자등록번호에 -를 포함해주세요.')
                return
              }
              const registerCheck = await backendApis.checkRegisteredBizNum(
                businessRegistrationNumber,
              )
              if (registerCheck?.status === 200) {
                if (registerCheck?.data?.isRegistered === true) {
                  alert(
                    '이미 등록된 사업자 번호입니다. 하나의 사업자 번호로 한 번만 등록이 가능합니다.',
                  )
                  SellerStore.setIsLoading(false)
                  return
                }
              }
              const bizNumberCheck = await validateBusinessRegistrationNumber(
                businessRegistrationNumber,
                businessStartDate,
                ceoName,
              )
              if (bizNumberCheck === false) {
                alert(
                  '사업자등록번호 검증에 실패했습니다. 정확한 정보를 입력해주세요.',
                )
                return
              }
              // if (
              //   validateBusinessRegistrationNumber(businessRegistrationNumber) ===
              //   false
              // ) {
              //   alert(`올바른 v를 입력해주세요.`)
              //   return
              // }
              // if (
              //   !kakaoChannelUrl.includes('pf.kakao.com') &&
              //   !kakaoChannelUrl.includes('open.kakao.com')
              // ) {
              //   alert('올바른 형태의 카카오 채널 오픈채팅방 링크를 입력해주세요.')
              //   return
              // }
              // alert(
              //   `추가 정보 입력이 완료되었습니다. 이제 상품 등록을 진행해주세요`,
              // )

              if (SellerStore.isLoading) {
                return
              }
              console.log(`seller info complete`)
              SellerStore.setIsLoading(true)
              // if (SellerStore.sellerInfo.isTestingSeller) {
              //   SellerStore.sellerInfo.kakaoChannelUrl =
              //     'https://open.kakao.com/o/s7IUusWd'
              // }

              const result = await backendApis.registerSellerDetail(
                SellerStore.sellerInfo.sellerName,
                SellerStore.sellerInfo.sellerDisplayName,
                description,
                SellerStore.sellerInfo.phoneNumber,
                contactNumber,
                SellerStore.sellerInfo.email,
                '82',
                address,
                mailOrderBusinessNumber,
                businessRegistrationNumber,
                // kakaoChannelUrl,
                defaultShippingCompanyId,
                {
                  ...bankAccountInfo,
                  bankAccountNumber: receivingAccountNumber.replace(
                    /[-\s]/g,
                    '',
                  ),
                },
                SellerStore.sellerInfo.representorName,
                'inboundSeller',
                shippingOrderCapa,
                sellerDistributionType,
                categoryId,
                urlLink,
                //   description,
                // phoneNumber?.replace(/-/g, ''),
                //   contactNumber,
                // email,
                // address,
                //   mailOrderBusinessNumber,
                //   businessRegistrationNumber,
                //   defaultShippingCompanyId,
                //   {
                //     ...bankAccountInfo,

                //     bankAccountNumber: receivingAccountNumber.replace(/-/g, ''),
                //   },
              )

              if (result?.status === 200) {
                await backendApis.uploadBusinessNumberImage(
                  businessRegistrationNumberImage,
                  SellerStore.sellerInfo._id,
                )
                console.log(`register detail result: ${JSON.stringify(result)}`)
                SellerStore.setIsLoading(false)
                setDescription('')
                setContactNumber('')
                setAddress('')
                setMailOrderBusinessNumber('')
                // setKakaoChannelUrl('')
                setBusinessRegistrationNumber('')
                setDefaultShippingCompanyId('')
                setBankAccountInfo('')
                setReceivingAccountNumber('')
                setCategoryId('')
                setUrlLink('')
                AuthStore.setToken(result.data.token)
                localStorage.setItem('@alwayz@seller@token@', result.data.token)
                const loadSellerResult = await SellerStore.loadSellerInfo()
                if (loadSellerResult) {
                  history.replace('/')
                } else {
                  alert('입력하지 않은 정보가 없는지 확인해주세요')
                }
              }

              SellerStore.setIsLoading(false)
            }}
          >
            추가 정보 제출하기
          </Button>
        </div>
        <div className='flex items-center justify-center mt-5'>
          <Button
            size='lg'
            onClick={(e) => {
              e.preventDefault()
              window.open('http://pf.kakao.com/_vxefts', '_blank')
              if (SellerStore.loadSellerInfo())
                Log.event(
                  'SellerSupportScreenButton',
                  'SellerSupportScreenButton',
                  {
                    click: 'SellerSupportScreenButton',
                  },
                )
            }}
          >
            카카오톡 1:1 문의하기 →
          </Button>
        </div>
      </div>
    </>
  )
})

export default RegisterSellerBasicsScreen
