const {
  REACT_APP_NODE_ENV_USER,
  REACT_APP_BACKEND_URL_DEVELOPMENT,
  REACT_APP_BACKEND_URL_PRODUCTION,
  REACT_APP_SENTRY_DSN,
} = process.env

const configs = {
  nodeEnv: null,
  backendUrl: null,
  sentryDSN: null,
}
configs.sentryDSN = REACT_APP_SENTRY_DSN

//
if (REACT_APP_NODE_ENV_USER === 'development') {
  configs.nodeEnv = 'development'
  configs.backendUrl = REACT_APP_BACKEND_URL_DEVELOPMENT
} else if (REACT_APP_NODE_ENV_USER === 'production') {
  configs.nodeEnv = 'production'
  configs.backendUrl = REACT_APP_BACKEND_URL_PRODUCTION
}

export default configs
