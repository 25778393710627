import React, { useState, useEffect } from 'react'
import backendApis from '../utils/backendApis'
import SellerStore from '../stores/SellerStore'
import Button from './atoms/button'
import Selector from './atoms/selector'
import ListLimitSelector from './atoms/listLimitSelector'
import Pagination from './molecules/pagination'
import resize from '../utils/resize'
import getDayOfWeek from '../utils/getDayofWeek'
import TodayDepartureRegisterModal from './molecules/TodayDepartureRegisterModal'
import { FcSearch } from 'react-icons/fc'

const dateTimeSortingTypeList = [
  { name: '생성일시 최신 순', value: 'newestCreatedAt' },
  { name: '생성일시 오래된 순', value: 'oldestCreatedAt' },
  { name: '수정일시 최신 순', value: 'newestUpdatedAt' },
  { name: '수정일시 오래된 순', value: 'oldestUpdatedAt' },
]

const IrregularClosedDayComponent = ({ irregularClosedDay }) => {
  const date = new Date(irregularClosedDay)
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()

  // YYYY-MM-DD as white text in grey container
  return (
    <div className='bg-slate-400 text-white text-sm rounded-sm px-1 my-1'>
      {year}-{month}-{day}
    </div>
  )
}

const TodayDepartureRegisteredHeader = () => {
  return (
    <>
      <div className='flex flex-row items-center justify-center flex-1 min-w-[15%] max-w-[15%]'>
        상품 이미지
      </div>
      <div className='flex flex-row items-center justify-center flex-1 min-w-[15%] max-w-[15%]'>
        상품명
      </div>
      <div className='flex flex-row items-center justify-center flex-1 min-w-[25%] max-w-[25%]'>
        상품ID
      </div>
      <div className='flex flex-row items-center justify-center flex-1 min-w-[25%] max-w-[25%]'>
        기준시간
      </div>
      <div className='flex flex-row items-center justify-center flex-1 min-w-[10%] max-w-[10%]'>
        휴무요일
      </div>
      <div className='flex flex-row items-center justify-center flex-1 min-w-[10%] max-w-[10%]'>
        휴무일
      </div>
    </>
  )
}

const TodayDepartureRegisteredItemComponent = ({
  itemInfo,
  checkedItemIds,
  setCheckedItemIds,
}) => {
  return (
    <div className='flex flex-row items-start justify-start flex-1 px-8 py-4 mb-5 font-semibold text-sm bg-white divide-x-2 rounded-lg shadow-md select-none text-neutral-500'>
      <div className='flex flex-row flex-1 items-center justify-start min-w-[3%] max-w-[3%]'>
        <input
          type='checkbox'
          checked={checkedItemIds?.includes(itemInfo?._id)}
          onChange={(e) => {
            if (e.target.checked) {
              setCheckedItemIds([
                ...checkedItemIds?.filter((itemId) => itemId !== itemInfo?._id),
                itemInfo?._id,
              ])
            } else {
              setCheckedItemIds(
                checkedItemIds?.filter((itemId) => itemId !== itemInfo?._id),
              )
            }
          }}
        />
      </div>
      <div className='flex flex-row items-center justify-center flex-1 min-w-[15%] max-w-[15%]'>
        <img
          alt='mainImage'
          src={resize(itemInfo?.mainImageUris[0])}
          className='w-32 h-32 rounded-md'
        />
      </div>
      <div className='flex flex-row items-center justify-center flex-1 min-w-[15%] max-w-[15%]  h-32'>
        {itemInfo?.itemTitle}
      </div>
      <div className='flex flex-row items-center justify-center flex-1 min-w-[25%] max-w-[25%] h-32'>
        {itemInfo?._id}
      </div>
      <div className='flex flex-row items-center justify-center flex-1 min-w-[25%] max-w-[25%]  h-32'>
        평일 {itemInfo?.shippingInfo.todayDeparture.orderFinishDeadline.hour}시{' '}
        {itemInfo?.shippingInfo.todayDeparture.orderFinishDeadline.minute}분까지
        결제완료된 주문
      </div>
      <div className='flex flex-row items-center justify-center flex-1 min-w-[10%] max-w-[10%] h-32'>
        {itemInfo?.shippingInfo.todayDeparture.regularClosedDays != null
          ? getDayOfWeek(
              itemInfo?.shippingInfo.todayDeparture.regularClosedDays,
            )
          : '없음'}
      </div>
      <div className='flex flex-col items-center justify-center flex-1 min-w-[10%] max-w-[10%]  h-32'>
        {itemInfo?.shippingInfo.todayDeparture.irregularClosedDays.length > 0
          ? itemInfo?.shippingInfo.todayDeparture.irregularClosedDays.map(
              (irregularClosedDay, idx) => (
                <IrregularClosedDayComponent
                  key={irregularClosedDay.toString() + idx.toString()}
                  irregularClosedDay={irregularClosedDay}
                />
              ),
            )
          : '없음'}
      </div>
    </div>
  )
}

const TodayDepartureNotRegisteredHeader = () => {
  return (
    <>
      <div className='flex flex-row items-center justify-center flex-1 min-w-[25%] max-w-[25%]'>
        상품 이미지
      </div>
      <div className='flex flex-row items-center justify-center flex-1 min-w-[30%] max-w-[30%]'>
        상품명
      </div>
      <div className='flex flex-row items-center justify-center flex-1 min-w-[30%] max-w-[30%]'>
        상품ID
      </div>
    </>
  )
}

const TodayDepartureNotRegisteredItemComponent = ({
  itemInfo,
  checkedItemIds,
  setCheckedItemIds,
}) => {
  return (
    <div className='flex flex-row items-start justify-start flex-1 px-8 py-4 mb-5 font-semibold text-sm bg-white divide-x-2 rounded-lg shadow-md select-none text-neutral-500'>
      <div className='flex flex-row flex-1 items-center justify-start min-w-[3%] max-w-[3%]'>
        <input
          type='checkbox'
          checked={checkedItemIds?.includes(itemInfo?._id)}
          onChange={(e) => {
            if (e.target.checked) {
              setCheckedItemIds([
                ...checkedItemIds?.filter((itemId) => itemId !== itemInfo?._id),
                itemInfo?._id,
              ])
            } else {
              setCheckedItemIds(
                checkedItemIds?.filter((itemId) => itemId !== itemInfo?._id),
              )
            }
          }}
        />
      </div>
      <div className='flex flex-row items-center justify-center flex-1 min-w-[25%] max-w-[25%]'>
        <img
          alt='mainImage'
          src={resize(itemInfo?.mainImageUris[0])}
          className='w-32 h-32 mb-2 mr-5 rounded-md'
        />
      </div>
      <div className='flex flex-row items-center justify-center flex-1 min-w-[30%] max-w-[30%]  h-32'>
        {itemInfo?.itemTitle}
      </div>
      <div className='flex flex-row items-center justify-center flex-1 min-w-[30%] max-w-[30%] h-32'>
        {itemInfo?._id}
      </div>
    </div>
  )
}

const TodayDepartureItems = ({ isRegistered }) => {
  const [itemsInfo, setItemsInfo] = useState([])
  const [itemsCount, setItemsCount] = useState(0)
  const [isAllCheckBoxChecked, setIsAllCheckBoxChecked] = useState(false)
  const [limit, setLimit] = useState(10)
  const [dateTimeSortingType, setDateTimeSortingType] = useState(
    isRegistered ? 'newestUpdatedAt' : 'newestCreatedAt',
  )
  const [page, setPage] = useState(1)
  const [query, setQuery] = useState('')

  const [checkedItemIds, setCheckedItemIds] = useState([])
  const [
    isTodayDepartureRegisterModalOpen,
    setIsTodayDepartureRegisterModalOpen,
  ] = useState(false)
  const [isAllItemsUpdate, setIsAllItemsUpdate] = useState(false)

  const fetchSegmentedItemsInfo = async () => {
    const itemCondition = {
      'shippingInfo.todayDeparture.isTodayDepartureItem': isRegistered
        ? true
        : {
            $in: [null, false],
          },
    }

    let itemSortCondition = null
    switch (dateTimeSortingType) {
      case 'oldestCreatedAt':
        itemSortCondition = { createdAt: 1 }
        break
      case 'newestUpdatedAt':
        itemSortCondition = { updatedAt: -1 }
        break
      case 'oldestUpdatedAt':
        itemSortCondition = { updatedAt: 1 }
        break
      default:
        itemSortCondition = { createdAt: -1 }
    }

    const itemsCountResult = await backendApis.getSellerItemsCountByCondition({
      type: 'item',
      itemCondition,
      itemSortCondition,
      query,
    })
    if (itemsCountResult?.status === 200) {
      setItemsCount(itemsCountResult?.data)
    } else {
      setItemsCount(0)
      setPage(1)
      setItemsInfo([])
      window.alert('상품 목록을 불러오는 중 문제가 발생했습니다.')
      SellerStore.setIsLoading(false)
    }
    const result = await backendApis.getSellerSegementedItemsInfoByCondition(
      {
        type: 'item',
        itemCondition,
        itemSortCondition,
        query,
      },
      page,
      limit,
    )
    if (result?.status === 2000) {
      setItemsInfo(result?.data?.itemsInfo)
    } else if (result?.status === 2001) {
      const maxPage = Math.ceil(result.data?.itemsCount / limit) || 1
      const retryResult =
        await backendApis.getSellerSegementedItemsInfoByCondition(
          {
            type: 'item',
            itemCondition,
            itemSortCondition,
            query,
          },
          maxPage,
          limit,
        )
      if (retryResult?.status === 2000) {
        setPage(maxPage)
        setItemsInfo(retryResult?.data?.itemsInfo)
      } else {
        setPage(1)
        setItemsInfo([])
        if (retryResult?.status !== 2001) {
          window.alert('상품 목록을 불러오는 중 문제가 발생했습니다.')
        }
      }
    }
  }

  useEffect(() => {
    fetchSegmentedItemsInfo()
    setCheckedItemIds([])
    setIsAllCheckBoxChecked(false)
  }, [page, dateTimeSortingType, limit])

  const unRegisterTodayDepartureInfo = async (isDeleteAll) => {
    const result = isDeleteAll
      ? await backendApis.unRegisterTodayDepartureInfoAllItems({
          itemIds: checkedItemIds,
        })
      : await backendApis.unRegisterTodayDepartureInfo({
          itemIds: checkedItemIds,
        })
    if (result?.status === 200) {
      window.alert(
        '오늘출발 등록이 취소되었습니다. 이미 주문완료된 건에 대해서는 기존 오늘출발 조건이 적용됩니다!',
      )
      window.location.reload()
    } else {
      window.alert('오늘출발 등록을 취소하는 중 문제가 발생했습니다.')
    }
  }

  return (
    <>
      <div className='px-10 py-3 my-10 shadow-md bg-slate-50 rounded-xl'>
        <div className='my-5 font-bold text-lg'>
          {isRegistered ? '등록현황' : '상품 등록하기'}
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            fetchSegmentedItemsInfo()
            setCheckedItemIds([])
            setIsAllCheckBoxChecked(false)
          }}
        >
          <div className='flex flex-row items-center justify-end flex-1 mb-5'>
            <input
              type='text'
              className='w-96 h-10 px-3 rounded-md'
              onChange={(e) => {
                setQuery(e.target.value)
              }}
            />
            <Button
              appearance='transparent'
              onClick={() => {
                fetchSegmentedItemsInfo()
                setCheckedItemIds([])
                setIsAllCheckBoxChecked(false)
              }}
            >
              <FcSearch />
            </Button>
          </div>
        </form>
        <div className='flex flex-row items-center justify-between flex-1 mt-10 mb-3 '>
          <div className='mb-2'>
            검색결과 총 {itemsCount}개 <span className='mx-2' />
            {isRegistered ? (
              <>
                <Button
                  appearance='positiveSub'
                  onClick={() => {
                    if (checkedItemIds?.length === 0) {
                      window.alert('수정할 상품을 선택해주세요.')
                    } else {
                      setIsAllItemsUpdate(false)
                      setIsTodayDepartureRegisterModalOpen(true)
                    }
                  }}
                >
                  선택항목 수정하기
                </Button>
                <span className='mx-2' />
                <Button
                  appearance='red'
                  onClick={() => {
                    if (checkedItemIds?.length === 0) {
                      window.alert('삭제할 상품을 선택해주세요.')
                    } else {
                      unRegisterTodayDepartureInfo(false)
                    }
                  }}
                >
                  선택항목 삭제하기
                </Button>
                <span className='mx-2' />
                <Button
                  appearance='blue'
                  onClick={() => {
                    setIsAllItemsUpdate(true)
                    setIsTodayDepartureRegisterModalOpen(true)
                  }}
                >
                  전체항목 수정하기
                </Button>
                <span className='mx-2' />
                <Button
                  appearance='red'
                  onClick={() => {
                    unRegisterTodayDepartureInfo(true)
                  }}
                >
                  전체항목 삭제하기
                </Button>
              </>
            ) : (
              <>
                <Button
                  appearance='positiveSub'
                  onClick={() => {
                    if (checkedItemIds?.length === 0) {
                      window.alert('등록할 상품을 선택해주세요.')
                    } else {
                      setIsAllItemsUpdate(false)
                      setIsTodayDepartureRegisterModalOpen(true)
                    }
                  }}
                >
                  선택된 상품 등록하기
                </Button>
                <span className='mx-2' />
                <Button
                  appearance='blue'
                  onClick={() => {
                    setIsAllItemsUpdate(true)
                    setIsTodayDepartureRegisterModalOpen(true)
                  }}
                >
                  전체 상품 등록하기
                </Button>
              </>
            )}
          </div>
          {itemsInfo?.length > 0 && (
            <div className='flex flex-row items-baseline space-x-3'>
              <div>
                <Selector
                  optionNameValueList={dateTimeSortingTypeList}
                  selectedOption={dateTimeSortingType}
                  setOption={setDateTimeSortingType}
                />
              </div>
              <div>
                <ListLimitSelector limit={limit} setLimit={setLimit} />
              </div>
            </div>
          )}
        </div>
        <div className='flex flex-row items-center justify-start flex-1 px-8 py-4 mb-5 font-bold bg-white divide-x-2 rounded-lg shadow-md select-none text-neutral-500'>
          <div className='flex flex-row flex-1 items-center justify-start min-w-[3%] max-w-[3%]'>
            <input
              type='checkbox'
              checked={isAllCheckBoxChecked}
              onChange={(e) => {
                if (e.target.checked) {
                  setCheckedItemIds(itemsInfo?.map((itemInfo) => itemInfo?._id))
                  setIsAllCheckBoxChecked(true)
                } else {
                  setCheckedItemIds([])
                  setIsAllCheckBoxChecked(false)
                }
              }}
            />
          </div>
          {isRegistered ? (
            <TodayDepartureRegisteredHeader />
          ) : (
            <TodayDepartureNotRegisteredHeader />
          )}
        </div>
        {itemsInfo.map((itemInfo, idx) => (
          <div key={itemInfo._id.toString() + idx.toString()}>
            {isRegistered ? (
              <TodayDepartureRegisteredItemComponent
                itemInfo={itemInfo}
                checkedItemIds={checkedItemIds}
                setCheckedItemIds={setCheckedItemIds}
              />
            ) : (
              <TodayDepartureNotRegisteredItemComponent
                itemInfo={itemInfo}
                checkedItemIds={checkedItemIds}
                setCheckedItemIds={setCheckedItemIds}
              />
            )}
          </div>
        ))}
        <div>
          <Pagination
            total={itemsCount}
            limit={limit}
            page={page}
            setPage={setPage}
            size='lg'
          />
        </div>
      </div>
      <TodayDepartureRegisterModal
        showModal={isTodayDepartureRegisterModalOpen}
        setShowModal={setIsTodayDepartureRegisterModalOpen}
        checkedItemIds={checkedItemIds}
        isRegistered={isRegistered}
        isAllItemsUpdate={isAllItemsUpdate}
      />
    </>
  )
}
export default TodayDepartureItems
