import ImageUploading from 'react-images-uploading'
import backendApis from '../../utils/backendApis'
import { useEffect, useState } from 'react'
import validateBusinessImage from '../../utils/businessRegistrationUtils/businessImageValidation'

const BusinessImageSection = ({ sellerInfo, onNextStep, onPreviousStep }) => {
  const [businessImage, setBusinessImage] = useState(null)
  const [isValidBusinessImage, setIsValidBusinessImage] = useState(null)
  const [isReLoad, setIsReLoad] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isValidating, setIsValidating] = useState(false)
  const [validationComplete, setValidationComplete] = useState(false)

  useEffect(async () => {
    const sellerBusinessInfo =
      await backendApis.loadSellerBusinessRegistrationInfo()
    setBusinessImage(sellerBusinessInfo?.data?.businessRegistrationNumberImage)
    setIsValidBusinessImage(
      sellerBusinessInfo?.data?.isValidBusinessRegistrationNumberImage,
    )
    if (
      sellerBusinessInfo?.data?.isValidBusinessRegistrationNumberImage ===
      'approved'
    ) {
      setValidationComplete(true)
    }
  }, [isReLoad])

  const handleImageUpload = async (imageList) => {
    setIsLoading(true)
    try {
      const response =
        await backendApis.registerBusinessRegistrationNumberImage(
          imageList,
          sellerInfo._id,
        )
      if (response?.status === 200) {
        setBusinessImage(imageList)
        alert('이미지 업로드가 완료되었습니다.')
        setIsReLoad((prev) => !prev)
      } else {
        alert('이미지 업로드에 실패했습니다. 다시 시도해주세요.')
        setIsReLoad((prev) => !prev)
      }
    } catch (error) {
      console.error('Image upload error:', error)
      alert('이미지 업로드 중 오류가 발생했습니다.')
      setIsReLoad((prev) => !prev)
    } finally {
      setIsLoading(false)
    }
  }

  const handleStartValidation = async () => {
    setIsValidating(true)
    try {
      const result = await validateBusinessImage(sellerInfo._id, businessImage)
      if (result.status === 200) {
        alert('사업자등록증 검증이 완료되었습니다.')
        setValidationComplete(true)
        setIsReLoad((prev) => !prev)
      } else {
        alert(result.message)
      }
    } catch (error) {
      console.error('Validation error:', error)
      alert(result.message)
    } finally {
      setIsValidating(false)
    }
  }

  const handleChangeImage = async () => {
    const resetResult = await backendApis.resetBusinessRegistrationNumberImage(
      sellerInfo._id,
    )
    if (resetResult?.status === 200) {
      setIsReLoad((prev) => !prev)
    } else {
      alert('이미지 변경에 실패했습니다. 다시 시도해주세요.')
    }
  }

  const Loader = () => (
    <div className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50'>
      <div className='animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-white'></div>
    </div>
  )

  return (
    <div className='p-5'>
      {isLoading && <Loader />}
      <div className='flex flex-col space-y-4'>
        <div className='border rounded-lg p-6'>
          {!businessImage ? (
            <div className='flex flex-col items-center space-y-4'>
              <div className='text-gray-400 mb-4'>
                <svg
                  className='w-16 h-16'
                  fill='none'
                  stroke='currentColor'
                  viewBox='0 0 24 24'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    d='M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z'
                  />
                </svg>
              </div>
              <ImageUploading
                multiple={false}
                value={[]}
                onChange={(imageList) => {
                  const isConfirmed =
                    confirm('사업자등록증을 업로드하시겠습니까?')
                  if (isConfirmed) {
                    handleImageUpload(imageList)
                  }
                }}
                maxFileSize={5e6}
                acceptType={['jpg', 'png', 'jpeg']}
                resolutionType='more'
                resolutionWidth={800}
                resolutionHeight={800}
                onError={(e) => {
                  if (e.maxFileSize) {
                    alert('이미지 파일 용량을 확인해주세요.(개당 최대 5MB)')
                  } else if (e.acceptType) {
                    alert(
                      '지원 가능한 이미지 파일 형식을 확인해주세요.(jpg/jpeg/png)',
                    )
                  } else if (e.resolution) {
                    alert(
                      '선명한 이미지를 올려주세요.\n(최소 800px*800px 이상)',
                    )
                  }
                }}
                allowNonImageType={false}
                maxNumber={1}
                dataURLKey='sourceUri'
              >
                {({ onImageUpload }) => (
                  <button
                    type='button'
                    onClick={onImageUpload}
                    disabled={isLoading}
                    className={`px-6 py-3 bg-blue-500 text-white rounded-md 
                      transition-colors duration-200 flex items-center space-x-2
                      ${
                        isLoading
                          ? 'opacity-50 cursor-not-allowed'
                          : 'hover:bg-blue-600'
                      }`}
                  >
                    <svg
                      className='w-5 h-5'
                      fill='none'
                      stroke='currentColor'
                      viewBox='0 0 24 24'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth='2'
                        d='M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12'
                      />
                    </svg>
                    <span>
                      {isLoading ? '업로드 중...' : '사업자등록증 업로드'}
                    </span>
                  </button>
                )}
              </ImageUploading>
              <p className='text-sm text-gray-500 text-center'>
                JPG, JPEG, PNG 파일 (최대 5MB)
                <br />
                최소 해상도 800x800px
              </p>
            </div>
          ) : (
            <div className='flex flex-col items-center space-y-4'>
              <div className='relative w-full max-w-md'>
                <img
                  src={businessImage}
                  alt='사업자등록증'
                  className='w-full h-auto rounded-lg shadow-md'
                />
                <div className='flex flex-col space-y-2 mt-4'>
                  <button
                    type='button'
                    onClick={handleChangeImage}
                    disabled={validationComplete}
                    className={`px-4 py-2 bg-gray-500 text-white rounded-md
                      transition-colors duration-200 flex items-center justify-center space-x-2
                      ${
                        validationComplete
                          ? 'opacity-50 cursor-not-allowed'
                          : 'hover:bg-gray-600'
                      }`}
                  >
                    <svg
                      className='w-4 h-4'
                      fill='none'
                      stroke='currentColor'
                      viewBox='0 0 24 24'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth='2'
                        d='M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12'
                      />
                    </svg>
                    <span>이미지 변경</span>
                  </button>

                  {isValidBusinessImage === 'pending' &&
                    !validationComplete && (
                      <button
                        type='button'
                        onClick={handleStartValidation}
                        disabled={isValidating}
                        className={`px-6 py-3 bg-green-500 text-white rounded-md 
                        transition-colors duration-200 w-full
                        ${
                          isValidating
                            ? 'opacity-50 cursor-not-allowed'
                            : 'hover:bg-green-600'
                        }`}
                      >
                        {isValidating ? '검증 중...' : '검증 시작하기'}
                      </button>
                    )}

                  {validationComplete && (
                    <>
                      <div className='text-green-600 font-semibold text-center py-2 bg-green-50 rounded-md'>
                        ✓ 사업자등록증 검증 완료
                      </div>
                      <button
                        type='button'
                        onClick={onNextStep}
                        className='px-6 py-3 bg-blue-600 text-white rounded-md 
                          transition-colors duration-200 w-full hover:bg-blue-700
                          font-semibold text-lg shadow-md'
                      >
                        다음 단계로 →
                      </button>
                      <button
                        type='button'
                        onClick={onPreviousStep}
                        className='px-6 py-3 bg-gray-600 text-white rounded-md 
                          transition-colors duration-200 w-full hover:bg-gray-700
                          font-semibold text-lg shadow-md'
                      >
                        이전 단계로
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default BusinessImageSection
