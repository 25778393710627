import backendApis from '../backendApis'

const validateBusinessNumber = async (sellerId) => {
  function parseSellerName(sellerName) {
    // 대표자가 여러명인 경우: "홍길동외 2명" -> "홍길동"
    if (sellerName.includes('외')) {
      return sellerName.split('외')[0]
    }

    // 대표자가 외국인인 경우: "Apple House(애플 하우스)" -> "Apple House"
    if (sellerName.includes('(')) {
      return sellerName.split('(')[0].trim()
    }

    // 위의 조건에 해당하지 않는 경우는 그대로 반환
    return sellerName.trim()
  }
  try {
    const loadSeller = await backendApis.loadSellerBusinessRegistrationInfo()
    const sellerInfos = {
      sellerId: loadSeller?.data?.sellerId,
      inputBusinessNumber:
        loadSeller?.data?.businessRegistrationNumber.replace(/[^0-9]/g, '') ||
        '',
      businessNumberFromImage:
        loadSeller?.data?.businessRegisteredData?.registerNumber.replace(
          /[^0-9]/g,
          '',
        ) || '',
      openDate:
        loadSeller?.data?.businessRegisteredData?.openDate.replace(
          /[^0-9]/g,
          '',
        ) || '',
      repName: loadSeller?.data?.businessRegisteredData?.repName || '',
    }

    if (
      sellerInfos?.inputBusinessNumber !== sellerInfos?.businessNumberFromImage
    ) {
      return {
        status: 400,
        message:
          '입력하신 사업자번호와 사업자등록증 상 사업자번호가 일치하지 않습니다.',
      }
    }
    const checkResult = await backendApis.validateBusinessRegistrationNumber(
      sellerInfos?.sellerId,
      sellerInfos?.inputBusinessNumber,
      parseSellerName(sellerInfos?.repName || ''),
      sellerInfos?.openDate,
    )
    if (checkResult?.status === 200) {
      if (
        checkResult?.data?.status_code === 'OK' &&
        checkResult?.data?.data?.[0]?.valid === '01' &&
        checkResult?.data?.data?.[0]?.status?.b_stt === '계속사업자'
      ) {
        const updateResult =
          await backendApis.updateSellerBusinessNumberValidity(
            sellerInfos?.sellerId,
          )
        if (updateResult?.status === 200) {
          return {
            status: 200,
            message: '계속사업자로 조회되었습니다. 검증에 성공했습니다.',
          }
        }
        return {
          status: 400,
          message:
            '검증에 성공했으나 업데이트 과정에서 오류가 발생했습니다. 재시도 부탁드립니다.',
        }
      }
      return {
        status: 400,
        message: '사업자번호 검증에 실패했습니다. 다시 시도해 주세요.',
      }
    }
    return {
      status: 400,
      message: '사업자번호 검증에 실패했습니다. 다시 시도해 주세요.',
    }
  } catch (error) {
    console.error('Business image validation error:', error)
    return {
      status: 400,
      message: error.message || '검증 중 오류가 발생했습니다.',
      error,
    }
  }
}

export default validateBusinessNumber
